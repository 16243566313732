/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// oneof
import {
    Base64,
    ImageUrl,
    Base64FromJSONTyped,
    Base64ToJSON,
    ImageUrlFromJSONTyped,
    ImageUrlToJSON,
} from './';

/**
 * oneof interface
 * @type ImageDefinition
 * 
 * @export
 */
export type ImageDefinition = Base64 | ImageUrl;


export function ImageDefinitionFromJSON(json: any): ImageDefinition {
    return ImageDefinitionFromJSONTyped(json, false);
}

export function ImageDefinitionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageDefinition {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'base64':
            return {...Base64FromJSONTyped(json, true), type: 'base64'};
        case 'imageUrl':
            return {...ImageUrlFromJSONTyped(json, true), type: 'imageUrl'};
        default:
            throw new Error(`No variant of ImageDefinition exists with 'type=${json['type']}'`);
    }
}

export function ImageDefinitionToJSON(value?: ImageDefinition | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'base64':
            return Base64ToJSON(value);
        case 'imageUrl':
            return ImageUrlToJSON(value);
        default:
            throw new Error(`No variant of ImageDefinition exists with 'type=${value['type']}'`);
    }
}

