import React, { JSX, PropsWithChildren, useEffect, useState } from 'react';
import { hasNumberKey, hasStringKey, Loaded, Loadable } from './type';
import {
  exchangeCodeForToken,
  getCollectionAssets,
  getCollections,
  getCurrentUser,
  GetToken,
  IBynderCollectionsOptions,
  IBynderUser,
  isValidToken,
  IToken,
  ITokenWithRefresh,
  refreshToken,
  createCollection,
  IBynderCreateCollectionOptions,
  addAssetsToCollection,
  shareCollection,
  IBynderShareCollectionOptions,
  getCollection,
  getAsset,
  searchKeyword,
  getAssetDownloadUrl,
  removeAssetsFromCollection,
} from './bynder-api';
import { bynder } from '../config';
import { config } from './authConfig';

type IBynderWrap = ReturnType<typeof getBynderWrap>;

export interface IBynderLoggedIn {
  bynder: IBynderWrap;
  isLoggedIn: true;
  login: () => void;
  logout: () => void;
  user: Loaded<IBynderUser, unknown>;
  getToken: GetToken;
}

interface IBynderNotLoggedIn {
  isLoggedIn: false;
  login: () => void;
  logout: () => void;
  user: Loadable<IBynderUser>;
}

type IBynder = IBynderLoggedIn | IBynderNotLoggedIn;

export const BASE_URL = bynder.BYNDER_BASE_URL;
export const API_BASE_URL = bynder.BYNDER_API_BASE_URL;
export const CLIENT_ID = bynder.BYNDER_CLIENT_ID;
export const CLIENT_SECRET = bynder.BYNDER_CLIENT_SECRET;
export const REDIRECT_URI = bynder.BYNDER_REDIRECT_URI;

const BynderContext = React.createContext<IBynder>(getBynder()); // Create a context object

export function useBynder(): IBynder {
  const context = React.useContext(BynderContext);

  return context;
}

/**
 * Fetches code and state from url and removes them from url by replacement
 */
function consumeAuthParamsInUrl() {
  const u = new URLSearchParams(window.location.search);
  const code = u.get('code');
  const state = u.get('state');

  const url = new URL(window.location.href);
  url.searchParams.delete('code');
  url.searchParams.delete('state');
  history.replaceState({}, document.title, url.href);

  return { code, state };
}

const TOKEN_KEY = 'bynder_token';
const STATE_KEY = 'bynder_state';

export type Timestamped<T> = T & { timestamp: number };

function getCacheStorage(): Storage {
  const cacheLocation = String(config.cache?.cacheLocation);

  if (cacheLocation === 'localStorage') {
    return window.localStorage;
  } else if (cacheLocation === 'sessionStorage') {
    return window.sessionStorage;
  }

  throw new Error(`Unknown cache location: ${cacheLocation}`);
}

function setStoredToken(token: Timestamped<ITokenWithRefresh> | null): void {
  if (token === null) {
    getCacheStorage().removeItem(TOKEN_KEY);
  } else {
    getCacheStorage().setItem(TOKEN_KEY, JSON.stringify(token));
  }
}

function genAndSetStoredState(): string {
  const random_string =
    Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  getCacheStorage().setItem(STATE_KEY, random_string);

  return random_string;
}

function getStoredState(): string {
  return String(getCacheStorage().getItem(STATE_KEY));
}

function getStoredToken(): Timestamped<ITokenWithRefresh> | null {
  const storedToken = getCacheStorage().getItem(TOKEN_KEY);

  if (!storedToken) {
    return null;
  }

  try {
    const t: unknown = JSON.parse(storedToken);

    if (isValidToken(t) && hasNumberKey('timestamp', t) && hasStringKey('refresh_token', t)) {
      return t;
    }

    return null;
  } catch {
    return null;
  }
}

function getBynderWrap(getToken: GetToken) {
  return {
    getCollections: (opts: IBynderCollectionsOptions, controller: AbortController) => {
      return getCollections(getToken, opts, controller);
    },
    getCollectionAssets: (id: string, controller: AbortController) => {
      return getCollectionAssets(getToken, { id }, controller);
    },
    getCurrentUser: () => {
      return getCurrentUser(getToken);
    },
    createCollection: (options: IBynderCreateCollectionOptions) => {
      return createCollection(getToken, options);
    },
    addAssetsToCollection: (collectionId: string, assets: string[]) => {
      return addAssetsToCollection(getToken, collectionId, assets);
    },
    removeAssetsFromCollection: (collectionId: string, assets: string[]) => {
      return removeAssetsFromCollection(getToken, collectionId, assets);
    },
    shareCollection: (opts: IBynderShareCollectionOptions) => {
      return shareCollection(getToken, opts);
    },
    getCollection: (id: string) => {
      return getCollection(getToken, id);
    },
    getAsset: (id: string) => {
      return getAsset(getToken, id);
    },
    getAssetDownloadUrl: (id: string) => {
      return getAssetDownloadUrl(getToken, id);
    },
    searchKeyword: (keyword: string) => {
      return searchKeyword(getToken, keyword);
    },
  };
}

function getBynder(
  getToken: GetToken | null = null,
  user: Loadable<IBynderUser, unknown> = { status: 'uninitialized' },
  onLogout?: () => void,
): IBynder {
  const common = {
    login: () => {
      const s = genAndSetStoredState();

      const scopes = [
        'collection:read',
        'current.user:read',
        'asset:read',
        'asset:write',
        'collection:write',
        'offline',
        // 'collection:write',
      ].join(' ');

      const url = new URL(`${bynder.BYNDER_BASE_URL}/v6/authentication/oauth2/auth`);
      url.searchParams.append('response_type', 'code');
      url.searchParams.append('client_id', bynder.BYNDER_CLIENT_ID);
      url.searchParams.append('redirect_uri', bynder.BYNDER_REDIRECT_URI);
      url.searchParams.append('scope', scopes);
      url.searchParams.append('state', s);

      window.open(url.href, '_self');
    },
    logout: () => {
      setStoredToken(null);
      if (typeof onLogout !== 'undefined') {
        onLogout();
      }
    },
  };

  if (getToken !== null && user.status === 'loaded') {
    return {
      ...common,
      isLoggedIn: true,
      bynder: getBynderWrap(getToken),
      user,
      getToken,
    };
  } else {
    return {
      ...common,
      isLoggedIn: false,
      user,
    };
  }
}

function isTokenExpired(token: Timestamped<IToken>) {
  const d = new Date();
  const t = d.getTime() + 60 * 10 * 1000; // 10 min into the future
  const r = t > token.timestamp;
  return r;
}

function timestamp<T extends { expires_in: number }>(o: T): Timestamped<T> {
  return { ...o, timestamp: new Date().getTime() + o.expires_in * 1000 };
}

const storedToken = getStoredToken();

export const BynderProvider = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
  const [token, setToken] = useState<Timestamped<ITokenWithRefresh> | null>(storedToken);
  const [bynderUser, setBynderUser] = useState<Loadable<IBynderUser>>({ status: 'uninitialized' });

  const getToken = async (forceRefresh = false) => {
    if (token === null) {
      return null;
    }

    if (!isTokenExpired(token) && !forceRefresh) {
      return token;
    }

    return doRefresh(token);
  };

  const doRefresh = async (token: Timestamped<ITokenWithRefresh>) => {
    // refresh token
    const refreshResponse = await refreshToken(
      BASE_URL,
      CLIENT_ID,
      CLIENT_SECRET,
      token.refresh_token,
    );

    if (refreshResponse.isError) {
      console.log('Could not use refreshtoken to get new accesstoken', refreshResponse);
      setToken(null);
      setBynderUser({ status: 'uninitialized' });
      setStoredToken(null);
      return null;
    } else {
      const newToken = timestamp({
        refresh_token: token.refresh_token,
        ...refreshResponse.token,
      });

      setToken(newToken);
      setStoredToken(newToken);

      return newToken;
    }
  };

  useEffect(() => {
    const urlData = consumeAuthParamsInUrl();

    // if the code query param exists try to exchange code for token
    if (urlData.code) {
      // check if state parameter matches what we sent in the login request

      const state = getStoredState();

      if (state !== urlData.state) {
        console.warn('Invalid auth state', state, urlData.state);
        return;
      }

      exchangeCodeForToken(BASE_URL, CLIENT_ID, CLIENT_SECRET, urlData.code)
        .then((res) => {
          if (res.isError) {
            console.error('got invalid token from bynder', res);
          } else {
            const timestampedToken = timestamp(res.token);
            setStoredToken(timestampedToken);
            setToken(timestampedToken);
          }
        })
        .catch((e) => {
          console.error('could not fetch bynder token', e);
        });
    } else if (token !== null && bynderUser.status === 'uninitialized') {
      const client = getBynderWrap(getToken);
      setBynderUser({ status: 'loading' });

      client
        .getCurrentUser()
        .then((u) => {
          if (u.isError) {
            console.log('Could not fetch user', u);
            // try using the refreshtoken
            return doRefresh(token);
          }

          console.log('got bynder user', u);
          setBynderUser({ status: 'loaded', data: u.result });
          return null;
        })
        .catch((e) => {
          console.log('Could not fetch bynder user.', e);
          // we've tried everything reset state now
          setBynderUser({ status: 'uninitialized' });
          setToken(null);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, bynderUser.status]);

  const bynder = getBynder(getToken, bynderUser, () => {
    setToken(null);
    setBynderUser({ status: 'uninitialized' });
    setStoredToken(null);
  });

  return <BynderContext.Provider value={bynder}>{children}</BynderContext.Provider>;
};
