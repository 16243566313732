/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    GetFeaturesResult,
    GetFeaturesResultFromJSON,
    GetFeaturesResultFromJSONTyped,
    GetFeaturesResultToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetFeaturesResults
 */
export interface GetFeaturesResults {
    /**
     * 
     * @type {Array<GetFeaturesResult>}
     * @memberof GetFeaturesResults
     */
    results: Array<GetFeaturesResult>;
}

export function GetFeaturesResultsFromJSON(json: any): GetFeaturesResults {
    return GetFeaturesResultsFromJSONTyped(json, false);
}

export function GetFeaturesResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetFeaturesResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(GetFeaturesResultFromJSON)),
    };
}

export function GetFeaturesResultsToJSON(value?: GetFeaturesResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(GetFeaturesResultToJSON)),
    };
}


