/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface VIstylegroup
 */
export interface VIstylegroup {
    /**
     * 
     * @type {number}
     * @memberof VIstylegroup
     */
    score: number;
    /**
     * 
     * @type {string}
     * @memberof VIstylegroup
     */
    stylegroup: string;
}

export function VIstylegroupFromJSON(json: any): VIstylegroup {
    return VIstylegroupFromJSONTyped(json, false);
}

export function VIstylegroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): VIstylegroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'score': json['score'],
        'stylegroup': json['stylegroup'],
    };
}

export function VIstylegroupToJSON(value?: VIstylegroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'score': value.score,
        'stylegroup': value.stylegroup,
    };
}


