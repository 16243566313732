/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    ExtApiAssetList,
    ExtApiAssetListFromJSON,
    ExtApiAssetListFromJSONTyped,
    ExtApiAssetListToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExtSearchResultResponse
 */
export interface ExtSearchResultResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ExtSearchResultResponse
     */
    isError: ExtSearchResultResponseIsErrorEnum;
    /**
     * 
     * @type {string}
     * @memberof ExtSearchResultResponse
     */
    type: ExtSearchResultResponseTypeEnum;
    /**
     * 
     * @type {ExtApiAssetList}
     * @memberof ExtSearchResultResponse
     */
    results: ExtApiAssetList;
}


/**
* @export
* @enum {boolean}
*/
export type ExtSearchResultResponseIsErrorEnum = false;




/**
* @export
* @enum {string}
*/
export type ExtSearchResultResponseTypeEnum = 'ext-search-result-response';





export function ExtSearchResultResponseFromJSON(json: any): ExtSearchResultResponse {
    return ExtSearchResultResponseFromJSONTyped(json, false);
}

export function ExtSearchResultResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtSearchResultResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isError': json['isError'],
        'type': json['type'],
        'results': ExtApiAssetListFromJSON(json['results']),
    };
}

export function ExtSearchResultResponseToJSON(value?: ExtSearchResultResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isError': value.isError,
        'type': value.type,
        'results': ExtApiAssetListToJSON(value.results),
    };
}


