/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    BynderAssetEditJobMessages,
    BynderAssetEditJobMessagesFromJSON,
    BynderAssetEditJobMessagesFromJSONTyped,
    BynderAssetEditJobMessagesToJSON,
    BynderAssetEditJobUser,
    BynderAssetEditJobUserFromJSON,
    BynderAssetEditJobUserFromJSONTyped,
    BynderAssetEditJobUserToJSON,
    EditJobItem,
    EditJobItemFromJSON,
    EditJobItemFromJSONTyped,
    EditJobItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface BynderAssetEditJob
 */
export interface BynderAssetEditJob {
    /**
     * 
     * @type {number}
     * @memberof BynderAssetEditJob
     */
    version: BynderAssetEditJobVersionEnum;
    /**
     * 
     * @type {string}
     * @memberof BynderAssetEditJob
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BynderAssetEditJob
     */
    secrets: string;
    /**
     * 
     * @type {EditJobItem}
     * @memberof BynderAssetEditJob
     */
    item: EditJobItem;
    /**
     * 
     * @type {BynderAssetEditJobUser}
     * @memberof BynderAssetEditJob
     */
    user: BynderAssetEditJobUser;
    /**
     * 
     * @type {number}
     * @memberof BynderAssetEditJob
     */
    attempts: number;
    /**
     * 
     * @type {number}
     * @memberof BynderAssetEditJob
     */
    created: number;
    /**
     * 
     * @type {number}
     * @memberof BynderAssetEditJob
     */
    lastUpdate: number;
    /**
     * 
     * @type {Array<BynderAssetEditJobMessages>}
     * @memberof BynderAssetEditJob
     */
    messages: Array<BynderAssetEditJobMessages>;
    /**
     * 
     * @type {number}
     * @memberof BynderAssetEditJob
     */
    maxAttempts: number;
}


/**
* @export
* @enum {string}
*/
export type BynderAssetEditJobVersionEnum = 2;





export function BynderAssetEditJobFromJSON(json: any): BynderAssetEditJob {
    return BynderAssetEditJobFromJSONTyped(json, false);
}

export function BynderAssetEditJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): BynderAssetEditJob {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': json['version'],
        'id': json['id'],
        'secrets': json['secrets'],
        'item': EditJobItemFromJSON(json['item']),
        'user': BynderAssetEditJobUserFromJSON(json['user']),
        'attempts': json['attempts'],
        'created': json['created'],
        'lastUpdate': json['lastUpdate'],
        'messages': ((json['messages'] as Array<any>).map(BynderAssetEditJobMessagesFromJSON)),
        'maxAttempts': json['maxAttempts'],
    };
}

export function BynderAssetEditJobToJSON(value?: BynderAssetEditJob | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': value.version,
        'id': value.id,
        'secrets': value.secrets,
        'item': EditJobItemToJSON(value.item),
        'user': BynderAssetEditJobUserToJSON(value.user),
        'attempts': value.attempts,
        'created': value.created,
        'lastUpdate': value.lastUpdate,
        'messages': ((value.messages as Array<any>).map(BynderAssetEditJobMessagesToJSON)),
        'maxAttempts': value.maxAttempts,
    };
}


