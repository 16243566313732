/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface IMCCSearchAggregations
 */
export interface IMCCSearchAggregations {
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchAggregations
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchAggregations
     */
    type: string;
    /**
     * 
     * @type {Array<{ [key: string]: object; }>}
     * @memberof IMCCSearchAggregations
     */
    values: Array<{ [key: string]: object; }>;
}

export function IMCCSearchAggregationsFromJSON(json: any): IMCCSearchAggregations {
    return IMCCSearchAggregationsFromJSONTyped(json, false);
}

export function IMCCSearchAggregationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): IMCCSearchAggregations {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'type': json['type'],
        'values': json['values'],
    };
}

export function IMCCSearchAggregationsToJSON(value?: IMCCSearchAggregations | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'values': value.values,
    };
}


