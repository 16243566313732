import React from 'react';
import { createRoot } from 'react-dom/client';

// css
import './index.scss';

import App from './App';
import { BynderProvider } from './util/bynderReactUtils';
import { ToastsProvider } from './util/toastProvider';
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// MSAL imports
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from '@azure/msal-browser';
import { config } from './util/authConfig';

if (window.location.pathname === '/blank.html') {
  throw new Error('This page is not meant to be loaded directly');
}

export const msalInstance = new PublicClientApplication(config);
msalInstance
  .initialize()
  .then(() => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
      }
    });
  })
  .catch((e: Error) => {
    console.error('Failed to initialize MSAL', e);
    throw e;
  });
// Account selection logic is app dependent. Adjust as needed for different use cases.

const root = createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <Router>
      <QueryClientProvider client={queryClient}>
        <BynderProvider>
          <ToastsProvider>
            <App pca={msalInstance} />
          </ToastsProvider>
        </BynderProvider>
      </QueryClientProvider>
    </Router>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
