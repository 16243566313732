/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// oneof
import {
    CouldNotCreateFSJob,
    CouldNotQueueUploadTask,
    UploadQueued,
    CouldNotCreateFSJobFromJSONTyped,
    CouldNotCreateFSJobToJSON,
    CouldNotQueueUploadTaskFromJSONTyped,
    CouldNotQueueUploadTaskToJSON,
    UploadQueuedFromJSONTyped,
    UploadQueuedToJSON,
} from './';

/**
 * oneof interface
 * @type CreateBynderJobResponseResult
 * 
 * @export
 */
export type CreateBynderJobResponseResult = CouldNotCreateFSJob | CouldNotQueueUploadTask | UploadQueued;


export function CreateBynderJobResponseResultFromJSON(json: any): CreateBynderJobResponseResult {
    return CreateBynderJobResponseResultFromJSONTyped(json, false);
}

export function CreateBynderJobResponseResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBynderJobResponseResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'could-not-create-fs-job':
            return {...CouldNotCreateFSJobFromJSONTyped(json, true), type: 'could-not-create-fs-job'};
        case 'could-not-queue-upload-task':
            return {...CouldNotQueueUploadTaskFromJSONTyped(json, true), type: 'could-not-queue-upload-task'};
        case 'job-queued':
            return {...UploadQueuedFromJSONTyped(json, true), type: 'job-queued'};
        default:
            throw new Error(`No variant of CreateBynderJobResponseResult exists with 'type=${json['type']}'`);
    }
}

export function CreateBynderJobResponseResultToJSON(value?: CreateBynderJobResponseResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'could-not-create-fs-job':
            return CouldNotCreateFSJobToJSON(value);
        case 'could-not-queue-upload-task':
            return CouldNotQueueUploadTaskToJSON(value);
        case 'job-queued':
            return UploadQueuedToJSON(value);
        default:
            throw new Error(`No variant of CreateBynderJobResponseResult exists with 'type=${value['type']}'`);
    }
}

