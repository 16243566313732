/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    IAGenerateCaptionsResponse,
    IAGenerateCaptionsResponseFromJSON,
    IAGenerateCaptionsResponseFromJSONTyped,
    IAGenerateCaptionsResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface VIImageCaptionsResponse
 */
export interface VIImageCaptionsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof VIImageCaptionsResponse
     */
    isError: VIImageCaptionsResponseIsErrorEnum;
    /**
     * 
     * @type {string}
     * @memberof VIImageCaptionsResponse
     */
    type: VIImageCaptionsResponseTypeEnum;
    /**
     * 
     * @type {IAGenerateCaptionsResponse}
     * @memberof VIImageCaptionsResponse
     */
    result: IAGenerateCaptionsResponse;
}


/**
* @export
* @enum {boolean}
*/
export type VIImageCaptionsResponseIsErrorEnum = false;




/**
* @export
* @enum {string}
*/
export type VIImageCaptionsResponseTypeEnum = 'vi-image-captions';





export function VIImageCaptionsResponseFromJSON(json: any): VIImageCaptionsResponse {
    return VIImageCaptionsResponseFromJSONTyped(json, false);
}

export function VIImageCaptionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VIImageCaptionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isError': json['isError'],
        'type': json['type'],
        'result': IAGenerateCaptionsResponseFromJSON(json['result']),
    };
}

export function VIImageCaptionsResponseToJSON(value?: VIImageCaptionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isError': value.isError,
        'type': value.type,
        'result': IAGenerateCaptionsResponseToJSON(value.result),
    };
}


