/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// oneof
import {
    AudioMeta,
    CouldNotFetchMeta,
    ImageMeta,
    MediaWithoutMeta,
    VideoMeta,
    AudioMetaFromJSONTyped,
    AudioMetaToJSON,
    CouldNotFetchMetaFromJSONTyped,
    CouldNotFetchMetaToJSON,
    ImageMetaFromJSONTyped,
    ImageMetaToJSON,
    MediaWithoutMetaFromJSONTyped,
    MediaWithoutMetaToJSON,
    VideoMetaFromJSONTyped,
    VideoMetaToJSON,
} from './';

/**
 * oneof interface
 * @type IMAssetMeta
 * 
 * @export
 */
export type IMAssetMeta = AudioMeta | CouldNotFetchMeta | ImageMeta | MediaWithoutMeta | VideoMeta;


export function IMAssetMetaFromJSON(json: any): IMAssetMeta {
    return IMAssetMetaFromJSONTyped(json, false);
}

export function IMAssetMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): IMAssetMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'audio-meta':
            return {...AudioMetaFromJSONTyped(json, true), type: 'audio-meta'};
        case 'could-not-fetch-meta':
            return {...CouldNotFetchMetaFromJSONTyped(json, true), type: 'could-not-fetch-meta'};
        case 'image-meta':
            return {...ImageMetaFromJSONTyped(json, true), type: 'image-meta'};
        case 'media-without-meta':
            return {...MediaWithoutMetaFromJSONTyped(json, true), type: 'media-without-meta'};
        case 'video-meta':
            return {...VideoMetaFromJSONTyped(json, true), type: 'video-meta'};
        default:
            throw new Error(`No variant of IMAssetMeta exists with 'type=${json['type']}'`);
    }
}

export function IMAssetMetaToJSON(value?: IMAssetMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'audio-meta':
            return AudioMetaToJSON(value);
        case 'could-not-fetch-meta':
            return CouldNotFetchMetaToJSON(value);
        case 'image-meta':
            return ImageMetaToJSON(value);
        case 'media-without-meta':
            return MediaWithoutMetaToJSON(value);
        case 'video-meta':
            return VideoMetaToJSON(value);
        default:
            throw new Error(`No variant of IMAssetMeta exists with 'type=${value['type']}'`);
    }
}

