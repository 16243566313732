/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    ImageDefinition,
    ImageDefinitionFromJSON,
    ImageDefinitionFromJSONTyped,
    ImageDefinitionToJSON,
    ProductInformation,
    ProductInformationFromJSON,
    ProductInformationFromJSONTyped,
    ProductInformationToJSON,
} from './';

/**
 * 
 * @export
 * @interface VIImageCaptionsPayload
 */
export interface VIImageCaptionsPayload {
    /**
     * 
     * @type {ImageDefinition}
     * @memberof VIImageCaptionsPayload
     */
    image: ImageDefinition;
    /**
     * 
     * @type {Array<ProductInformation>}
     * @memberof VIImageCaptionsPayload
     */
    productInfo: Array<ProductInformation>;
    /**
     * 
     * @type {string}
     * @memberof VIImageCaptionsPayload
     */
    mainProduct?: string;
}

export function VIImageCaptionsPayloadFromJSON(json: any): VIImageCaptionsPayload {
    return VIImageCaptionsPayloadFromJSONTyped(json, false);
}

export function VIImageCaptionsPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): VIImageCaptionsPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'image': ImageDefinitionFromJSON(json['image']),
        'productInfo': ((json['productInfo'] as Array<any>).map(ProductInformationFromJSON)),
        'mainProduct': !exists(json, 'mainProduct') ? undefined : json['mainProduct'],
    };
}

export function VIImageCaptionsPayloadToJSON(value?: VIImageCaptionsPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'image': ImageDefinitionToJSON(value.image),
        'productInfo': ((value.productInfo as Array<any>).map(ProductInformationToJSON)),
        'mainProduct': value.mainProduct,
    };
}


