/** @jsxImportSource @emotion/react */
import React, { ReactNode } from 'react';
import { ClassNames, css } from '@emotion/react';
import Spreadsheet, { Matrix } from 'react-spreadsheet';

import RemoveIcon from '@ingka/ssr-icon/paths/cross';
import AddIcon from '@ingka/ssr-icon/paths/plus';
import Button from '@ingka/button';
import { Cell, Row } from '../../../hooks/useAssetEditHelpers';
import SSRIcon from '@ingka/ssr-icon';

type Data = Matrix<
  | {
      value: string;
      className: string;
    }
  | {
      value: string;
      className?: undefined;
    }
>;
interface IAssetsTable {
  data: Matrix<{ value: string; className?: string }>;
  columnsLabels: ReactNode[];
  isFaultyRow: (row: Row) => boolean;
  removeRowByIndex: (i: number) => void;
  isCreationCell: (cell: Cell, colIndex: number) => boolean;
  addColumn: () => void;
  addRow: () => void;
  setData: (data: Data) => void;
  setSaveEnabled: (enabled: boolean) => void;
}

const AssetsTable = ({
  data,
  columnsLabels,
  isFaultyRow,
  removeRowByIndex,
  isCreationCell,
  addColumn,
  addRow,
  setData,
  setSaveEnabled,
}: IAssetsTable) => {
  return (
    <div
      css={css`
        margin-top: 2%;
        margin-bottom: 2%;
        overflow-x: scroll;
        overflow: overlay;
      `}
    >
      <ClassNames>
        {({ css }) => (
          <>
            <div
              css={css`
                position: relative;
                display: grid;
                grid-template-columns: max-content max-content;
                grid-template-rows: auto auto;
                gap: 0px 0px;
                grid-template-areas:
                  'spreadsheet add-col'
                  'add-row .';
              `}
            >
              <div
                css={css`
                  grid-area: spreadsheet;
                  margin-bottom: -6px;
                  tr {
                    button {
                      display: none;
                    }
                    &:hover {
                      button {
                        display: block;
                      }
                    }
                  }
                `}
              >
                <Spreadsheet
                  rowLabels={data.map(
                    (_, index) =>
                      (
                        <div
                          css={css`
                            position: relative;
                            .btn {
                              color: rgba(255, 0, 0, 0.5);
                              position: absolute;
                              top: 0;
                              left: 0;
                              transform: scale(0.8) translate(-18px, -10px);
                            }
                          `}
                        >
                          {(index + 1).toString()}
                          <Button
                            title="Remove row"
                            iconOnly
                            ssrIcon={RemoveIcon}
                            size="small"
                            type="tertiary"
                            onClick={() => removeRowByIndex(index)}
                          />
                        </div>
                      ) as unknown as string,
                  )}
                  data={data.map((row) => {
                    const isFaulty = isFaultyRow(row);
                    return row.map((cell, colIndex) => {
                      if (!cell) {
                        return cell;
                      }
                      if (isFaulty) {
                        return {
                          value: cell.value,
                          className: css`
                            background-color: #e00751;
                            color: white;
                          `,
                        };
                      }
                      const isCreation = isCreationCell(cell, colIndex);
                      if (isCreation) {
                        return {
                          value: cell.value,
                          className: css`
                            background-color: #1207e0;
                            color: white;
                          `,
                        };
                      }
                      return {
                        value: cell.value,
                      };
                    });
                  })}
                  columnLabels={columnsLabels as unknown as string[]}
                  onChange={(d) => {
                    setData(d);
                    setSaveEnabled(false);
                  }}
                />
              </div>
              <div
                role="button"
                onClick={addColumn}
                css={css`
                  box-shadow: -1px 0 2px #ccc;
                  position: sticky;
                  right: 0;
                  grid-area: add-col;
                  cursor: pointer;
                  border: solid 1px hsl(2deg, 0%, 91%);
                  border-top: none;
                  background: rgb(245, 245, 245);
                  color: rgba(0, 150, 0, 0.4);
                  display: flex;
                  align-items: center;
                  &:hover {
                    background: rgb(250, 250, 250);
                  }
                  svg {
                    margin: auto;
                  }
                `}
              >
                <SSRIcon paths={AddIcon} />
              </div>
              <div
                role="button"
                onClick={addRow}
                css={css`
                  margin-bottom: 18px;
                  grid-area: add-row;
                  cursor: pointer;
                  padding: 2px;
                  border: solid 1px hsl(2deg, 0%, 91%);
                  border-left: none;
                  background: rgba(0, 0, 0, 0.04);
                  color: rgba(0, 150, 0, 0.4);
                  display: flex;
                  justify-content: center;
                  &:hover {
                    background: rgb(250, 250, 250);
                  }
                `}
              >
                <SSRIcon paths={AddIcon} />
              </div>
              <div />
            </div>
          </>
        )}
      </ClassNames>
    </div>
  );
};
export default AssetsTable;
