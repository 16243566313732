/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    SearchSimilarResultHits,
    SearchSimilarResultHitsFromJSON,
    SearchSimilarResultHitsFromJSONTyped,
    SearchSimilarResultHitsToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchSimilarResult
 */
export interface SearchSimilarResult {
    /**
     * 
     * @type {number}
     * @memberof SearchSimilarResult
     */
    took: number;
    /**
     * 
     * @type {boolean}
     * @memberof SearchSimilarResult
     */
    timed_out: boolean;
    /**
     * 
     * @type {SearchSimilarResultHits}
     * @memberof SearchSimilarResult
     */
    hits: SearchSimilarResultHits;
}

export function SearchSimilarResultFromJSON(json: any): SearchSimilarResult {
    return SearchSimilarResultFromJSONTyped(json, false);
}

export function SearchSimilarResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchSimilarResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'took': json['took'],
        'timed_out': json['timed_out'],
        'hits': SearchSimilarResultHitsFromJSON(json['hits']),
    };
}

export function SearchSimilarResultToJSON(value?: SearchSimilarResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'took': value.took,
        'timed_out': value.timed_out,
        'hits': SearchSimilarResultHitsToJSON(value.hits),
    };
}


