/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface AkamaiPurgeCacheResponse
 */
export interface AkamaiPurgeCacheResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AkamaiPurgeCacheResponse
     */
    isError: AkamaiPurgeCacheResponseIsErrorEnum;
    /**
     * 
     * @type {string}
     * @memberof AkamaiPurgeCacheResponse
     */
    type: AkamaiPurgeCacheResponseTypeEnum;
}


/**
* @export
* @enum {boolean}
*/
export type AkamaiPurgeCacheResponseIsErrorEnum = false;




/**
* @export
* @enum {string}
*/
export type AkamaiPurgeCacheResponseTypeEnum = 'akamai-purge-cache';





export function AkamaiPurgeCacheResponseFromJSON(json: any): AkamaiPurgeCacheResponse {
    return AkamaiPurgeCacheResponseFromJSONTyped(json, false);
}

export function AkamaiPurgeCacheResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AkamaiPurgeCacheResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isError': json['isError'],
        'type': json['type'],
    };
}

export function AkamaiPurgeCacheResponseToJSON(value?: AkamaiPurgeCacheResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isError': value.isError,
        'type': value.type,
    };
}


