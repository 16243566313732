import React, { JSX } from 'react';
import s from './SectionWrap.module.scss';

interface ISectionWrapProps {
  WrapperElement?: keyof JSX.IntrinsicElements;
}

export const SectionWrap = (props: React.PropsWithChildren<ISectionWrapProps>): JSX.Element => {
  const { WrapperElement = 'div' } = props;

  return (
    <WrapperElement className={s['app-page-container']}>
      <div className={s['app-page-container__inner']}>
        <div className={s['app-page-container__main']}>{props.children}</div>
      </div>
    </WrapperElement>
  );
};
