/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface AssetProductInformation
 */
export interface AssetProductInformation {
    /**
     * 
     * @type {string}
     * @memberof AssetProductInformation
     */
    itemNo: string;
    /**
     * 
     * @type {string}
     * @memberof AssetProductInformation
     */
    productName: string;
    /**
     * 
     * @type {string}
     * @memberof AssetProductInformation
     */
    altText?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetProductInformation
     */
    productCategory?: string;
}

export function AssetProductInformationFromJSON(json: any): AssetProductInformation {
    return AssetProductInformationFromJSONTyped(json, false);
}

export function AssetProductInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetProductInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemNo': json['itemNo'],
        'productName': json['productName'],
        'altText': !exists(json, 'altText') ? undefined : json['altText'],
        'productCategory': !exists(json, 'productCategory') ? undefined : json['productCategory'],
    };
}

export function AssetProductInformationToJSON(value?: AssetProductInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'itemNo': value.itemNo,
        'productName': value.productName,
        'altText': value.altText,
        'productCategory': value.productCategory,
    };
}


