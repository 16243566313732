/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface IDAMIllustration
 */
export interface IDAMIllustration {
    /**
     * 
     * @type {string}
     * @memberof IDAMIllustration
     */
    type?: string;
}

export function IDAMIllustrationFromJSON(json: any): IDAMIllustration {
    return IDAMIllustrationFromJSONTyped(json, false);
}

export function IDAMIllustrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDAMIllustration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function IDAMIllustrationToJSON(value?: IDAMIllustration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
    };
}


