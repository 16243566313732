/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface JobSaveInBynderCompleted
 */
export interface JobSaveInBynderCompleted {
    /**
     * 
     * @type {string}
     * @memberof JobSaveInBynderCompleted
     */
    type: JobSaveInBynderCompletedTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JobSaveInBynderCompleted
     */
    bynderId: string;
    /**
     * 
     * @type {number}
     * @memberof JobSaveInBynderCompleted
     */
    attempt: number;
    /**
     * 
     * @type {number}
     * @memberof JobSaveInBynderCompleted
     */
    timestamp: number;
}


/**
* @export
* @enum {string}
*/
export type JobSaveInBynderCompletedTypeEnum = 'job-save-in-bynder-completed';





export function JobSaveInBynderCompletedFromJSON(json: any): JobSaveInBynderCompleted {
    return JobSaveInBynderCompletedFromJSONTyped(json, false);
}

export function JobSaveInBynderCompletedFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobSaveInBynderCompleted {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'bynderId': json['bynderId'],
        'attempt': json['attempt'],
        'timestamp': json['timestamp'],
    };
}

export function JobSaveInBynderCompletedToJSON(value?: JobSaveInBynderCompleted | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'bynderId': value.bynderId,
        'attempt': value.attempt,
        'timestamp': value.timestamp,
    };
}


