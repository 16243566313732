import React, { JSX } from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  FSAssetDev,
  FSAssetProd,
  BynderAssetUploadJob,
  MetaProperty,
  SearchFSResult,
} from '../generated-backend-api';
import { Loadable } from '../util/type';
import { constructBynderAssetUrl } from '../util/bynder-api';
import InlineMessage from '@ingka/inline-message';
import { Section } from './section/section';
import { JobListItem } from './job-list-item';
import { UploadsListLoadable } from '../app-state';

interface IUploadAssetExistsWarningProps {
  searchFSResult: Loadable<SearchFSResult>;
  names: string[];
  bynderBaseUrl: string;
  uploadsList: UploadsListLoadable;
  propMap: Map<string, MetaProperty>;
}

function isFailed(j: BynderAssetUploadJob): boolean {
  const m = j.messages.sort((m1, m2) => m1.timestamp - m2.timestamp)[j.messages.length - 1];

  return m.type === 'job-failed';
}

function isCancelled(j: BynderAssetUploadJob): boolean {
  const m = j.messages.sort((m1, m2) => m1.timestamp - m2.timestamp)[j.messages.length - 1];

  return m.type === 'job-cancelled';
}

export function UploadAssetExistsWarning(
  props: IUploadAssetExistsWarningProps,
): JSX.Element | null {
  const { names, searchFSResult, bynderBaseUrl, uploadsList, propMap } = props;

  if (searchFSResult.status !== 'loaded') {
    return null;
  }

  if (uploadsList.status !== 'loaded') {
    return null;
  }

  if (
    searchFSResult.data.type !== 'fs-search-result-prod-response' &&
    searchFSResult.data.type !== 'fs-search-result-dev-response'
  ) {
    return <div>error: {searchFSResult.data.type}</div>;
  }

  if (uploadsList.data.type !== 'composite-uploads-list-result') {
    return <div>error: {uploadsList.data.type}</div>;
  }

  const namesSet = new Set(names);

  const resultNameMap = new Map(
    searchFSResult.data.results.map((a: FSAssetProd | FSAssetDev) => [a.name, a] as const),
  );

  const existingAssets = names.filter((n) => resultNameMap.has(n));

  const fsRows = existingAssets.map((a) => {
    const fsAsset = resultNameMap.get(a);
    if (!fsAsset) {
      return <div key={`invalid_${a}`}>invalid</div>;
    }

    return (
      <div key={a}>
        <a
          href={constructBynderAssetUrl(bynderBaseUrl, fsAsset.id)}
          target="_blank"
          rel="noreferrer"
        >
          {a}
        </a>
      </div>
    );
  });

  let fsAssetsMessage;
  if (fsRows.length !== 0) {
    fsAssetsMessage = (
      <InlineMessage
        css={css``}
        variant={'cautionary'}
        body={<>{fsRows}</>}
        title={'Some assets already exist in dam'}
      />
    );
  }

  // check jobs

  const existingAssetsInJobs = uploadsList.data.result
    .filter((a) => new Date().getTime() - a.created < 1000 * 60 * 60 * 4) // only jobs from the last 4 h
    .filter(
      (a): a is BynderAssetUploadJob =>
        namesSet.has(a.item.name) && !isFailed(a) && !isCancelled(a),
    );

  const jobRows = existingAssetsInJobs.map((j) => {
    return (
      <div key={`${j.id}`}>
        <JobListItem bynderBaseUrl={bynderBaseUrl} job={j} propMap={propMap} />
      </div>
    );
  });

  let jobAssetsMessage;
  if (jobRows.length !== 0) {
    jobAssetsMessage = (
      <InlineMessage
        css={css``}
        variant={'cautionary'}
        body={<>{jobRows}</>}
        title={'Some assets where recently queued for upload'}
      />
    );
  }

  return (
    <>
      <Section>{fsAssetsMessage}</Section>
      <Section>{jobAssetsMessage}</Section>
    </>
  );
}
