/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface IDAMJsonapi
 */
export interface IDAMJsonapi {
    /**
     * 
     * @type {string}
     * @memberof IDAMJsonapi
     */
    version?: string;
}

export function IDAMJsonapiFromJSON(json: any): IDAMJsonapi {
    return IDAMJsonapiFromJSONTyped(json, false);
}

export function IDAMJsonapiFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDAMJsonapi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': !exists(json, 'version') ? undefined : json['version'],
    };
}

export function IDAMJsonapiToJSON(value?: IDAMJsonapi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': value.version,
    };
}


