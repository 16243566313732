/** @jsxImportSource @emotion/react */

import React, { JSX } from 'react';
import { css } from '@emotion/react';
import Button from '@ingka/button';
import InlineMessage from '@ingka/inline-message';
import Modal, { ModalHeader, Prompt } from '@ingka/modal';
import Select, { Option } from '@ingka/select';
import questionMark from '@ingka/ssr-icon/paths/question-mark';
import Tooltip from '@ingka/tooltip';
import { useState } from 'react';
import Spreadsheet from 'react-spreadsheet';

interface IModalData {
  header: string;
  labels: { id: string; label: string }[];
  values: { value: string }[][];
}

const InlineContent = ({ children }: { children: React.ReactNode }) => (
  <div
    css={css`
      display: flex;
      align-items: center;
    `}
  >
    {children}
  </div>
);

const AssetEditHelp = (): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState<IModalData | undefined>();

  const items = [
    {
      content: (
        <>
          <strong>1.</strong> Define name&ensp;<i>(e.g. PH123456-crop)</i>&ensp;and extension &ensp;
          <i>(e.g. jgp)</i>&ensp;as attributes and fill in.{' '}
        </>
      ),
      data: {
        header: 'Name and extension',
        labels: [
          { id: 'name', label: 'Name' },
          { id: 'ext', label: 'Extension' },
        ],
        values: [[{ value: 'PH123456-crop' }, { value: 'jpg' }]],
      },
    },
    {
      content: (
        <>
          <strong>2.</strong> Define ID&ensp;<i>(e.g. 123456789)</i>&ensp;as attribute and fill
          in.{' '}
        </>
      ),
      data: {
        header: 'Only ID',
        labels: [{ id: 'id', label: 'ID' }],
        values: [[{ value: '123456789' }]],
      },
    },
  ];
  return (
    <>
      <InlineMessage
        variant="informative"
        title={'How to search for assets'}
        body={
          <>
            {items.map(({ content, data }, index) => (
              <InlineContent key={`key-${index}`}>
                {content}
                <Tooltip tooltipText="Show example">
                  <Button
                    ssrIcon={questionMark}
                    size="small"
                    iconOnly={true}
                    onClick={() => {
                      setModalData(data);
                      setOpen(true);
                    }}
                    type={'tertiary'}
                  />
                </Tooltip>
              </InlineContent>
            ))}
          </>
        }
      />
      <Modal visible={open} escapable={true} handleCloseBtn={() => setOpen(false)}>
        <Prompt
          titleId="modal-prompt"
          header={null}
          css={css`
            .prompt__content {
              padding: 20px;
            }
          `}
          title={
            <ModalHeader
              title={modalData?.header}
              ariaCloseTxt="Close prompt"
              closeBtnClick={() => setOpen(false)}
            />
          }
          footer={null}
        >
          {modalData && (
            <Spreadsheet
              css={css`
                .Spreadsheet__table {
                  pointer-events: none;
                }
              `}
              data={modalData.values.map((row) => {
                return row.map((cell) => {
                  return {
                    value: cell.value,
                  };
                });
              })}
              columnLabels={
                modalData.labels.map((item) => (
                  <Select
                    key={item.id}
                    id="deliveryDropDown"
                    label="Select an attribute"
                    value={item.id}
                  >
                    {[{ id: item.id, label: item.label }].map((d) => (
                      <Option key={d.id} value={d.id} name={`${d.label}`} />
                    ))}
                  </Select>
                )) as unknown as string[]
              }
            />
          )}
        </Prompt>
      </Modal>
    </>
  );
};

export default AssetEditHelp;
