/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface IMCCSearchHitDownloadItem
 */
export interface IMCCSearchHitDownloadItem {
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHitDownloadItem
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof IMCCSearchHitDownloadItem
     */
    type: number;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHitDownloadItem
     */
    url: string;
}

export function IMCCSearchHitDownloadItemFromJSON(json: any): IMCCSearchHitDownloadItem {
    return IMCCSearchHitDownloadItemFromJSONTyped(json, false);
}

export function IMCCSearchHitDownloadItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): IMCCSearchHitDownloadItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'type': json['type'],
        'url': json['url'],
    };
}

export function IMCCSearchHitDownloadItemToJSON(value?: IMCCSearchHitDownloadItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'type': value.type,
        'url': value.url,
    };
}


