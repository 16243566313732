/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface StreamResizeError
 */
export interface StreamResizeError {
    /**
     * 
     * @type {boolean}
     * @memberof StreamResizeError
     */
    isError: StreamResizeErrorIsErrorEnum;
    /**
     * 
     * @type {string}
     * @memberof StreamResizeError
     */
    type: StreamResizeErrorTypeEnum;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof StreamResizeError
     */
    error: { [key: string]: object; };
}


/**
* @export
* @enum {boolean}
*/
export type StreamResizeErrorIsErrorEnum = true;




/**
* @export
* @enum {string}
*/
export type StreamResizeErrorTypeEnum = 'stream-resize-error';





export function StreamResizeErrorFromJSON(json: any): StreamResizeError {
    return StreamResizeErrorFromJSONTyped(json, false);
}

export function StreamResizeErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreamResizeError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isError': json['isError'],
        'type': json['type'],
        'error': json['error'],
    };
}

export function StreamResizeErrorToJSON(value?: StreamResizeError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isError': value.isError,
        'type': value.type,
        'error': value.error,
    };
}


