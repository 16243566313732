/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface ExtApiSearch2ResponseTotal
 */
export interface ExtApiSearch2ResponseTotal {
    /**
     * 
     * @type {number}
     * @memberof ExtApiSearch2ResponseTotal
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof ExtApiSearch2ResponseTotal
     */
    relation: string;
}

export function ExtApiSearch2ResponseTotalFromJSON(json: any): ExtApiSearch2ResponseTotal {
    return ExtApiSearch2ResponseTotalFromJSONTyped(json, false);
}

export function ExtApiSearch2ResponseTotalFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtApiSearch2ResponseTotal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
        'relation': json['relation'],
    };
}

export function ExtApiSearch2ResponseTotalToJSON(value?: ExtApiSearch2ResponseTotal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'relation': value.relation,
    };
}


