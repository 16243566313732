import React from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { hasKey } from '@ikea-ingka-dam/type-util';
import InputField from '@ingka/input-field';
import FormField from '@ingka/form-field';
import Button from '@ingka/button';
import RemoveIcon from '@ingka/ssr-icon/paths/cross';

import { unreachable } from '../util/common';
import { SearchQueryTypeSelect } from './search-query-type-select';
import { SearchFieldQuery } from './search-query-types';

function isNumeric(str: unknown) {
  if (typeof str != 'string') return false; // we only process strings!
  return (
    !isNaN(Number(str)) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  );
}

type FieldQueryType = 'prefix' | 'exact' | 'lt' | 'gt' | 'ltgt' | 'contains' | 'fuzzy';

export const SearchQueryBuilder = ({
  query,
  onChange,
  onEnter,
}: {
  query: SearchFieldQuery;
  onChange: (query: SearchFieldQuery) => void;
  onEnter: () => void;
}) => {
  type ValueOf<T> = T[keyof T];
  const onFieldQueryTypeChange = (field: string, type: FieldQueryType) => {
    const queryField = query[field];

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!queryField) {
      return;
    }

    const getFieldDefault = (type: FieldQueryType): ValueOf<typeof query> => {
      switch (type) {
        case 'exact':
          return { exact: '' };
        case 'prefix':
          return { prefix: '' };
        case 'contains':
          return { contains: '' };
        case 'gt':
          return { gt: '0' };
        case 'lt':
          return { lt: '0' };
        case 'ltgt':
          return { lt: '0', gt: '0' };
        case 'fuzzy':
          return { fuzzy: '' };
        default:
          unreachable(type);
      }
    };

    onChange({ ...query, ...{ [field]: getFieldDefault(type) } });
  };

  const queryFields = Object.entries(query).map((e) => {
    const [field, q] = e;

    const onPrefixValueChanged = (field: string, value: string) => {
      onChange({ ...query, ...{ [field]: { prefix: value } } });
    };

    const onExactValueChanged = (field: string, value: string) => {
      const intValue = parseInt(value, 10);

      const v = intValue.toString() === value ? intValue : value;

      onChange({ ...query, ...{ [field]: { exact: v } } });
    };

    const onLTValueChanged = (field: string, value: string) => {
      onChange({ ...query, ...{ [field]: { lt: value } } });
    };

    const onGTValueChanged = (field: string, value: string) => {
      onChange({ ...query, ...{ [field]: { gt: value } } });
    };

    const onLTGTValueChanged = (field: string, lt: string, gt: string) => {
      onChange({ ...query, ...{ [field]: { gt, lt } } });
    };
    const onContainsValueChanged = (field: string, value: string) => {
      onChange({ ...query, ...{ [field]: { contains: value } } });
    };
    const onFuzzyValueChanged = (field: string, value: string) => {
      onChange({ ...query, ...{ [field]: { fuzzy: value } } });
    };

    const inputStyle = css`
      max-width: 300px;
      display: inline-block;
      margin-right: 10px;
    `;

    let fieldType: FieldQueryType;
    let inputs;

    const commonProps = {
      css: inputStyle,
      onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) =>
        e.key === 'Enter' ? onEnter() : null,
    };

    if (hasKey('prefix', q)) {
      fieldType = 'prefix';
      inputs = (
        <InputField
          id={`${field}_prefix`}
          type="text"
          value={q.prefix}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            onPrefixValueChanged(field, e.currentTarget.value)
          }
          {...commonProps}
        />
      );
    } else if (hasKey('exact', q)) {
      fieldType = 'exact';
      inputs = (
        <InputField
          type="text"
          id={`${field}_exact`}
          value={q.exact}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            onExactValueChanged(field, e.currentTarget.value)
          }
          {...commonProps}
        />
      );
    } else if (hasKey('contains', q)) {
      fieldType = 'contains';
      inputs = (
        <InputField
          type="text"
          id={`${field}_contains`}
          value={q.contains}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            onContainsValueChanged(field, e.currentTarget.value)
          }
          {...commonProps}
        />
      );
    } else if (hasKey('fuzzy', q)) {
      fieldType = 'fuzzy';
      inputs = (
        <InputField
          type="text"
          id={`${field}_fuzzy`}
          value={q.fuzzy}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            onFuzzyValueChanged(field, e.currentTarget.value)
          }
          {...commonProps}
        />
      );
    } else if (hasKey('gt', q) && hasKey('lt', q)) {
      fieldType = 'ltgt';
      inputs = (
        <span>
          <FormField valid={isNumeric(q.gt)} css={inputStyle} shouldValidate={true}>
            <InputField
              type="number"
              id={`${field}_gt`}
              placeholder="Greater than"
              label={'Greater than'}
              value={q.gt}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                onLTGTValueChanged(field, q.lt, e.currentTarget.value)
              }
              onKeyDown={commonProps.onKeyDown}
            />
          </FormField>
          <FormField valid={isNumeric(q.lt)} css={inputStyle} shouldValidate={true}>
            <InputField
              type="number"
              id={`${field}_lt`}
              placeholder="Less than"
              label={'Less than'}
              value={q.lt}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                onLTGTValueChanged(field, e.currentTarget.value, q.gt)
              }
              onKeyDown={commonProps.onKeyDown}
            />
          </FormField>
        </span>
      );
    } else if (hasKey('gt', q)) {
      fieldType = 'gt';
      inputs = (
        <span>
          <FormField valid={isNumeric(q.gt)} css={inputStyle} shouldValidate={true}>
            <InputField
              id={`${field}_gt`}
              type="number"
              placeholder="Greater than"
              label="Greater than"
              value={q.gt}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                onGTValueChanged(field, e.currentTarget.value)
              }
              {...commonProps}
            />
          </FormField>
        </span>
      );
    } else if (hasKey('lt', q)) {
      fieldType = 'lt';
      inputs = (
        <span>
          <FormField valid={isNumeric(q.lt)} css={inputStyle} shouldValidate={true}>
            <InputField
              id={`${field}_lt`}
              type="number"
              placeholder="Less than"
              label="Less than"
              value={q.lt}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                onLTValueChanged(field, e.currentTarget.value)
              }
              {...commonProps}
            />
          </FormField>
        </span>
      );
    } else {
      unreachable(q);
    }

    const onRemoveQueryField = () => {
      const { [field]: _val, ...rest } = query;

      onChange(rest);
    };

    return (
      <div
        key={`${field}`}
        css={css`
          margin-bottom: 10px;
        `}
      >
        <span
          css={css`
            display: inline-block;
            margin-right: 10px;
            min-width: 200px;
          `}
        >
          {field}
        </span>
        <SearchQueryTypeSelect
          value={fieldType}
          onChange={(v) => onFieldQueryTypeChange(field, v)}
        />
        {inputs}
        <Button
          onClick={onRemoveQueryField}
          size="small"
          iconOnly={true}
          ssrIcon={RemoveIcon}
          css={css`
            vertical-align: middle;
          `}
        />
      </div>
    );
    // return <div key={`${field}`}>unknown query type</div>;
  });

  return <>{queryFields}</>;
};
