/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// oneof
import {
    CouldNotFetchFromFSError,
    FSSearchResultResponseDev,
    FSSearchResultResponseProd,
    ValidationError,
    CouldNotFetchFromFSErrorFromJSONTyped,
    CouldNotFetchFromFSErrorToJSON,
    FSSearchResultResponseDevFromJSONTyped,
    FSSearchResultResponseDevToJSON,
    FSSearchResultResponseProdFromJSONTyped,
    FSSearchResultResponseProdToJSON,
    ValidationErrorFromJSONTyped,
    ValidationErrorToJSON,
} from './';

/**
 * oneof interface
 * @type SearchFSExtName
 * 
 * @export
 */
export type SearchFSExtName = CouldNotFetchFromFSError | ValidationError | FSSearchResultResponseDev | FSSearchResultResponseProd;


export function SearchFSExtNameFromJSON(json: any): SearchFSExtName {
    return SearchFSExtNameFromJSONTyped(json, false);
}

export function SearchFSExtNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchFSExtName {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'could-not-fetch-from-fs':
            return {...CouldNotFetchFromFSErrorFromJSONTyped(json, true), type: 'could-not-fetch-from-fs'};
        case 'failed-to-validate':
            return {...ValidationErrorFromJSONTyped(json, true), type: 'failed-to-validate'};
        case 'fs-search-result-dev-response':
            return {...FSSearchResultResponseDevFromJSONTyped(json, true), type: 'fs-search-result-dev-response'};
        case 'fs-search-result-prod-response':
            return {...FSSearchResultResponseProdFromJSONTyped(json, true), type: 'fs-search-result-prod-response'};
        default:
            throw new Error(`No variant of SearchFSExtName exists with 'type=${json['type']}'`);
    }
}

export function SearchFSExtNameToJSON(value?: SearchFSExtName | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'could-not-fetch-from-fs':
            return CouldNotFetchFromFSErrorToJSON(value);
        case 'failed-to-validate':
            return ValidationErrorToJSON(value);
        case 'fs-search-result-dev-response':
            return FSSearchResultResponseDevToJSON(value);
        case 'fs-search-result-prod-response':
            return FSSearchResultResponseProdToJSON(value);
        default:
            throw new Error(`No variant of SearchFSExtName exists with 'type=${value['type']}'`);
    }
}

