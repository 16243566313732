import Fraction from 'fraction.js';

/**
 * Converts width, height, top and left to a set of points describing the bounding box.
 */
export const widthHeightTopLeftToBoxPoints = (w: number, h: number, t: number, l: number) => {
  const topLeft = { x: l, y: t };
  const topRight = { x: l + w, y: t };
  const bottomLeft = { x: l, y: t + h };
  const bottomRight = { x: l + w, y: t + h };

  return { topLeft, topRight, bottomLeft, bottomRight };
};

export type Point = { x: number; y: number };

/**
 * Projects a point p to a line defined by two points (l1, l2).
 */
export const projectPointToLine = (p: Point, l1: Point, l2: Point) => {
  const dx = l2.x - l1.x;
  const dy = l2.y - l1.y;

  const t = ((p.x - l1.x) * dx + (p.y - l1.y) * dy) / (dx * dx + dy * dy);

  return {
    x: l1.x + t * dx,
    y: l1.y + t * dy,
  };
};

/**
 * Calculates the intersection point of two lines or returns null if there is no intersection.
 */
export const lineIntersection = (l1p1: Point, l1p2: Point, l2p1: Point, l2p2: Point) => {
  const s1_x = l1p2.x - l1p1.x;
  const s1_y = l1p2.y - l1p1.y;
  const s2_x = l2p2.x - l2p1.x;
  const s2_y = l2p2.y - l2p1.y;

  const s = (-s1_y * (l1p1.x - l2p1.x) + s1_x * (l1p1.y - l2p1.y)) / (-s2_x * s1_y + s1_x * s2_y);
  const t = (s2_x * (l1p1.y - l2p1.y) - s2_y * (l1p1.x - l2p1.x)) / (-s2_x * s1_y + s1_x * s2_y);

  if (s >= 0 && s <= 1 && t >= 0 && t <= 1) {
    // Collision detected
    return {
      x: l1p1.x + t * s1_x,
      y: l1p1.y + t * s1_y,
    };
  }

  return null; // No collision
};

/**
 * Projects point to line within the bounds of the bounding box.
 * Ie: if the projected point is outside the bounds, the closest intersection with the bounds is returned. Otherwise the projected point is returned.
 */
export const projectPointToLineWithinBounds = (
  point: Point,
  { l1, l2 }: { l1: Point; l2: Point },
  {
    topLeft,
    topRight,
    bottomLeft,
    bottomRight,
  }: { topLeft: Point; topRight: Point; bottomRight: Point; bottomLeft: Point },
) => {
  const projectedPoint = projectPointToLine(point, l1, l2);

  const width = topRight.x - topLeft.x;
  const height = bottomLeft.y - topLeft.y;

  const isWithinBounds =
    projectedPoint.x >= 0 &&
    projectedPoint.x <= width &&
    projectedPoint.y >= 0 &&
    projectedPoint.y <= height;

  if (isWithinBounds) {
    return projectedPoint;
  }

  // intersections with bounds
  const intersections: Point[] = [
    lineIntersection(l1, projectedPoint, topLeft, topRight),
    lineIntersection(l1, projectedPoint, topRight, bottomRight),
    lineIntersection(l1, projectedPoint, bottomRight, bottomLeft),
    lineIntersection(l1, projectedPoint, bottomLeft, topLeft),
  ].filter((i): i is Point => i !== null);

  if (intersections.length === 0) {
    return l1;
  }

  const closestIntersection = intersections.reduce((acc, curr) => {
    const accDist = Math.sqrt((acc.x - point.x) ** 2 + (acc.y - point.y) ** 2);
    const currDist = Math.sqrt((curr.x - point.x) ** 2 + (curr.y - point.y) ** 2);

    return currDist < accDist ? curr : acc;
  }, intersections[0]);

  return closestIntersection;
};

export interface Ratio {
  /**
   * Numerator of the ratio.
   */
  A: number;

  /**
   * Denominator of the ratio.
   */
  B: number;

  /**
   * String representation of the ratio (A:B).
   */
  ratio: string;
}

/**
 * Calculates crop width and height given ratio, base size of the crop and image dimensions.
 */
export function calculateCropImageDimensions(
  crop: { ratio: Ratio; base: number },
  imgWidth: number,
  imgHeight: number,
): { width: number; height: number } {
  if (crop.ratio.A > crop.ratio.B) {
    // ratio  width > height
    const width = imgWidth * crop.base;
    const height = width * (crop.ratio.B / crop.ratio.A);

    return { width, height };
  } else {
    const height = imgHeight * crop.base;
    const width = height * (crop.ratio.A / crop.ratio.B);

    return { width, height };
  }
}

export interface CropRect {
  left: number;
  top: number;
  width: number;
  height: number;
}

/**
 * Finds closest full pixel crop smaller than or equal to the 'scaledCrop' parameter.
 *
 * @param scaledCrop raw scaled crop
 * @param ratio aspect ratio choosen by the user
 * @param origWidth original image width
 * @param origHeight original image height
 */
export function createAlignedCrop(
  scaledCrop: CropRect,
  ratio: Ratio | 'free',
  origWidth: number,
  origHeight: number,
): CropRect {
  let left = Math.floor(scaledCrop.left);
  let top = Math.floor(scaledCrop.top);
  let width = Math.floor(scaledCrop.width);
  let height = Math.floor(scaledCrop.height);

  const ratioFraction = ratio === 'free' ? new Fraction(-1) : new Fraction(ratio.A, ratio.B);

  // check how much width is misaligned
  const widthOffset = width % Number(ratioFraction.n);

  // helper to calculate height
  const calcHeight = (w: number, f: Fraction) => f.inverse().mul(w).valueOf();

  // only check alignment with aspect ratio if not free form
  if (ratio !== 'free') {
    /**
     * example 3:4
     * x = width
     * y = height
     *
     * x / y = 3 / 4
     * line (calcHeight) => y = 4 / 3 * x
     *
     */

    // aligned width
    width = width - widthOffset;

    // calculate new height
    height = calcHeight(width, ratioFraction);
  }

  // check if we are inside the bounds of the original image
  // and reduce by numerator steps
  while (width > origWidth || height > origHeight) {
    width = width - Number(ratioFraction.n);
    height = calcHeight(width, ratioFraction);

    if (width < 0 || height < 0) {
      console.warn('Invalid width or height reduction');
      return scaledCrop;
    }
  }

  while (left + width > origWidth) {
    left = left - 1;

    if (left < 0) {
      console.warn('Invalid width + left offset');
      return scaledCrop;
    }
  }

  while (top + height > origHeight) {
    top = top - 1;

    if (top < 0) {
      console.warn('Invalid top + height offset');
      return scaledCrop;
    }
  }

  return { left, top, width, height };
}

/**
 * Clamp number to be between min and max.
 * Ex: clamp(0, 1, 2) -> 1
 */
export const clamp = (number: number, min: number, max: number): number => {
  return Math.max(min, Math.min(number, max));
};

export function safeFractionString(width: number, height: number): string {
  if (width === 0 || height === 0) {
    return '0';
  }

  const fraction = new Fraction(width, height);
  return fraction.toFraction();
}

type BoundingBox = {
  topRight: Point;
  topLeft: Point;
  bottomRight: Point;
  bottomLeft: Point;
};

export interface CropDefinition {
  ratio: Ratio;
  base: number;
  x: number;
  y: number;
}

/**
 * Generates crop definition from points, a bounding box for the original image and a ratio.
 */
export const generateCropDefinitionFromPoints = (
  points: BoundingBox,
  originalImageBox: DOMRect,
  ratio: Ratio,
): CropDefinition => {
  const newWidth = (points.topRight.x - points.topLeft.x) / originalImageBox.width;
  const newHeight = (points.bottomLeft.y - points.topLeft.y) / originalImageBox.height;

  const newBase = ratio.A > ratio.B ? newWidth : newHeight;

  const newX = points.topLeft.x / originalImageBox.width;
  const newY = points.topLeft.y / originalImageBox.height;

  return {
    ratio: { ...ratio },
    x: newX,
    y: newY,
    base: newBase,
  };
};
