/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface IAGenerateCaptionsResponse
 */
export interface IAGenerateCaptionsResponse {
    /**
     * 
     * @type {string}
     * @memberof IAGenerateCaptionsResponse
     */
    altText?: string;
}

export function IAGenerateCaptionsResponseFromJSON(json: any): IAGenerateCaptionsResponse {
    return IAGenerateCaptionsResponseFromJSONTyped(json, false);
}

export function IAGenerateCaptionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IAGenerateCaptionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'altText': !exists(json, 'altText') ? undefined : json['altText'],
    };
}

export function IAGenerateCaptionsResponseToJSON(value?: IAGenerateCaptionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'altText': value.altText,
    };
}


