/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    IMCCPackageAsset,
    IMCCPackageAssetFromJSON,
    IMCCPackageAssetFromJSONTyped,
    IMCCPackageAssetToJSON,
} from './';

/**
 * 
 * @export
 * @interface IMCCDeliveryResponse
 */
export interface IMCCDeliveryResponse {
    /**
     * 
     * @type {boolean}
     * @memberof IMCCDeliveryResponse
     */
    isError: IMCCDeliveryResponseIsErrorEnum;
    /**
     * 
     * @type {string}
     * @memberof IMCCDeliveryResponse
     */
    type: IMCCDeliveryResponseTypeEnum;
    /**
     * 
     * @type {Array<IMCCPackageAsset>}
     * @memberof IMCCDeliveryResponse
     */
    assets: Array<IMCCPackageAsset>;
    /**
     * 
     * @type {string}
     * @memberof IMCCDeliveryResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IMCCDeliveryResponse
     */
    name: string;
}


/**
* @export
* @enum {boolean}
*/
export type IMCCDeliveryResponseIsErrorEnum = false;




/**
* @export
* @enum {string}
*/
export type IMCCDeliveryResponseTypeEnum = 'delivery-response';





export function IMCCDeliveryResponseFromJSON(json: any): IMCCDeliveryResponse {
    return IMCCDeliveryResponseFromJSONTyped(json, false);
}

export function IMCCDeliveryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IMCCDeliveryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isError': json['isError'],
        'type': json['type'],
        'assets': ((json['assets'] as Array<any>).map(IMCCPackageAssetFromJSON)),
        'id': json['id'],
        'name': json['name'],
    };
}

export function IMCCDeliveryResponseToJSON(value?: IMCCDeliveryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isError': value.isError,
        'type': value.type,
        'assets': ((value.assets as Array<any>).map(IMCCPackageAssetToJSON)),
        'id': value.id,
        'name': value.name,
    };
}


