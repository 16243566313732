import React, { JSX } from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { constructBynderAssetUrl } from '../util/bynder-api';
import { Asset } from '../generated-backend-api';

interface SimilarImageProps {
  bynderBaseUrl: string;
  asset: Asset;
  similarityScore: number;
  selected: boolean;
  inBoard: boolean;
  onImageClicked?: (asset: Asset) => void;
}

export function SimilarImage(props: SimilarImageProps): JSX.Element {
  const { asset, similarityScore, onImageClicked, selected, inBoard } = props;

  const description = (
    <div
      css={css`
        background-color: rgba(255, 255, 255, 0.4);
        bottom: 0px;
        padding: 20px;
        width: 100%;
      `}
    >
      <div>Name: {asset.name}</div>
      <div>
        <a
          href={constructBynderAssetUrl(props.bynderBaseUrl, asset.id)}
          target="_blank"
          rel="noreferrer"
        >
          View in DAM
        </a>
      </div>
      <div>
        <a href={`/assetstatus/${asset.name}`} target="_blank" rel="noreferrer">
          Asset status
        </a>
      </div>
      <div>Score: {similarityScore.toPrecision(4)}</div>
    </div>
  );

  const image = (
    <div
      css={css`
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
      `}
    >
      <div
        css={css`
          height: 100%;
          position: relative;
        `}
      >
        <img
          src={asset.thumbnails.webimage}
          alt={asset.name}
          onClick={() => {
            if (!onImageClicked) {
              return;
            }

            onImageClicked(asset);
          }}
          css={css`
            max-width: 100%;
            max-height: 100%;
            object-fit: scale-down;
            cursor: pointer;
            transition: box-shadow 0.1s ease-in-out;

            &:hover {
              box-shadow: 0px 0px 7px 2px rgb(0 0 0 / 60%);
            }
          `}
        />
        {selected && (
          <div
            css={css`
              width: 20px;
              height: 20px;
              border-radius: 10px;
              background-color: #7ec76d;
              border: 1px solid white;
              position: absolute;
              top: 15px;
              right: 15px;
            `}
          />
        )}
        {inBoard && (
          <div
            css={css`
              position: absolute;
              top: 15px;
              left: 0px;
              padding: 5px;
              background-color: white;
              box-shadow: 2px 2px 4px -1px #000;
            `}
          >
            in board
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div>
      {image}
      {description}
    </div>
  );
}
