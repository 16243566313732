/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface ImageMeta
 */
export interface ImageMeta {
    /**
     * 
     * @type {number}
     * @memberof ImageMeta
     */
    size: number;
    /**
     * 
     * @type {string}
     * @memberof ImageMeta
     */
    type: ImageMetaTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ImageMeta
     */
    height?: number;
    /**
     * 
     * @type {number}
     * @memberof ImageMeta
     */
    width?: number;
}


/**
* @export
* @enum {string}
*/
export type ImageMetaTypeEnum = 'image-meta';





export function ImageMetaFromJSON(json: any): ImageMeta {
    return ImageMetaFromJSONTyped(json, false);
}

export function ImageMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'size': json['size'],
        'type': json['type'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'width': !exists(json, 'width') ? undefined : json['width'],
    };
}

export function ImageMetaToJSON(value?: ImageMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'size': value.size,
        'type': value.type,
        'height': value.height,
        'width': value.width,
    };
}


