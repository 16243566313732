/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface ExtApiAssetListNotFound
 */
export interface ExtApiAssetListNotFound {
    /**
     * 
     * @type {string}
     * @memberof ExtApiAssetListNotFound
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ExtApiAssetListNotFound
     */
    reason: number;
}

export function ExtApiAssetListNotFoundFromJSON(json: any): ExtApiAssetListNotFound {
    return ExtApiAssetListNotFoundFromJSONTyped(json, false);
}

export function ExtApiAssetListNotFoundFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtApiAssetListNotFound {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'reason': json['reason'],
    };
}

export function ExtApiAssetListNotFoundToJSON(value?: ExtApiAssetListNotFound | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reason': value.reason,
    };
}


