import React, { JSX, useState } from 'react';
import { TemplateListType, TemplateType, setIDBTemplates } from '../templates';
import Modal, { Sheets, ModalBody, ModalHeader, ModalFooter } from '@ingka/modal';
import Button from '@ingka/button';
import { MetaProperty } from '../generated-backend-api';
import InputField from '@ingka/input-field';
import { Loadable } from '../util/type';
import { TemplateSummary } from './template-summary';
import FormField from '@ingka/form-field';

interface ICreateTemplate {
  templateWithoutName: Omit<TemplateType, 'name'>;
  propMap: Map<string, MetaProperty>;
  templates: Loadable<TemplateListType>;
  onClose: () => void;
  onCreate: (template: TemplateType) => void;
}

export function CreateTemplate(props: ICreateTemplate): JSX.Element {
  const [isSaving, setIsSaving] = useState(false);

  const [name, setName] = useState('');

  const { templateWithoutName, propMap, templates } = props;

  if (templates.status !== 'loaded') {
    return <div>loading...</div>;
  }

  const handleSubmit = async (name: string) => {
    setIsSaving(true);

    const newTemplate = { ...templateWithoutName, name };

    await setIDBTemplates([...templates.data, newTemplate]);

    setIsSaving(false);
    props.onCreate(newTemplate);
  };

  return (
    <Modal
      visible={true} // Opens / Closes modal
      escapable={false} // the modal view can't be dismissed without interacting with modal content
      handleCloseBtn={props.onClose} // on Internal close request
    >
      <Sheets
        title="Share board with users" // Prompt's heading
        header={<ModalHeader></ModalHeader>}
        footer={
          <ModalFooter>
            <Button
              text="Create template"
              type="primary"
              disabled={isSaving}
              onClick={() => handleSubmit(name)}
            />
          </ModalFooter>
        }
      >
        <ModalBody>
          <TemplateSummary values={templateWithoutName.values} propMap={propMap} />

          <div>
            <FormField
              valid={!templates.data.find((n) => n.name === name)}
              shouldValidate={name.length > 0}
              validation={{
                msg: 'Template name already exists.',
                type: 'error',
                id: 'template-already-exists',
              }}
            >
              <InputField
                label="Template name"
                id="template_name"
                type="text"
                autoFocus={true}
                value={name}
                disabled={isSaving}
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.code === 'Enter') {
                    void handleSubmit(name);
                  }
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setName(e.currentTarget.value);
                }}
              />
            </FormField>
          </div>
        </ModalBody>
      </Sheets>
    </Modal>
  );
}
