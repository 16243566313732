/** @jsxImportSource @emotion/react */
import React from 'react';
import Button from '@ingka/button';
import { useNavigate } from 'react-router-dom';
import { Asset } from '../../generated-backend-api';
import CropIcon from '@ingka/ssr-icon/paths/selection-hidden';
import { css } from '@emotion/react';

export const CropAssetButton = ({ asset }: { asset: Asset }) => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={(event: React.MouseEvent) => {
        event.preventDefault();
        navigate(`/crop/${asset.id}`)?.catch((e: Error) => {
          console.error('Failed to navigate', e);
          throw e;
        });
      }}
      size="small"
      iconOnly
      title="Create crop"
      ssrIcon={CropIcon}
      type="tertiary"
      css={css`
        position: absolute;
        top: 0;
        right: 0;
        transform: scale(0.8);
        background: rgba(255, 255, 255, 0.5);
        box-shadow: 0 0 4px #000;
      `}
    />
  );
};

export default CropAssetButton;
