/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    ExtRequestParamsIsPublic,
    ExtRequestParamsIsPublicFromJSON,
    ExtRequestParamsIsPublicFromJSONTyped,
    ExtRequestParamsIsPublicToJSON,
    ExtRequestParamsIsShoppable,
    ExtRequestParamsIsShoppableFromJSON,
    ExtRequestParamsIsShoppableFromJSONTyped,
    ExtRequestParamsIsShoppableToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExtRequestParams
 */
export interface ExtRequestParams {
    /**
     * 
     * @type {Array<string>}
     * @memberof ExtRequestParams
     */
    usageRights: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExtRequestParams
     */
    requestingMarket: string;
    /**
     * 
     * @type {string}
     * @memberof ExtRequestParams
     */
    id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExtRequestParams
     */
    ids?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ExtRequestParams
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof ExtRequestParams
     */
    limit?: number;
    /**
     * 
     * @type {string}
     * @memberof ExtRequestParams
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtRequestParams
     */
    namePrefix?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtRequestParams
     */
    changedSince?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtRequestParams
     */
    changedBefore?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExtRequestParams
     */
    productName?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExtRequestParams
     */
    itemNo?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExtRequestParams
     */
    aspectRatio?: Array<string>;
    /**
     * 
     * @type {ExtRequestParamsIsPublic}
     * @memberof ExtRequestParams
     */
    isPublic?: ExtRequestParamsIsPublic;
    /**
     * 
     * @type {ExtRequestParamsIsShoppable}
     * @memberof ExtRequestParams
     */
    isShoppable?: ExtRequestParamsIsShoppable;
    /**
     * 
     * @type {string}
     * @memberof ExtRequestParams
     */
    type?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExtRequestParams
     */
    property_ContentType?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExtRequestParams
     */
    property_icom_project_name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExtRequestParams
     */
    property_LayoutID?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExtRequestParams
     */
    property_BriefName?: Array<string>;
}

export function ExtRequestParamsFromJSON(json: any): ExtRequestParams {
    return ExtRequestParamsFromJSONTyped(json, false);
}

export function ExtRequestParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtRequestParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'usageRights': json['usageRights'],
        'requestingMarket': json['requestingMarket'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'ids': !exists(json, 'ids') ? undefined : json['ids'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'namePrefix': !exists(json, 'namePrefix') ? undefined : json['namePrefix'],
        'changedSince': !exists(json, 'changedSince') ? undefined : json['changedSince'],
        'changedBefore': !exists(json, 'changedBefore') ? undefined : json['changedBefore'],
        'productName': !exists(json, 'productName') ? undefined : json['productName'],
        'itemNo': !exists(json, 'itemNo') ? undefined : json['itemNo'],
        'aspectRatio': !exists(json, 'aspectRatio') ? undefined : json['aspectRatio'],
        'isPublic': !exists(json, 'isPublic') ? undefined : ExtRequestParamsIsPublicFromJSON(json['isPublic']),
        'isShoppable': !exists(json, 'isShoppable') ? undefined : ExtRequestParamsIsShoppableFromJSON(json['isShoppable']),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'property_ContentType': !exists(json, 'property_ContentType') ? undefined : json['property_ContentType'],
        'property_icom_project_name': !exists(json, 'property_icom_project_name') ? undefined : json['property_icom_project_name'],
        'property_LayoutID': !exists(json, 'property_LayoutID') ? undefined : json['property_LayoutID'],
        'property_BriefName': !exists(json, 'property_BriefName') ? undefined : json['property_BriefName'],
    };
}

export function ExtRequestParamsToJSON(value?: ExtRequestParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'usageRights': value.usageRights,
        'requestingMarket': value.requestingMarket,
        'id': value.id,
        'ids': value.ids,
        'offset': value.offset,
        'limit': value.limit,
        'name': value.name,
        'namePrefix': value.namePrefix,
        'changedSince': value.changedSince,
        'changedBefore': value.changedBefore,
        'productName': value.productName,
        'itemNo': value.itemNo,
        'aspectRatio': value.aspectRatio,
        'isPublic': ExtRequestParamsIsPublicToJSON(value.isPublic),
        'isShoppable': ExtRequestParamsIsShoppableToJSON(value.isShoppable),
        'type': value.type,
        'property_ContentType': value.property_ContentType,
        'property_icom_project_name': value.property_icom_project_name,
        'property_LayoutID': value.property_LayoutID,
        'property_BriefName': value.property_BriefName,
    };
}


