/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface CouldNotDecryptSecretsError
 */
export interface CouldNotDecryptSecretsError {
    /**
     * 
     * @type {boolean}
     * @memberof CouldNotDecryptSecretsError
     */
    isError: CouldNotDecryptSecretsErrorIsErrorEnum;
    /**
     * 
     * @type {string}
     * @memberof CouldNotDecryptSecretsError
     */
    type: CouldNotDecryptSecretsErrorTypeEnum;
}


/**
* @export
* @enum {boolean}
*/
export type CouldNotDecryptSecretsErrorIsErrorEnum = true;




/**
* @export
* @enum {string}
*/
export type CouldNotDecryptSecretsErrorTypeEnum = 'could-not-decrypt-secrets';





export function CouldNotDecryptSecretsErrorFromJSON(json: any): CouldNotDecryptSecretsError {
    return CouldNotDecryptSecretsErrorFromJSONTyped(json, false);
}

export function CouldNotDecryptSecretsErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): CouldNotDecryptSecretsError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isError': json['isError'],
        'type': json['type'],
    };
}

export function CouldNotDecryptSecretsErrorToJSON(value?: CouldNotDecryptSecretsError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isError': value.isError,
        'type': value.type,
    };
}


