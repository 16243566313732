import React, { JSX } from 'react';
import { Loadable } from '../util/type';

import { FindSimilarResponse } from '../util/find-similar';
import { WithLoadedLoadable } from './with-loaded-loadable';
import Loading, { LoadingBall } from '@ingka/loading';
import { SimilarImage } from './similar-image';
import { Grid } from './grid';

interface SimilarListProps {
  bynderBaseUrl: string;
  similar: Loadable<FindSimilarResponse>;
  selectedIds?: string[];
  boardAssetIds?: string[];
  onSelectedIdsChanged?: (ids: string[]) => void;
}

export function SimilarList(props: SimilarListProps): JSX.Element {
  const { bynderBaseUrl, similar, onSelectedIdsChanged } = props;

  const selectedIds = new Set(props.selectedIds ?? []);
  const boardAssetIds = new Set(props.boardAssetIds ?? []);

  return (
    <WithLoadedLoadable
      loadable={similar}
      fallback={
        <Loading text="Loading results.">
          <LoadingBall />
        </Loading>
      }
    >
      {(data) => {
        if (!data) {
          return <div>Could not load search result.</div>;
        }

        const c = data.searchResult.assets.map((result, i) => {
          return (
            <SimilarImage
              asset={result}
              bynderBaseUrl={bynderBaseUrl}
              selected={selectedIds.has(result.id)}
              similarityScore={data.similar[i].distance}
              inBoard={boardAssetIds.has(result.id)}
              key={result.id}
              onImageClicked={() => {
                if (!onSelectedIdsChanged) {
                  return;
                }

                if (selectedIds.has(result.id)) {
                  onSelectedIdsChanged([...selectedIds].filter((id) => id !== result.id));
                } else {
                  onSelectedIdsChanged([...selectedIds, result.id]);
                }
              }}
            />
          );
        });

        return (
          <Grid columns={4} rowGap={40} colGap={20}>
            <>{c}</>;
          </Grid>
        );
      }}
    </WithLoadedLoadable>
  );
}
