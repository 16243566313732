import Toast from '@ingka/toast';
import React, { createContext, JSX, PropsWithChildren, useState } from 'react';

const ToastsContext = createContext<IToasts>({ push: () => console.warn('toast not loaded') });

interface IToast {
  message: string;
  isError: boolean;
}

interface IToasts {
  push: (toast: IToast) => void;
}

export function useToasts(): IToasts {
  const context = React.useContext(ToastsContext);

  return context;
}

export const ToastsProvider = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
  const [toasts, setToasts] = useState<IToast[]>([]);

  const toastsApi: IToasts = {
    push: (toast) => {
      setToasts([...toasts, toast]);
    },
  };

  let toast;

  if (toasts.length > 0) {
    const t = toasts[0];

    toast = (
      <Toast
        text={t.message}
        isOpen={true}
        onCloseRequest={() => setToasts([...toasts].slice(1))}
        style={t.isError ? { backgroundColor: 'red' } : {}}
      />
    );
  }

  return (
    <ToastsContext.Provider value={toastsApi}>
      {toast}
      {children}
    </ToastsContext.Provider>
  );
};
