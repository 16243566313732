/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface JobRetry
 */
export interface JobRetry {
    /**
     * 
     * @type {string}
     * @memberof JobRetry
     */
    type: JobRetryTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof JobRetry
     */
    attempt: number;
    /**
     * 
     * @type {number}
     * @memberof JobRetry
     */
    maxAttempts: number;
    /**
     * 
     * @type {number}
     * @memberof JobRetry
     */
    timestamp: number;
    /**
     * 
     * @type {string}
     * @memberof JobRetry
     */
    user: string;
}


/**
* @export
* @enum {string}
*/
export type JobRetryTypeEnum = 'job-retry';





export function JobRetryFromJSON(json: any): JobRetry {
    return JobRetryFromJSONTyped(json, false);
}

export function JobRetryFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobRetry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'attempt': json['attempt'],
        'maxAttempts': json['maxAttempts'],
        'timestamp': json['timestamp'],
        'user': json['user'],
    };
}

export function JobRetryToJSON(value?: JobRetry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'attempt': value.attempt,
        'maxAttempts': value.maxAttempts,
        'timestamp': value.timestamp,
        'user': value.user,
    };
}


