/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    FSVIAssetMetaDataV2Roomclassification,
    FSVIAssetMetaDataV2RoomclassificationFromJSON,
    FSVIAssetMetaDataV2RoomclassificationFromJSONTyped,
    FSVIAssetMetaDataV2RoomclassificationToJSON,
    FSVIAssetMetaDataV2Stylegroup,
    FSVIAssetMetaDataV2StylegroupFromJSON,
    FSVIAssetMetaDataV2StylegroupFromJSONTyped,
    FSVIAssetMetaDataV2StylegroupToJSON,
    ImageColorResult,
    ImageColorResultFromJSON,
    ImageColorResultFromJSONTyped,
    ImageColorResultToJSON,
    ProductColorResult,
    ProductColorResultFromJSON,
    ProductColorResultFromJSONTyped,
    ProductColorResultToJSON,
} from './';

/**
 * 
 * @export
 * @interface FSVIAssetMetaDataV2
 */
export interface FSVIAssetMetaDataV2 {
    /**
     * 
     * @type {Array<number>}
     * @memberof FSVIAssetMetaDataV2
     */
    extract?: Array<number>;
    /**
     * 
     * @type {Array<ImageColorResult>}
     * @memberof FSVIAssetMetaDataV2
     */
    imagecolor?: Array<ImageColorResult>;
    /**
     * 
     * @type {{ [key: string]: Array<ProductColorResult>; }}
     * @memberof FSVIAssetMetaDataV2
     */
    productcolor?: { [key: string]: Array<ProductColorResult>; };
    /**
     * 
     * @type {Array<FSVIAssetMetaDataV2Roomclassification>}
     * @memberof FSVIAssetMetaDataV2
     */
    roomclassification?: Array<FSVIAssetMetaDataV2Roomclassification>;
    /**
     * 
     * @type {Array<FSVIAssetMetaDataV2Stylegroup>}
     * @memberof FSVIAssetMetaDataV2
     */
    stylegroup?: Array<FSVIAssetMetaDataV2Stylegroup>;
}

export function FSVIAssetMetaDataV2FromJSON(json: any): FSVIAssetMetaDataV2 {
    return FSVIAssetMetaDataV2FromJSONTyped(json, false);
}

export function FSVIAssetMetaDataV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): FSVIAssetMetaDataV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'extract': !exists(json, 'extract') ? undefined : json['extract'],
        'imagecolor': !exists(json, 'imagecolor') ? undefined : ((json['imagecolor'] as Array<any>).map(ImageColorResultFromJSON)),
        'productcolor': !exists(json, 'productcolor') ? undefined : json['productcolor'],
        'roomclassification': !exists(json, 'roomclassification') ? undefined : ((json['roomclassification'] as Array<any>).map(FSVIAssetMetaDataV2RoomclassificationFromJSON)),
        'stylegroup': !exists(json, 'stylegroup') ? undefined : ((json['stylegroup'] as Array<any>).map(FSVIAssetMetaDataV2StylegroupFromJSON)),
    };
}

export function FSVIAssetMetaDataV2ToJSON(value?: FSVIAssetMetaDataV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'extract': value.extract,
        'imagecolor': value.imagecolor === undefined ? undefined : ((value.imagecolor as Array<any>).map(ImageColorResultToJSON)),
        'productcolor': value.productcolor,
        'roomclassification': value.roomclassification === undefined ? undefined : ((value.roomclassification as Array<any>).map(FSVIAssetMetaDataV2RoomclassificationToJSON)),
        'stylegroup': value.stylegroup === undefined ? undefined : ((value.stylegroup as Array<any>).map(FSVIAssetMetaDataV2StylegroupToJSON)),
    };
}


