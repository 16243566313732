/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// oneof
import {
    CouldNotDecryptSecretsError,
    CouldNotFetchFromFSError,
    CouldNotPerformRequest,
    CouldNotUpdateFSJob,
    MaxAttemptsReachedError,
    NotFoundInFS,
    NotLoggedInError,
    RequestAbortedError,
    StreamCancelledError,
    StreamError,
    TooManyPollingErrors,
    UnexpectedResponseError,
    ValidationError,
    CouldNotDecryptSecretsErrorFromJSONTyped,
    CouldNotDecryptSecretsErrorToJSON,
    CouldNotFetchFromFSErrorFromJSONTyped,
    CouldNotFetchFromFSErrorToJSON,
    CouldNotPerformRequestFromJSONTyped,
    CouldNotPerformRequestToJSON,
    CouldNotUpdateFSJobFromJSONTyped,
    CouldNotUpdateFSJobToJSON,
    MaxAttemptsReachedErrorFromJSONTyped,
    MaxAttemptsReachedErrorToJSON,
    NotFoundInFSFromJSONTyped,
    NotFoundInFSToJSON,
    NotLoggedInErrorFromJSONTyped,
    NotLoggedInErrorToJSON,
    RequestAbortedErrorFromJSONTyped,
    RequestAbortedErrorToJSON,
    StreamCancelledErrorFromJSONTyped,
    StreamCancelledErrorToJSON,
    StreamErrorFromJSONTyped,
    StreamErrorToJSON,
    TooManyPollingErrorsFromJSONTyped,
    TooManyPollingErrorsToJSON,
    UnexpectedResponseErrorFromJSONTyped,
    UnexpectedResponseErrorToJSON,
    ValidationErrorFromJSONTyped,
    ValidationErrorToJSON,
} from './';

/**
 * oneof interface
 * @type JobFailedError
 * 
 * @export
 */
export type JobFailedError = CouldNotDecryptSecretsError | CouldNotFetchFromFSError | CouldNotPerformRequest | CouldNotUpdateFSJob | ValidationError | MaxAttemptsReachedError | NotFoundInFS | NotLoggedInError | RequestAbortedError | StreamCancelledError | StreamError | TooManyPollingErrors | UnexpectedResponseError;


export function JobFailedErrorFromJSON(json: any): JobFailedError {
    return JobFailedErrorFromJSONTyped(json, false);
}

export function JobFailedErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobFailedError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'could-not-decrypt-secrets':
            return {...CouldNotDecryptSecretsErrorFromJSONTyped(json, true), type: 'could-not-decrypt-secrets'};
        case 'could-not-fetch-from-fs':
            return {...CouldNotFetchFromFSErrorFromJSONTyped(json, true), type: 'could-not-fetch-from-fs'};
        case 'could-not-perform-request':
            return {...CouldNotPerformRequestFromJSONTyped(json, true), type: 'could-not-perform-request'};
        case 'could-not-update-fs-job':
            return {...CouldNotUpdateFSJobFromJSONTyped(json, true), type: 'could-not-update-fs-job'};
        case 'failed-to-validate':
            return {...ValidationErrorFromJSONTyped(json, true), type: 'failed-to-validate'};
        case 'max-attempts-reached':
            return {...MaxAttemptsReachedErrorFromJSONTyped(json, true), type: 'max-attempts-reached'};
        case 'not-found-in-fs':
            return {...NotFoundInFSFromJSONTyped(json, true), type: 'not-found-in-fs'};
        case 'not-logged-in':
            return {...NotLoggedInErrorFromJSONTyped(json, true), type: 'not-logged-in'};
        case 'request-aborted':
            return {...RequestAbortedErrorFromJSONTyped(json, true), type: 'request-aborted'};
        case 'stream-cancelled':
            return {...StreamCancelledErrorFromJSONTyped(json, true), type: 'stream-cancelled'};
        case 'stream-error':
            return {...StreamErrorFromJSONTyped(json, true), type: 'stream-error'};
        case 'too-many-polling-errors':
            return {...TooManyPollingErrorsFromJSONTyped(json, true), type: 'too-many-polling-errors'};
        case 'unexpected-response':
            return {...UnexpectedResponseErrorFromJSONTyped(json, true), type: 'unexpected-response'};
        default:
            throw new Error(`No variant of JobFailedError exists with 'type=${json['type']}'`);
    }
}

export function JobFailedErrorToJSON(value?: JobFailedError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'could-not-decrypt-secrets':
            return CouldNotDecryptSecretsErrorToJSON(value);
        case 'could-not-fetch-from-fs':
            return CouldNotFetchFromFSErrorToJSON(value);
        case 'could-not-perform-request':
            return CouldNotPerformRequestToJSON(value);
        case 'could-not-update-fs-job':
            return CouldNotUpdateFSJobToJSON(value);
        case 'failed-to-validate':
            return ValidationErrorToJSON(value);
        case 'max-attempts-reached':
            return MaxAttemptsReachedErrorToJSON(value);
        case 'not-found-in-fs':
            return NotFoundInFSToJSON(value);
        case 'not-logged-in':
            return NotLoggedInErrorToJSON(value);
        case 'request-aborted':
            return RequestAbortedErrorToJSON(value);
        case 'stream-cancelled':
            return StreamCancelledErrorToJSON(value);
        case 'stream-error':
            return StreamErrorToJSON(value);
        case 'too-many-polling-errors':
            return TooManyPollingErrorsToJSON(value);
        case 'unexpected-response':
            return UnexpectedResponseErrorToJSON(value);
        default:
            throw new Error(`No variant of JobFailedError exists with 'type=${value['type']}'`);
    }
}

