/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    CreateBynderJobRequestBodyItems,
    CreateBynderJobRequestBodyItemsFromJSON,
    CreateBynderJobRequestBodyItemsFromJSONTyped,
    CreateBynderJobRequestBodyItemsToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateBynderJobRequestBody
 */
export interface CreateBynderJobRequestBody {
    /**
     * 
     * @type {string}
     * @memberof CreateBynderJobRequestBody
     */
    accessToken: string;
    /**
     * 
     * @type {string}
     * @memberof CreateBynderJobRequestBody
     */
    refreshToken: string;
    /**
     * 
     * @type {Array<CreateBynderJobRequestBodyItems>}
     * @memberof CreateBynderJobRequestBody
     */
    items: Array<CreateBynderJobRequestBodyItems>;
}

export function CreateBynderJobRequestBodyFromJSON(json: any): CreateBynderJobRequestBody {
    return CreateBynderJobRequestBodyFromJSONTyped(json, false);
}

export function CreateBynderJobRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBynderJobRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': json['accessToken'],
        'refreshToken': json['refreshToken'],
        'items': ((json['items'] as Array<any>).map(CreateBynderJobRequestBodyItemsFromJSON)),
    };
}

export function CreateBynderJobRequestBodyToJSON(value?: CreateBynderJobRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessToken': value.accessToken,
        'refreshToken': value.refreshToken,
        'items': ((value.items as Array<any>).map(CreateBynderJobRequestBodyItemsToJSON)),
    };
}


