import React, { JSX } from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Select, { components } from 'react-select';
import { TemplateListType, TemplateType } from '../templates';
import { hasKey, hasNumberKey } from '../util/type';
import { MetaProperty } from '../generated-backend-api';
import { TemplateSummary } from './template-summary';
import Button from '@ingka/button';
import trashCan from '@ingka/ssr-icon/paths/trash-can';

interface ITemplateSelectProps {
  templates: TemplateListType;
  onSelectTemplate: (template: TemplateType) => void;
  onDeleteTemplate: (template: TemplateType) => void;
  propMap: Map<string, MetaProperty>;
}

export function TemplateSelect({
  templates,
  propMap,
  onSelectTemplate,
  onDeleteTemplate,
}: ITemplateSelectProps): JSX.Element {
  const sortedTemplates = templates.sort((p1, p2) => p2.timestamp - p1.timestamp);

  return (
    <Select
      css={css`
        max-width: 300px;
      `}
      components={{
        MenuList: function Opt(props) {
          const q: unknown = props;

          let info;

          if (hasKey('focusedOption', q) && hasNumberKey('value', q.focusedOption)) {
            const template = templates[q.focusedOption.value];

            info = (
              <div
                css={css`
                  position: absolute;
                  left: 100%;
                  min-width: 400px;
                  min-height: 200px;
                  padding: 20px;
                  background-color: #fff;
                  margin-left: 10px;
                  background-color: hsl(0, 0%, 100%);
                  border-radius: 4px;
                  box-shadow:
                    0 0 0 1px hsl(0deg 0% 0% / 10%),
                    0 4px 11px hsl(0deg 0% 0% / 10%);
                `}
              >
                <div
                  css={css`
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    h5 {
                      flex: 1;
                    }
                  `}
                >
                  <h5>{template.name}</h5>
                  <Button
                    iconOnly
                    ssrIcon={trashCan}
                    size="small"
                    type="tertiary"
                    onClick={() => onDeleteTemplate(template)}
                  />
                </div>
                <TemplateSummary values={template.values} propMap={propMap} />
              </div>
            );
          }

          return (
            <>
              {info}
              <components.MenuList {...props} />
            </>
          );
        },
      }}
      options={sortedTemplates.map((p, index) => {
        return {
          label: p.name,
          value: index,
        };
      })}
      onChange={(e) => {
        if (!e || typeof e.value !== 'number') {
          return;
        }

        const z = templates[e.value];
        onSelectTemplate(z);
      }}
    />
  );
}
