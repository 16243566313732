/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    PIAItemProductTypeLocalCountryCode,
    PIAItemProductTypeLocalCountryCodeFromJSON,
    PIAItemProductTypeLocalCountryCodeFromJSONTyped,
    PIAItemProductTypeLocalCountryCodeToJSON,
    PIAItemProductTypeLocalLanguageCode,
    PIAItemProductTypeLocalLanguageCodeFromJSON,
    PIAItemProductTypeLocalLanguageCodeFromJSONTyped,
    PIAItemProductTypeLocalLanguageCodeToJSON,
    PIAItemProductTypeLocalProductTypeName,
    PIAItemProductTypeLocalProductTypeNameFromJSON,
    PIAItemProductTypeLocalProductTypeNameFromJSONTyped,
    PIAItemProductTypeLocalProductTypeNameToJSON,
    PIAItemProductTypeLocalProductTypeNamePart,
    PIAItemProductTypeLocalProductTypeNamePartFromJSON,
    PIAItemProductTypeLocalProductTypeNamePartFromJSONTyped,
    PIAItemProductTypeLocalProductTypeNamePartToJSON,
    PIAItemProductTypeLocalProductTypeNo,
    PIAItemProductTypeLocalProductTypeNoFromJSON,
    PIAItemProductTypeLocalProductTypeNoFromJSONTyped,
    PIAItemProductTypeLocalProductTypeNoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PIAItemProductTypeLocal
 */
export interface PIAItemProductTypeLocal {
    /**
     * 
     * @type {PIAItemProductTypeLocalCountryCode}
     * @memberof PIAItemProductTypeLocal
     */
    countryCode: PIAItemProductTypeLocalCountryCode | null;
    /**
     * 
     * @type {PIAItemProductTypeLocalLanguageCode}
     * @memberof PIAItemProductTypeLocal
     */
    languageCode: PIAItemProductTypeLocalLanguageCode | null;
    /**
     * 
     * @type {PIAItemProductTypeLocalProductTypeNo}
     * @memberof PIAItemProductTypeLocal
     */
    productTypeNo: PIAItemProductTypeLocalProductTypeNo | null;
    /**
     * 
     * @type {PIAItemProductTypeLocalProductTypeName}
     * @memberof PIAItemProductTypeLocal
     */
    productTypeName: PIAItemProductTypeLocalProductTypeName | null;
    /**
     * 
     * @type {PIAItemProductTypeLocalProductTypeNamePart}
     * @memberof PIAItemProductTypeLocal
     */
    productTypeNamePart: PIAItemProductTypeLocalProductTypeNamePart | null;
}

export function PIAItemProductTypeLocalFromJSON(json: any): PIAItemProductTypeLocal {
    return PIAItemProductTypeLocalFromJSONTyped(json, false);
}

export function PIAItemProductTypeLocalFromJSONTyped(json: any, ignoreDiscriminator: boolean): PIAItemProductTypeLocal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countryCode': PIAItemProductTypeLocalCountryCodeFromJSON(json['countryCode']),
        'languageCode': PIAItemProductTypeLocalLanguageCodeFromJSON(json['languageCode']),
        'productTypeNo': PIAItemProductTypeLocalProductTypeNoFromJSON(json['productTypeNo']),
        'productTypeName': PIAItemProductTypeLocalProductTypeNameFromJSON(json['productTypeName']),
        'productTypeNamePart': PIAItemProductTypeLocalProductTypeNamePartFromJSON(json['productTypeNamePart']),
    };
}

export function PIAItemProductTypeLocalToJSON(value?: PIAItemProductTypeLocal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countryCode': PIAItemProductTypeLocalCountryCodeToJSON(value.countryCode),
        'languageCode': PIAItemProductTypeLocalLanguageCodeToJSON(value.languageCode),
        'productTypeNo': PIAItemProductTypeLocalProductTypeNoToJSON(value.productTypeNo),
        'productTypeName': PIAItemProductTypeLocalProductTypeNameToJSON(value.productTypeName),
        'productTypeNamePart': PIAItemProductTypeLocalProductTypeNamePartToJSON(value.productTypeNamePart),
    };
}


