/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface FSSearchRequestBodyID
 */
export interface FSSearchRequestBodyID {
    /**
     * 
     * @type {Array<string>}
     * @memberof FSSearchRequestBodyID
     */
    IDList: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof FSSearchRequestBodyID
     */
    full?: boolean;
}

export function FSSearchRequestBodyIDFromJSON(json: any): FSSearchRequestBodyID {
    return FSSearchRequestBodyIDFromJSONTyped(json, false);
}

export function FSSearchRequestBodyIDFromJSONTyped(json: any, ignoreDiscriminator: boolean): FSSearchRequestBodyID {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'IDList': json['IDList'],
        'full': !exists(json, 'full') ? undefined : json['full'],
    };
}

export function FSSearchRequestBodyIDToJSON(value?: FSSearchRequestBodyID | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'IDList': value.IDList,
        'full': value.full,
    };
}


