/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface FSSearchRequestBody
 */
export interface FSSearchRequestBody {
    /**
     * 
     * @type {Array<string>}
     * @memberof FSSearchRequestBody
     */
    phList: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof FSSearchRequestBody
     */
    full?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FSSearchRequestBody
     */
    prefixSearch?: boolean;
}

export function FSSearchRequestBodyFromJSON(json: any): FSSearchRequestBody {
    return FSSearchRequestBodyFromJSONTyped(json, false);
}

export function FSSearchRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): FSSearchRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phList': json['phList'],
        'full': !exists(json, 'full') ? undefined : json['full'],
        'prefixSearch': !exists(json, 'prefixSearch') ? undefined : json['prefixSearch'],
    };
}

export function FSSearchRequestBodyToJSON(value?: FSSearchRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phList': value.phList,
        'full': value.full,
        'prefixSearch': value.prefixSearch,
    };
}


