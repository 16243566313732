/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface FSAssetProdThumbnails
 */
export interface FSAssetProdThumbnails {
    /**
     * 
     * @type {string}
     * @memberof FSAssetProdThumbnails
     */
    mini?: string;
    /**
     * 
     * @type {string}
     * @memberof FSAssetProdThumbnails
     */
    thul?: string;
    /**
     * 
     * @type {string}
     * @memberof FSAssetProdThumbnails
     */
    webimage?: string;
}

export function FSAssetProdThumbnailsFromJSON(json: any): FSAssetProdThumbnails {
    return FSAssetProdThumbnailsFromJSONTyped(json, false);
}

export function FSAssetProdThumbnailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FSAssetProdThumbnails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mini': !exists(json, 'mini') ? undefined : json['mini'],
        'thul': !exists(json, 'thul') ? undefined : json['thul'],
        'webimage': !exists(json, 'webimage') ? undefined : json['webimage'],
    };
}

export function FSAssetProdThumbnailsToJSON(value?: FSAssetProdThumbnails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mini': value.mini,
        'thul': value.thul,
        'webimage': value.webimage,
    };
}


