/** @jsxImportSource @emotion/react */

import React, { JSX } from 'react';
import { SerializedStyles, css } from '@emotion/react';
import Button from '@ingka/button';
import icon_trash from '@ingka/ssr-icon/paths/trash-can';
import { useImageSrc } from '../hooks/idam';

const nameCellStyle = css([
  css`
    width: 300px;
    display: inline-block;
    margin-right: 10px;
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
  `,
]);

interface Props {
  uniqueKey: string;
  name: string;
  extension: string;
  url: string;
  thumbnailUrl?: string;
  inputs: JSX.Element[];
  disabledUploadItems: Set<string>;
  setDisabledUploadItems: React.Dispatch<React.SetStateAction<Set<string>>>;
  showImages: boolean;
  datoriginal?: string;
  rowStyle: SerializedStyles;
}

const UploadRow = ({
  uniqueKey,
  name,
  extension,
  url,
  thumbnailUrl,
  inputs,
  disabledUploadItems,
  setDisabledUploadItems,
  showImages,
  datoriginal,
  rowStyle,
}: Props) => {
  const src = useImageSrc({ idamUrl: thumbnailUrl || url });

  return (
    <div css={rowStyle}>
      {disabledUploadItems.has(uniqueKey) ? (
        <div
          css={css`
            background-color: rgba(0, 0, 0, 0);
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1000;
          `}
        />
      ) : null}

      <span css={nameCellStyle}>
        <Button
          iconOnly={true}
          ssrIcon={icon_trash}
          type={disabledUploadItems.has(uniqueKey) ? 'primary' : 'tertiary'}
          size="small"
          disabled={typeof datoriginal !== 'undefined'}
          newWindow={true}
          title={'Bynder original link'}
          css={css`
            position: absolute;
            left: -50px;
          `}
          onClick={() => {
            if (disabledUploadItems.has(uniqueKey)) {
              disabledUploadItems.delete(uniqueKey);
            } else {
              disabledUploadItems.add(uniqueKey);
            }

            setDisabledUploadItems(new Set(disabledUploadItems));
          }}
        />
        {showImages ? (
          <div style={{ width: '150px', height: '150px' }}>
            <a
              href={src}
              target="_blank"
              rel="noreferrer"
              css={css`
                display: contents;
              `}
            >
              <img src={src} style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
            </a>
          </div>
        ) : null}
        <span style={{ color: disabledUploadItems.has(uniqueKey) ? '#999' : undefined }}>
          {name}{' '}
          {disabledUploadItems.has(uniqueKey) ? (
            <>
              <br />
              (disabled)
            </>
          ) : null}
        </span>
        <span style={{ marginRight: '20px' }}>{extension}</span>
      </span>

      <div
        css={css`
          display: contents;
        `}
        style={{ visibility: disabledUploadItems.has(uniqueKey) ? 'hidden' : undefined }}
      >
        {inputs}
      </div>
    </div>
  );
};

export default UploadRow;
