/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    ImageColorResult,
    ImageColorResultFromJSON,
    ImageColorResultFromJSONTyped,
    ImageColorResultToJSON,
    VIroomclassification,
    VIroomclassificationFromJSON,
    VIroomclassificationFromJSONTyped,
    VIroomclassificationToJSON,
    VIstylegroup,
    VIstylegroupFromJSON,
    VIstylegroupFromJSONTyped,
    VIstylegroupToJSON,
} from './';

/**
 * 
 * @export
 * @interface VIAssetMetaData
 */
export interface VIAssetMetaData {
    /**
     * 
     * @type {Array<ImageColorResult>}
     * @memberof VIAssetMetaData
     */
    imagecolor?: Array<ImageColorResult>;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof VIAssetMetaData
     */
    productcolor?: { [key: string]: object; };
    /**
     * 
     * @type {Array<VIroomclassification>}
     * @memberof VIAssetMetaData
     */
    roomclassification?: Array<VIroomclassification>;
    /**
     * 
     * @type {Array<VIstylegroup>}
     * @memberof VIAssetMetaData
     */
    stylegroup?: Array<VIstylegroup>;
}

export function VIAssetMetaDataFromJSON(json: any): VIAssetMetaData {
    return VIAssetMetaDataFromJSONTyped(json, false);
}

export function VIAssetMetaDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): VIAssetMetaData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'imagecolor': !exists(json, 'imagecolor') ? undefined : ((json['imagecolor'] as Array<any>).map(ImageColorResultFromJSON)),
        'productcolor': !exists(json, 'productcolor') ? undefined : json['productcolor'],
        'roomclassification': !exists(json, 'roomclassification') ? undefined : ((json['roomclassification'] as Array<any>).map(VIroomclassificationFromJSON)),
        'stylegroup': !exists(json, 'stylegroup') ? undefined : ((json['stylegroup'] as Array<any>).map(VIstylegroupFromJSON)),
    };
}

export function VIAssetMetaDataToJSON(value?: VIAssetMetaData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'imagecolor': value.imagecolor === undefined ? undefined : ((value.imagecolor as Array<any>).map(ImageColorResultToJSON)),
        'productcolor': value.productcolor,
        'roomclassification': value.roomclassification === undefined ? undefined : ((value.roomclassification as Array<any>).map(VIroomclassificationToJSON)),
        'stylegroup': value.stylegroup === undefined ? undefined : ((value.stylegroup as Array<any>).map(VIstylegroupToJSON)),
    };
}


