/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    IMCCSearchAggregations,
    IMCCSearchAggregationsFromJSON,
    IMCCSearchAggregationsFromJSONTyped,
    IMCCSearchAggregationsToJSON,
    IMCCSearchInfoMaxScore,
    IMCCSearchInfoMaxScoreFromJSON,
    IMCCSearchInfoMaxScoreFromJSONTyped,
    IMCCSearchInfoMaxScoreToJSON,
    IMCCSearchTotalHits,
    IMCCSearchTotalHitsFromJSON,
    IMCCSearchTotalHitsFromJSONTyped,
    IMCCSearchTotalHitsToJSON,
} from './';

/**
 * 
 * @export
 * @interface IMCCSearchInfo
 */
export interface IMCCSearchInfo {
    /**
     * 
     * @type {Array<IMCCSearchAggregations>}
     * @memberof IMCCSearchInfo
     */
    aggregations: Array<IMCCSearchAggregations>;
    /**
     * 
     * @type {IMCCSearchInfoMaxScore}
     * @memberof IMCCSearchInfo
     */
    maxScore: IMCCSearchInfoMaxScore | null;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchInfo
     */
    nextPage: string;
    /**
     * 
     * @type {number}
     * @memberof IMCCSearchInfo
     */
    took: number;
    /**
     * 
     * @type {IMCCSearchTotalHits}
     * @memberof IMCCSearchInfo
     */
    totalHits: IMCCSearchTotalHits;
}

export function IMCCSearchInfoFromJSON(json: any): IMCCSearchInfo {
    return IMCCSearchInfoFromJSONTyped(json, false);
}

export function IMCCSearchInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IMCCSearchInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aggregations': ((json['aggregations'] as Array<any>).map(IMCCSearchAggregationsFromJSON)),
        'maxScore': IMCCSearchInfoMaxScoreFromJSON(json['maxScore']),
        'nextPage': json['nextPage'],
        'took': json['took'],
        'totalHits': IMCCSearchTotalHitsFromJSON(json['totalHits']),
    };
}

export function IMCCSearchInfoToJSON(value?: IMCCSearchInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aggregations': ((value.aggregations as Array<any>).map(IMCCSearchAggregationsToJSON)),
        'maxScore': IMCCSearchInfoMaxScoreToJSON(value.maxScore),
        'nextPage': value.nextPage,
        'took': value.took,
        'totalHits': IMCCSearchTotalHitsToJSON(value.totalHits),
    };
}


