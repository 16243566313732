/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    PIAItemIdentifier,
    PIAItemIdentifierFromJSON,
    PIAItemIdentifierFromJSONTyped,
    PIAItemIdentifierToJSON,
} from './';

/**
 * 
 * @export
 * @interface PIABulkSearchRequestBody
 */
export interface PIABulkSearchRequestBody {
    /**
     * 
     * @type {Array<PIAItemIdentifier>}
     * @memberof PIABulkSearchRequestBody
     */
    items: Array<PIAItemIdentifier>;
}

export function PIABulkSearchRequestBodyFromJSON(json: any): PIABulkSearchRequestBody {
    return PIABulkSearchRequestBodyFromJSONTyped(json, false);
}

export function PIABulkSearchRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PIABulkSearchRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(PIAItemIdentifierFromJSON)),
    };
}

export function PIABulkSearchRequestBodyToJSON(value?: PIABulkSearchRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(PIAItemIdentifierToJSON)),
    };
}


