/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface FSAssetDevVisualSearch
 */
export interface FSAssetDevVisualSearch {
    /**
     * 
     * @type {Array<number>}
     * @memberof FSAssetDevVisualSearch
     */
    extract?: Array<number>;
}

export function FSAssetDevVisualSearchFromJSON(json: any): FSAssetDevVisualSearch {
    return FSAssetDevVisualSearchFromJSONTyped(json, false);
}

export function FSAssetDevVisualSearchFromJSONTyped(json: any, ignoreDiscriminator: boolean): FSAssetDevVisualSearch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'extract': !exists(json, 'extract') ? undefined : json['extract'],
    };
}

export function FSAssetDevVisualSearchToJSON(value?: FSAssetDevVisualSearch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'extract': value.extract,
    };
}


