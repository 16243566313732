/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    IMAssetMeta,
    IMAssetMetaFromJSON,
    IMAssetMetaFromJSONTyped,
    IMAssetMetaToJSON,
} from './';

/**
 * 
 * @export
 * @interface IMAsset
 */
export interface IMAsset {
    /**
     * 
     * @type {IMAssetMeta}
     * @memberof IMAsset
     */
    meta: IMAssetMeta;
    /**
     * 
     * @type {string}
     * @memberof IMAsset
     */
    url: string;
}

export function IMAssetFromJSON(json: any): IMAsset {
    return IMAssetFromJSONTyped(json, false);
}

export function IMAssetFromJSONTyped(json: any, ignoreDiscriminator: boolean): IMAsset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meta': IMAssetMetaFromJSON(json['meta']),
        'url': json['url'],
    };
}

export function IMAssetToJSON(value?: IMAsset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meta': IMAssetMetaToJSON(value.meta),
        'url': value.url,
    };
}


