/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * oneof interface
 * @type PIAItemProductTypeLocalProductTypeNamePart
 * 
 * @export
 */
export type PIAItemProductTypeLocalProductTypeNamePart = string | null;


export function PIAItemProductTypeLocalProductTypeNamePartFromJSON(json: any): PIAItemProductTypeLocalProductTypeNamePart {
    return PIAItemProductTypeLocalProductTypeNamePartFromJSONTyped(json, false);
}

export function PIAItemProductTypeLocalProductTypeNamePartFromJSONTyped(json: any, ignoreDiscriminator: boolean): PIAItemProductTypeLocalProductTypeNamePart {
    if ((json === undefined) || (json === null)) {
        return json;
    }

    const validTypes = ['string'];

    if (validTypes.includes(typeof json)){
        return json;
    }

    throw new Error(`Invalid input type '${typeof json}' for PIAItemProductTypeLocalProductTypeNamePart, expected one of ${validTypes.join(', ')}`);

    // return { ...stringFromJSONTyped(json, true) };
}

export function PIAItemProductTypeLocalProductTypeNamePartToJSON(value?: PIAItemProductTypeLocalProductTypeNamePart | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    const validTypes = ['string'];

    if (validTypes.includes(typeof value)){
        return value;
    }

    throw new Error(`Invalid input type '${typeof value}' for PIAItemProductTypeLocalProductTypeNamePart, expected one of ${validTypes.join(', ')}`);

    // return { ...stringToJSON(value) };

}

