/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface ProductInformation
 */
export interface ProductInformation {
    /**
     * 
     * @type {string}
     * @memberof ProductInformation
     */
    altText?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductInformation
     */
    itemNo?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductInformation
     */
    productName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductInformation
     */
    productCategory?: string;
}

export function ProductInformationFromJSON(json: any): ProductInformation {
    return ProductInformationFromJSONTyped(json, false);
}

export function ProductInformationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductInformation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'altText': !exists(json, 'altText') ? undefined : json['altText'],
        'itemNo': !exists(json, 'itemNo') ? undefined : json['itemNo'],
        'productName': !exists(json, 'productName') ? undefined : json['productName'],
        'productCategory': !exists(json, 'productCategory') ? undefined : json['productCategory'],
    };
}

export function ProductInformationToJSON(value?: ProductInformation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'altText': value.altText,
        'itemNo': value.itemNo,
        'productName': value.productName,
        'productCategory': value.productCategory,
    };
}


