/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface IMCCPackageAsset
 */
export interface IMCCPackageAsset {
    /**
     * 
     * @type {string}
     * @memberof IMCCPackageAsset
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof IMCCPackageAsset
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof IMCCPackageAsset
     */
    downloadUrl: string;
    /**
     * 
     * @type {string}
     * @memberof IMCCPackageAsset
     */
    extension: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof IMCCPackageAsset
     */
    tags: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof IMCCPackageAsset
     */
    fileSize?: number;
    /**
     * 
     * @type {string}
     * @memberof IMCCPackageAsset
     */
    thumbnailUrl?: string;
}

export function IMCCPackageAssetFromJSON(json: any): IMCCPackageAsset {
    return IMCCPackageAssetFromJSONTyped(json, false);
}

export function IMCCPackageAssetFromJSONTyped(json: any, ignoreDiscriminator: boolean): IMCCPackageAsset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'downloadUrl': json['downloadUrl'],
        'extension': json['extension'],
        'tags': json['tags'],
        'fileSize': !exists(json, 'fileSize') ? undefined : json['fileSize'],
        'thumbnailUrl': !exists(json, 'thumbnailUrl') ? undefined : json['thumbnailUrl'],
    };
}

export function IMCCPackageAssetToJSON(value?: IMCCPackageAsset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'downloadUrl': value.downloadUrl,
        'extension': value.extension,
        'tags': value.tags,
        'fileSize': value.fileSize,
        'thumbnailUrl': value.thumbnailUrl,
    };
}


