/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    IMCCSearchHitDownloadItem,
    IMCCSearchHitDownloadItemFromJSON,
    IMCCSearchHitDownloadItemFromJSONTyped,
    IMCCSearchHitDownloadItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface IMCCSearchHit
 */
export interface IMCCSearchHit {
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHit
     */
    agency?: string;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHit
     */
    area?: string;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHit
     */
    assetType?: string;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHit
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHit
     */
    copyright?: string;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHit
     */
    downloadUrl?: string;
    /**
     * 
     * @type {Array<IMCCSearchHitDownloadItem>}
     * @memberof IMCCSearchHit
     */
    downloads?: Array<IMCCSearchHitDownloadItem>;
    /**
     * 
     * @type {number}
     * @memberof IMCCSearchHit
     */
    dpi?: number;
    /**
     * 
     * @type {number}
     * @memberof IMCCSearchHit
     */
    editionYear?: number;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHit
     */
    fileExtension?: string;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHit
     */
    fiscalYear?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IMCCSearchHit
     */
    hasAnimals?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IMCCSearchHit
     */
    hasClippingPath?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof IMCCSearchHit
     */
    hasPeople?: boolean;
    /**
     * 
     * @type {number}
     * @memberof IMCCSearchHit
     */
    height?: number;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHit
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IMCCSearchHit
     */
    isReleased?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHit
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHit
     */
    orientation?: string;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHit
     */
    previewUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHit
     */
    producedFor?: string;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHit
     */
    productDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHit
     */
    productName?: string;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHit
     */
    productType?: string;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHit
     */
    productionMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHit
     */
    provider?: string;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHit
     */
    releaseDateAt?: string;
    /**
     * 
     * @type {number}
     * @memberof IMCCSearchHit
     */
    relevanceScore?: number;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHit
     */
    section?: string;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHit
     */
    smallThumbnailUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHit
     */
    thumbnailUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof IMCCSearchHit
     */
    uploadedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof IMCCSearchHit
     */
    width?: number;
}

export function IMCCSearchHitFromJSON(json: any): IMCCSearchHit {
    return IMCCSearchHitFromJSONTyped(json, false);
}

export function IMCCSearchHitFromJSONTyped(json: any, ignoreDiscriminator: boolean): IMCCSearchHit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agency': !exists(json, 'agency') ? undefined : json['agency'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'assetType': !exists(json, 'assetType') ? undefined : json['assetType'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'copyright': !exists(json, 'copyright') ? undefined : json['copyright'],
        'downloadUrl': !exists(json, 'downloadUrl') ? undefined : json['downloadUrl'],
        'downloads': !exists(json, 'downloads') ? undefined : ((json['downloads'] as Array<any>).map(IMCCSearchHitDownloadItemFromJSON)),
        'dpi': !exists(json, 'dpi') ? undefined : json['dpi'],
        'editionYear': !exists(json, 'editionYear') ? undefined : json['editionYear'],
        'fileExtension': !exists(json, 'fileExtension') ? undefined : json['fileExtension'],
        'fiscalYear': !exists(json, 'fiscalYear') ? undefined : json['fiscalYear'],
        'hasAnimals': !exists(json, 'hasAnimals') ? undefined : json['hasAnimals'],
        'hasClippingPath': !exists(json, 'hasClippingPath') ? undefined : json['hasClippingPath'],
        'hasPeople': !exists(json, 'hasPeople') ? undefined : json['hasPeople'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'isReleased': !exists(json, 'isReleased') ? undefined : json['isReleased'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'orientation': !exists(json, 'orientation') ? undefined : json['orientation'],
        'previewUrl': !exists(json, 'previewUrl') ? undefined : json['previewUrl'],
        'producedFor': !exists(json, 'producedFor') ? undefined : json['producedFor'],
        'productDescription': !exists(json, 'productDescription') ? undefined : json['productDescription'],
        'productName': !exists(json, 'productName') ? undefined : json['productName'],
        'productType': !exists(json, 'productType') ? undefined : json['productType'],
        'productionMethod': !exists(json, 'productionMethod') ? undefined : json['productionMethod'],
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'releaseDateAt': !exists(json, 'releaseDateAt') ? undefined : json['releaseDateAt'],
        'relevanceScore': !exists(json, 'relevanceScore') ? undefined : json['relevanceScore'],
        'section': !exists(json, 'section') ? undefined : json['section'],
        'smallThumbnailUrl': !exists(json, 'smallThumbnailUrl') ? undefined : json['smallThumbnailUrl'],
        'thumbnailUrl': !exists(json, 'thumbnailUrl') ? undefined : json['thumbnailUrl'],
        'uploadedAt': !exists(json, 'uploadedAt') ? undefined : json['uploadedAt'],
        'width': !exists(json, 'width') ? undefined : json['width'],
    };
}

export function IMCCSearchHitToJSON(value?: IMCCSearchHit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agency': value.agency,
        'area': value.area,
        'assetType': value.assetType,
        'category': value.category,
        'copyright': value.copyright,
        'downloadUrl': value.downloadUrl,
        'downloads': value.downloads === undefined ? undefined : ((value.downloads as Array<any>).map(IMCCSearchHitDownloadItemToJSON)),
        'dpi': value.dpi,
        'editionYear': value.editionYear,
        'fileExtension': value.fileExtension,
        'fiscalYear': value.fiscalYear,
        'hasAnimals': value.hasAnimals,
        'hasClippingPath': value.hasClippingPath,
        'hasPeople': value.hasPeople,
        'height': value.height,
        'id': value.id,
        'isReleased': value.isReleased,
        'name': value.name,
        'orientation': value.orientation,
        'previewUrl': value.previewUrl,
        'producedFor': value.producedFor,
        'productDescription': value.productDescription,
        'productName': value.productName,
        'productType': value.productType,
        'productionMethod': value.productionMethod,
        'provider': value.provider,
        'releaseDateAt': value.releaseDateAt,
        'relevanceScore': value.relevanceScore,
        'section': value.section,
        'smallThumbnailUrl': value.smallThumbnailUrl,
        'thumbnailUrl': value.thumbnailUrl,
        'uploadedAt': value.uploadedAt,
        'width': value.width,
    };
}


