/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface CouldNotCreateFSJob
 */
export interface CouldNotCreateFSJob {
    /**
     * 
     * @type {boolean}
     * @memberof CouldNotCreateFSJob
     */
    isError: CouldNotCreateFSJobIsErrorEnum;
    /**
     * 
     * @type {string}
     * @memberof CouldNotCreateFSJob
     */
    type: CouldNotCreateFSJobTypeEnum;
}


/**
* @export
* @enum {boolean}
*/
export type CouldNotCreateFSJobIsErrorEnum = true;




/**
* @export
* @enum {string}
*/
export type CouldNotCreateFSJobTypeEnum = 'could-not-create-fs-job';





export function CouldNotCreateFSJobFromJSON(json: any): CouldNotCreateFSJob {
    return CouldNotCreateFSJobFromJSONTyped(json, false);
}

export function CouldNotCreateFSJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): CouldNotCreateFSJob {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isError': json['isError'],
        'type': json['type'],
    };
}

export function CouldNotCreateFSJobToJSON(value?: CouldNotCreateFSJob | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isError': value.isError,
        'type': value.type,
    };
}


