/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface JobInProgress
 */
export interface JobInProgress {
    /**
     * 
     * @type {string}
     * @memberof JobInProgress
     */
    type: JobInProgressTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof JobInProgress
     */
    timestamp: number;
    /**
     * 
     * @type {number}
     * @memberof JobInProgress
     */
    attempt: number;
}


/**
* @export
* @enum {string}
*/
export type JobInProgressTypeEnum = 'job-in-progress';





export function JobInProgressFromJSON(json: any): JobInProgress {
    return JobInProgressFromJSONTyped(json, false);
}

export function JobInProgressFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobInProgress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'timestamp': json['timestamp'],
        'attempt': json['attempt'],
    };
}

export function JobInProgressToJSON(value?: JobInProgress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'timestamp': value.timestamp,
        'attempt': value.attempt,
    };
}


