import { useLocation, useNavigate } from 'react-router-dom';
import { ExtSearchRequestBody } from '../components/Search/helpers';
import { SearchRequestBody } from '../generated-backend-api';
import { validateType } from '@ikea-ingka-dam/type-util';

export const useUrlSearchQuery = (): [
  () => SearchRequestBody | null,
  (q: SearchRequestBody) => void,
] => {
  const { search } = useLocation();
  const navigate = useNavigate();
  function setQuery(q: SearchRequestBody) {
    const str = encodeURIComponent(JSON.stringify(q));
    navigate({
      pathname: window.location.pathname,
      search: `?query=${str}`,
    })?.catch((e: Error) => {
      console.error('Error navigating', e);
      throw e;
    });
  }

  const getQuery = () => {
    const p = new URLSearchParams(search);

    const query = p.get('query');

    try {
      const data: unknown = JSON.parse(decodeURIComponent(String(query)));
      const v = validateType(ExtSearchRequestBody, data);

      if (v.isError) {
        return null;
      }

      return v.result;
    } catch {
      return null;
    }
  };

  return [getQuery, setQuery];
};

export default useUrlSearchQuery;
