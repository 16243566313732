/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface CouldNotFetchFromFSError
 */
export interface CouldNotFetchFromFSError {
    /**
     * 
     * @type {boolean}
     * @memberof CouldNotFetchFromFSError
     */
    isError: CouldNotFetchFromFSErrorIsErrorEnum;
    /**
     * 
     * @type {string}
     * @memberof CouldNotFetchFromFSError
     */
    type: CouldNotFetchFromFSErrorTypeEnum;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof CouldNotFetchFromFSError
     */
    error: { [key: string]: object; };
}


/**
* @export
* @enum {boolean}
*/
export type CouldNotFetchFromFSErrorIsErrorEnum = true;




/**
* @export
* @enum {string}
*/
export type CouldNotFetchFromFSErrorTypeEnum = 'could-not-fetch-from-fs';





export function CouldNotFetchFromFSErrorFromJSON(json: any): CouldNotFetchFromFSError {
    return CouldNotFetchFromFSErrorFromJSONTyped(json, false);
}

export function CouldNotFetchFromFSErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): CouldNotFetchFromFSError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isError': json['isError'],
        'type': json['type'],
        'error': json['error'],
    };
}

export function CouldNotFetchFromFSErrorToJSON(value?: CouldNotFetchFromFSError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isError': value.isError,
        'type': value.type,
        'error': value.error,
    };
}


