/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    BynderAssetUploadJob,
    BynderAssetUploadJobFromJSON,
    BynderAssetUploadJobFromJSONTyped,
    BynderAssetUploadJobToJSON,
} from './';

/**
 * 
 * @export
 * @interface BynderAssetUploadJobListResponse
 */
export interface BynderAssetUploadJobListResponse {
    /**
     * 
     * @type {boolean}
     * @memberof BynderAssetUploadJobListResponse
     */
    isError: BynderAssetUploadJobListResponseIsErrorEnum;
    /**
     * 
     * @type {string}
     * @memberof BynderAssetUploadJobListResponse
     */
    type: BynderAssetUploadJobListResponseTypeEnum;
    /**
     * 
     * @type {Array<BynderAssetUploadJob>}
     * @memberof BynderAssetUploadJobListResponse
     */
    result: Array<BynderAssetUploadJob>;
}


/**
* @export
* @enum {boolean}
*/
export type BynderAssetUploadJobListResponseIsErrorEnum = false;




/**
* @export
* @enum {string}
*/
export type BynderAssetUploadJobListResponseTypeEnum = 'bynder-asset-upload-job-list-response';





export function BynderAssetUploadJobListResponseFromJSON(json: any): BynderAssetUploadJobListResponse {
    return BynderAssetUploadJobListResponseFromJSONTyped(json, false);
}

export function BynderAssetUploadJobListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BynderAssetUploadJobListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isError': json['isError'],
        'type': json['type'],
        'result': ((json['result'] as Array<any>).map(BynderAssetUploadJobFromJSON)),
    };
}

export function BynderAssetUploadJobListResponseToJSON(value?: BynderAssetUploadJobListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isError': value.isError,
        'type': value.type,
        'result': ((value.result as Array<any>).map(BynderAssetUploadJobToJSON)),
    };
}


