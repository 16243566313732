import React, { useEffect } from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Loading, { LoadingBall } from '@ingka/loading';
import { SectionWrap } from './SectionWrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Delivery } from './Delivery';
import { Loadable } from './util/type';
import Select, { Option } from '@ingka/select';

import { CreatePackageBodyItem } from './generated-backend-api/models/CreatePackageBodyItem';
import { IMCCDeliveriesResult, IMCCDeliveryResult, SearchFSResult } from './generated-backend-api';
import { Section } from './components/section/section';
import Button from '@ingka/button';
import { UploadsListLoadable } from './app-state';
import { UploadJobItemWithThumbnail } from './util/common-types';

export interface IDelivery {
  name: string;
  id: string;
}

export type DeliveryMap = {
  [id: string]: Loadable<IMCCDeliveryResult, { id: string }> | undefined;
};

interface IDeliveriesProps {
  deliveries: Loadable<IMCCDeliveriesResult>;
  deliveryDataMap: DeliveryMap;
  deliveryFSAssets: Loadable<
    SearchFSResult,
    {
      deliveryId: string;
      phs: string[];
    }
  >;
  uploadJobs: UploadsListLoadable;

  loadDelivery: (id: string, skipCache: boolean) => void;
  loadMoreDeliveries: () => void;
  loadDeliveries: (skipCache: boolean) => void;
  downloadPackage: (downloadables: CreatePackageBodyItem[]) => void;
  loadDeliveryFSAssets: (names: string[], deliveryId: string, skipCache: boolean) => void;
  prepareForUpload: (jobs: UploadJobItemWithThumbnail[]) => void;
}

export const Deliveries: React.FC<IDeliveriesProps> = (props: IDeliveriesProps) => {
  const {
    deliveries,
    deliveryDataMap,
    loadDeliveries,
    loadMoreDeliveries,
    loadDelivery,
    downloadPackage,
    loadDeliveryFSAssets,
    deliveryFSAssets,
    uploadJobs,
    prepareForUpload,
  } = props;

  const params = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const selectedDeliveryId = params.id;
  const status = deliveries.status;

  useEffect(() => {
    if (selectedDeliveryId && params.id !== selectedDeliveryId) {
      navigate(`/deliveries/${selectedDeliveryId}`, { replace: true })?.catch((e: Error) => {
        console.error('Failed to navigate', e);
        throw e;
      });
    }
  }, [navigate, selectedDeliveryId, params.id]);

  useEffect(() => {
    if (status !== 'uninitialized') {
      return;
    }

    loadDeliveries(false);
  }, [loadDeliveries, status]);

  if (deliveries.status !== 'loaded') {
    return (
      <SectionWrap>
        <Loading text="Loading deliveries.">
          <LoadingBall />
        </Loading>
      </SectionWrap>
    );
  }

  if (deliveries.data.type !== 'deliveries-list-response') {
    return <SectionWrap>Could not load deliveries: {deliveries.data.type}</SectionWrap>;
  }

  let delivery = null;

  const selectedDeliveryItem = deliveries.data.result.items.find(
    (item) => item.id === selectedDeliveryId,
  );

  const selectedDelivery = selectedDeliveryId ? deliveryDataMap[selectedDeliveryId] : undefined;

  delivery = (
    <Delivery
      deliveryName={selectedDeliveryItem ? selectedDeliveryItem.name : 'unknown'}
      key={selectedDeliveryId}
      deliveryId={selectedDeliveryId}
      loadDelivery={loadDelivery}
      delivery={selectedDelivery}
      downloadPackage={downloadPackage}
      deliveryFSAssets={deliveryFSAssets}
      loadDeliveryFSAssets={loadDeliveryFSAssets}
      uploadJobs={uploadJobs}
      prepareForUpload={prepareForUpload}
    />
  );

  const handleChange = (event: React.FormEvent<HTMLSelectElement>) => {
    navigate(`/deliveries/${event.currentTarget.value}`)?.catch((e: Error) => {
      console.error('Failed to navigate', e);
      throw e;
    });
  };

  return (
    <SectionWrap>
      <Section size="large">
        <h1>Deliveries</h1>
      </Section>
      <Section size="large">
        <div
          css={css`
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            align-items: center;

            button {
              display: inline-block;
            }
          `}
        >
          <Select
            onChange={handleChange}
            id="deliveryDropDown"
            label="Select a delivery"
            value={selectedDeliveryId}
          >
            {deliveries.data.result.items.map((d) => (
              <Option key={d.id} value={d.id} name={d.name} />
            ))}
          </Select>

          <div>
            <Button
              text={'Refresh deliveries'}
              onClick={() => {
                props.loadDeliveries(true);
              }}
            />

            <Button
              text={`Load more deliveries (${deliveries.data.result.items.length})`}
              onClick={loadMoreDeliveries}
              type="emphasised"
              css={css`
                margin-left: 10px;
              `}
            />
          </div>
        </div>
      </Section>
      {delivery}
    </SectionWrap>
  );
};
