/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface CannotRetryNotMaxAttempts
 */
export interface CannotRetryNotMaxAttempts {
    /**
     * 
     * @type {boolean}
     * @memberof CannotRetryNotMaxAttempts
     */
    isError: CannotRetryNotMaxAttemptsIsErrorEnum;
    /**
     * 
     * @type {string}
     * @memberof CannotRetryNotMaxAttempts
     */
    type: CannotRetryNotMaxAttemptsTypeEnum;
}


/**
* @export
* @enum {boolean}
*/
export type CannotRetryNotMaxAttemptsIsErrorEnum = true;




/**
* @export
* @enum {string}
*/
export type CannotRetryNotMaxAttemptsTypeEnum = 'cannot-retry-not-max-attempts';





export function CannotRetryNotMaxAttemptsFromJSON(json: any): CannotRetryNotMaxAttempts {
    return CannotRetryNotMaxAttemptsFromJSONTyped(json, false);
}

export function CannotRetryNotMaxAttemptsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CannotRetryNotMaxAttempts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isError': json['isError'],
        'type': json['type'],
    };
}

export function CannotRetryNotMaxAttemptsToJSON(value?: CannotRetryNotMaxAttempts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isError': value.isError,
        'type': value.type,
    };
}


