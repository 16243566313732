/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    QueueBynderJobResult,
    QueueBynderJobResultFromJSON,
    QueueBynderJobResultFromJSONTyped,
    QueueBynderJobResultToJSON,
} from './';

/**
 * 
 * @export
 * @interface UploadQueued
 */
export interface UploadQueued {
    /**
     * 
     * @type {string}
     * @memberof UploadQueued
     */
    type: UploadQueuedTypeEnum;
    /**
     * 
     * @type {QueueBynderJobResult}
     * @memberof UploadQueued
     */
    result: QueueBynderJobResult;
}


/**
* @export
* @enum {string}
*/
export type UploadQueuedTypeEnum = 'job-queued';





export function UploadQueuedFromJSON(json: any): UploadQueued {
    return UploadQueuedFromJSONTyped(json, false);
}

export function UploadQueuedFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadQueued {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'result': QueueBynderJobResultFromJSON(json['result']),
    };
}

export function UploadQueuedToJSON(value?: UploadQueued | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'result': QueueBynderJobResultToJSON(value.result),
    };
}


