import React, { JSX } from 'react';
import { Loadable } from '../util/type';

import { WithLoadedLoadable } from './with-loaded-loadable';
import Loading, { LoadingBall } from '@ingka/loading';
import { Grid } from './grid';
import { VIImageCaptionsResult } from '../generated-backend-api';

interface CaptionsProps {
  captions: Loadable<VIImageCaptionsResult>;
}

export function Captions(props: CaptionsProps): JSX.Element {
  const { captions } = props;

  return (
    <WithLoadedLoadable
      loadable={captions}
      fallback={
        <Loading text="Loading captions.">
          <LoadingBall />
        </Loading>
      }
    >
      {(data) => {
        if (data.isError) {
          return <div>Could not load captions.</div>;
        }

        return (
          <Grid columns={1} rowGap={10} colGap={0}>
            <h3>Captions</h3>
            <>{JSON.stringify(data.result, undefined, 2)}</>
          </Grid>
        );
      }}
    </WithLoadedLoadable>
  );
}
