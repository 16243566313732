import React, { JSX, useState } from 'react';
import { Asset } from '../generated-backend-api';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { LoadingLinear } from '@ingka/loading';
import InlineMessage from '@ingka/inline-message';

import { FindSimilarResponse } from '../util/find-similar';
import { Loadable } from '../util/type';
import { Section } from './section/section';
import { UploadJobItemWithThumbnail } from '../util/common-types';

interface UploadSimilarityResultsProps {
  findSimilarResults: Loadable<
    FindSimilarResponse[],
    { progress: number; checkedUploads: UploadJobItemWithThumbnail[] }
  >;
}

export const UploadSimilarityResults = (
  props: UploadSimilarityResultsProps,
): JSX.Element | null => {
  const [showAllSimilar, setShowAllSimilar] = React.useState(false);

  const { findSimilarResults } = props;

  if (findSimilarResults.status === 'uninitialized') {
    return null;
  }

  if (findSimilarResults.status === 'loading') {
    return (
      <div>
        <LoadingLinear
          loadingLabel="Similarity check progress"
          loadingValue={findSimilarResults.progress}
          loadingMax={1}
        />
      </div>
    );
  }

  const uploadJobs = findSimilarResults.checkedUploads;

  const similarWarnings = [];
  const similarNoWarning = [];

  for (let i = 0; i < uploadJobs.length; i++) {
    const uploadJob = uploadJobs[i];
    const similar = findSimilarResults.data[i];

    if (!similar) {
      similarWarnings.push(
        <SingleResult key={uploadJob.url} similarityResult={similar} uploadItem={uploadJob} />,
      );
      continue;
    }

    const distance = Math.max(...similar.similar.map((s) => s.distance));

    if (distance > 0.95) {
      similarWarnings.push(
        <SingleResult key={uploadJob.url} similarityResult={similar} uploadItem={uploadJob} />,
      );
      continue;
    }

    similarNoWarning.push(
      <SingleResult key={uploadJob.url} similarityResult={similar} uploadItem={uploadJob} />,
    );
  }

  return (
    <div>
      <Section>
        <p>
          The similarity measure (score) is a value in the range 0-1. A value of 1 indicates maximum
          similarity. Keep in mind that the similarity measure is approximate and even a measure of
          1 could have small differences.
        </p>
      </Section>
      {similarWarnings.length > 0 ? (
        <Section>
          <InlineMessage
            variant={'cautionary'}
            body={
              <div
                css={css`
                  margin-top: 20px;
                `}
              >
                {similarWarnings}
              </div>
            }
            title={'Similar assets may exist in DAM'}
          />
        </Section>
      ) : (
        <Section>No similar assets found in DAM.</Section>
      )}
      <Section>
        <a
          onClick={(e) => {
            e.preventDefault();
            setShowAllSimilar(!showAllSimilar);
          }}
          css={css`
            cursor: pointer;
          `}
        >
          {showAllSimilar ? 'Hide' : 'Show'} similarity results for differing images (
          {similarNoWarning.length}).
        </a>
      </Section>
      {showAllSimilar && <div>{similarNoWarning}</div>}
    </div>
  );
};

function SingleResult({
  similarityResult,
  uploadItem,
}: {
  similarityResult: FindSimilarResponse;
  uploadItem: UploadJobItemWithThumbnail;
}) {
  const [showOtherSimilar, setShowOtherSimilar] = useState(false);

  if (!similarityResult) {
    return <div>No similarity result for {uploadItem.name}</div>;
  }

  let closest: { asset: Asset; distance: number } | undefined;

  const similar = similarityResult.similar.map((s) => {
    const asset = similarityResult.searchResult.assets.find((q) => q.id === s.id);

    if (!asset) {
      return <div key={`invalid_${s.id}`}>Could not find asset with id {s.id}</div>;
    }

    if (!closest || closest.distance < s.distance) {
      closest = { asset, distance: s.distance };
    }

    return (
      <div key={s.id}>
        <img
          src={asset.thumbnails.webimage}
          alt={asset.name}
          css={css`
            width: 200px;
            height: 200px;
            object-fit: contain;
            padding: 5px;
            margin-right: 5px;
          `}
        />
        <div>Name: {asset.name}</div>
        <div>Score: {s.distance}</div>
      </div>
    );
  });

  return (
    <div>
      <Section>
        <div style={{ display: 'flex' }}>
          <div>
            <div>
              Name: <b>{uploadItem.name}</b>
            </div>
            <div>&nbsp;</div>
            <img
              src={uploadItem.url}
              alt={uploadItem.name}
              css={css`
                width: 400px;
                height: 400px;
                object-fit: contain;
              `}
            />
          </div>
          <div>
            <div>Name: {closest?.asset.name}</div>
            <div>Score: {closest?.distance}</div>
            <img
              src={closest?.asset.thumbnails.webimage}
              alt={closest?.asset.name}
              css={css`
                width: 400px;
                height: 400px;
                object-fit: contain;
              `}
            />
          </div>
        </div>
      </Section>
      <Section size="large">
        <a
          onClick={(e) => {
            e.preventDefault();
            setShowOtherSimilar(!showOtherSimilar);
          }}
          css={css`
            cursor: pointer;
          `}
        >
          {showOtherSimilar ? 'Hide' : 'Show'} other similarity results for {uploadItem.name}.
        </a>

        {showOtherSimilar && <div style={{ display: 'flex' }}>{similar}</div>}
      </Section>
    </div>
  );
}
