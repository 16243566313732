/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// oneof
import {
    JobAddedToBoard,
    JobCancelled,
    JobCancelledDueToExistingAsset,
    JobCompleted,
    JobFailed,
    JobInProgress,
    JobQueued,
    JobRetry,
    JobSaveInBynderCompleted,
    JobSavingInBynder,
    JobUpdatedAsset,
    JobUploadingToAws,
    JobAddedToBoardFromJSONTyped,
    JobAddedToBoardToJSON,
    JobCancelledFromJSONTyped,
    JobCancelledToJSON,
    JobCancelledDueToExistingAssetFromJSONTyped,
    JobCancelledDueToExistingAssetToJSON,
    JobCompletedFromJSONTyped,
    JobCompletedToJSON,
    JobFailedFromJSONTyped,
    JobFailedToJSON,
    JobInProgressFromJSONTyped,
    JobInProgressToJSON,
    JobQueuedFromJSONTyped,
    JobQueuedToJSON,
    JobRetryFromJSONTyped,
    JobRetryToJSON,
    JobSaveInBynderCompletedFromJSONTyped,
    JobSaveInBynderCompletedToJSON,
    JobSavingInBynderFromJSONTyped,
    JobSavingInBynderToJSON,
    JobUpdatedAssetFromJSONTyped,
    JobUpdatedAssetToJSON,
    JobUploadingToAwsFromJSONTyped,
    JobUploadingToAwsToJSON,
} from './';

/**
 * oneof interface
 * @type BynderAssetEditJobMessages
 * 
 * @export
 */
export type BynderAssetEditJobMessages = JobCancelledDueToExistingAsset | JobAddedToBoard | JobCancelled | JobCompleted | JobFailed | JobInProgress | JobQueued | JobRetry | JobSaveInBynderCompleted | JobSavingInBynder | JobUpdatedAsset | JobUploadingToAws;


export function BynderAssetEditJobMessagesFromJSON(json: any): BynderAssetEditJobMessages {
    return BynderAssetEditJobMessagesFromJSONTyped(json, false);
}

export function BynderAssetEditJobMessagesFromJSONTyped(json: any, ignoreDiscriminator: boolean): BynderAssetEditJobMessages {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'asset-already-exists-in-bynder':
            return {...JobCancelledDueToExistingAssetFromJSONTyped(json, true), type: 'asset-already-exists-in-bynder'};
        case 'job-added-to-board':
            return {...JobAddedToBoardFromJSONTyped(json, true), type: 'job-added-to-board'};
        case 'job-cancelled':
            return {...JobCancelledFromJSONTyped(json, true), type: 'job-cancelled'};
        case 'job-completed':
            return {...JobCompletedFromJSONTyped(json, true), type: 'job-completed'};
        case 'job-failed':
            return {...JobFailedFromJSONTyped(json, true), type: 'job-failed'};
        case 'job-in-progress':
            return {...JobInProgressFromJSONTyped(json, true), type: 'job-in-progress'};
        case 'job-queued':
            return {...JobQueuedFromJSONTyped(json, true), type: 'job-queued'};
        case 'job-retry':
            return {...JobRetryFromJSONTyped(json, true), type: 'job-retry'};
        case 'job-save-in-bynder-completed':
            return {...JobSaveInBynderCompletedFromJSONTyped(json, true), type: 'job-save-in-bynder-completed'};
        case 'job-saving-in-bynder':
            return {...JobSavingInBynderFromJSONTyped(json, true), type: 'job-saving-in-bynder'};
        case 'job-updated-asset':
            return {...JobUpdatedAssetFromJSONTyped(json, true), type: 'job-updated-asset'};
        case 'job-uploading-to-aws':
            return {...JobUploadingToAwsFromJSONTyped(json, true), type: 'job-uploading-to-aws'};
        default:
            throw new Error(`No variant of BynderAssetEditJobMessages exists with 'type=${json['type']}'`);
    }
}

export function BynderAssetEditJobMessagesToJSON(value?: BynderAssetEditJobMessages | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'asset-already-exists-in-bynder':
            return JobCancelledDueToExistingAssetToJSON(value);
        case 'job-added-to-board':
            return JobAddedToBoardToJSON(value);
        case 'job-cancelled':
            return JobCancelledToJSON(value);
        case 'job-completed':
            return JobCompletedToJSON(value);
        case 'job-failed':
            return JobFailedToJSON(value);
        case 'job-in-progress':
            return JobInProgressToJSON(value);
        case 'job-queued':
            return JobQueuedToJSON(value);
        case 'job-retry':
            return JobRetryToJSON(value);
        case 'job-save-in-bynder-completed':
            return JobSaveInBynderCompletedToJSON(value);
        case 'job-saving-in-bynder':
            return JobSavingInBynderToJSON(value);
        case 'job-updated-asset':
            return JobUpdatedAssetToJSON(value);
        case 'job-uploading-to-aws':
            return JobUploadingToAwsToJSON(value);
        default:
            throw new Error(`No variant of BynderAssetEditJobMessages exists with 'type=${value['type']}'`);
    }
}

