/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    GetFeaturesResults,
    GetFeaturesResultsFromJSON,
    GetFeaturesResultsFromJSONTyped,
    GetFeaturesResultsToJSON,
} from './';

/**
 * 
 * @export
 * @interface VIImageFeaturesResponse
 */
export interface VIImageFeaturesResponse {
    /**
     * 
     * @type {boolean}
     * @memberof VIImageFeaturesResponse
     */
    isError: VIImageFeaturesResponseIsErrorEnum;
    /**
     * 
     * @type {string}
     * @memberof VIImageFeaturesResponse
     */
    type: VIImageFeaturesResponseTypeEnum;
    /**
     * 
     * @type {GetFeaturesResults}
     * @memberof VIImageFeaturesResponse
     */
    result: GetFeaturesResults;
}


/**
* @export
* @enum {boolean}
*/
export type VIImageFeaturesResponseIsErrorEnum = false;




/**
* @export
* @enum {string}
*/
export type VIImageFeaturesResponseTypeEnum = 'vi-image-features';





export function VIImageFeaturesResponseFromJSON(json: any): VIImageFeaturesResponse {
    return VIImageFeaturesResponseFromJSONTyped(json, false);
}

export function VIImageFeaturesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VIImageFeaturesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isError': json['isError'],
        'type': json['type'],
        'result': GetFeaturesResultsFromJSON(json['result']),
    };
}

export function VIImageFeaturesResponseToJSON(value?: VIImageFeaturesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isError': value.isError,
        'type': value.type,
        'result': GetFeaturesResultsToJSON(value.result),
    };
}


