/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    Asset,
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
    ExtApiSearch2ResponseSearchAfter,
    ExtApiSearch2ResponseSearchAfterFromJSON,
    ExtApiSearch2ResponseSearchAfterFromJSONTyped,
    ExtApiSearch2ResponseSearchAfterToJSON,
    ExtApiSearch2ResponseTotal,
    ExtApiSearch2ResponseTotalFromJSON,
    ExtApiSearch2ResponseTotalFromJSONTyped,
    ExtApiSearch2ResponseTotalToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExtApiSearch2Response
 */
export interface ExtApiSearch2Response {
    /**
     * 
     * @type {Array<Asset>}
     * @memberof ExtApiSearch2Response
     */
    assets: Array<Asset>;
    /**
     * 
     * @type {ExtApiSearch2ResponseTotal}
     * @memberof ExtApiSearch2Response
     */
    total: ExtApiSearch2ResponseTotal;
    /**
     * 
     * @type {Array<ExtApiSearch2ResponseSearchAfter>}
     * @memberof ExtApiSearch2Response
     */
    searchAfter?: Array<ExtApiSearch2ResponseSearchAfter>;
}

export function ExtApiSearch2ResponseFromJSON(json: any): ExtApiSearch2Response {
    return ExtApiSearch2ResponseFromJSONTyped(json, false);
}

export function ExtApiSearch2ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtApiSearch2Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assets': ((json['assets'] as Array<any>).map(AssetFromJSON)),
        'total': ExtApiSearch2ResponseTotalFromJSON(json['total']),
        'searchAfter': !exists(json, 'searchAfter') ? undefined : ((json['searchAfter'] as Array<any>).map(ExtApiSearch2ResponseSearchAfterFromJSON)),
    };
}

export function ExtApiSearch2ResponseToJSON(value?: ExtApiSearch2Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assets': ((value.assets as Array<any>).map(AssetToJSON)),
        'total': ExtApiSearch2ResponseTotalToJSON(value.total),
        'searchAfter': value.searchAfter === undefined ? undefined : ((value.searchAfter as Array<any>).map(ExtApiSearch2ResponseSearchAfterToJSON)),
    };
}


