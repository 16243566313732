/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// oneof
import {
    CouldNotPerformRequest,
    IDAMAssetResponse,
    IDAMImageNotFound,
    RequestAbortedError,
    UnexpectedResponseError,
    ValidationError,
    CouldNotPerformRequestFromJSONTyped,
    CouldNotPerformRequestToJSON,
    IDAMAssetResponseFromJSONTyped,
    IDAMAssetResponseToJSON,
    IDAMImageNotFoundFromJSONTyped,
    IDAMImageNotFoundToJSON,
    RequestAbortedErrorFromJSONTyped,
    RequestAbortedErrorToJSON,
    UnexpectedResponseErrorFromJSONTyped,
    UnexpectedResponseErrorToJSON,
    ValidationErrorFromJSONTyped,
    ValidationErrorToJSON,
} from './';

/**
 * oneof interface
 * @type IDAMAsset
 * 
 * @export
 */
export type IDAMAsset = CouldNotPerformRequest | ValidationError | IDAMAssetResponse | IDAMImageNotFound | RequestAbortedError | UnexpectedResponseError;


export function IDAMAssetFromJSON(json: any): IDAMAsset {
    return IDAMAssetFromJSONTyped(json, false);
}

export function IDAMAssetFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDAMAsset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'could-not-perform-request':
            return {...CouldNotPerformRequestFromJSONTyped(json, true), type: 'could-not-perform-request'};
        case 'failed-to-validate':
            return {...ValidationErrorFromJSONTyped(json, true), type: 'failed-to-validate'};
        case 'idam-asset':
            return {...IDAMAssetResponseFromJSONTyped(json, true), type: 'idam-asset'};
        case 'idam-image-not-found':
            return {...IDAMImageNotFoundFromJSONTyped(json, true), type: 'idam-image-not-found'};
        case 'request-aborted':
            return {...RequestAbortedErrorFromJSONTyped(json, true), type: 'request-aborted'};
        case 'unexpected-response':
            return {...UnexpectedResponseErrorFromJSONTyped(json, true), type: 'unexpected-response'};
        default:
            throw new Error(`No variant of IDAMAsset exists with 'type=${json['type']}'`);
    }
}

export function IDAMAssetToJSON(value?: IDAMAsset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'could-not-perform-request':
            return CouldNotPerformRequestToJSON(value);
        case 'failed-to-validate':
            return ValidationErrorToJSON(value);
        case 'idam-asset':
            return IDAMAssetResponseToJSON(value);
        case 'idam-image-not-found':
            return IDAMImageNotFoundToJSON(value);
        case 'request-aborted':
            return RequestAbortedErrorToJSON(value);
        case 'unexpected-response':
            return UnexpectedResponseErrorToJSON(value);
        default:
            throw new Error(`No variant of IDAMAsset exists with 'type=${value['type']}'`);
    }
}

