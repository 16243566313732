/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    SearchSimilarResult,
    SearchSimilarResultFromJSON,
    SearchSimilarResultFromJSONTyped,
    SearchSimilarResultToJSON,
} from './';

/**
 * 
 * @export
 * @interface ElasticSearchSimilarResponse
 */
export interface ElasticSearchSimilarResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ElasticSearchSimilarResponse
     */
    isError: ElasticSearchSimilarResponseIsErrorEnum;
    /**
     * 
     * @type {string}
     * @memberof ElasticSearchSimilarResponse
     */
    type: ElasticSearchSimilarResponseTypeEnum;
    /**
     * 
     * @type {SearchSimilarResult}
     * @memberof ElasticSearchSimilarResponse
     */
    result: SearchSimilarResult;
}


/**
* @export
* @enum {boolean}
*/
export type ElasticSearchSimilarResponseIsErrorEnum = false;




/**
* @export
* @enum {string}
*/
export type ElasticSearchSimilarResponseTypeEnum = 'elastic-search-similar-response';





export function ElasticSearchSimilarResponseFromJSON(json: any): ElasticSearchSimilarResponse {
    return ElasticSearchSimilarResponseFromJSONTyped(json, false);
}

export function ElasticSearchSimilarResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ElasticSearchSimilarResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isError': json['isError'],
        'type': json['type'],
        'result': SearchSimilarResultFromJSON(json['result']),
    };
}

export function ElasticSearchSimilarResponseToJSON(value?: ElasticSearchSimilarResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isError': value.isError,
        'type': value.type,
        'result': SearchSimilarResultToJSON(value.result),
    };
}


