import React from 'react';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Button from '@ingka/button';
import RemoveIcon from '@ingka/ssr-icon/paths/cross';
import { DirectionalSort } from './Search/helpers';
import { SearchSortPicker } from './search-sort-picker';
import { Stack } from './stack';

export const SearchSortPickerList = ({
  sorts,
  allFields,
  onChange,
  prioritizedFields,
}: {
  sorts: DirectionalSort[];
  allFields: string[];
  prioritizedFields?: string[];
  onChange: (sorts: DirectionalSort[]) => void;
}) => {
  const usedFields = sorts.map((s) => Object.keys(s)[0]);

  const renderedSorts = sorts.map((sort, index) => {
    return Object.keys(sort).map((key) => {
      return (
        <Stack key={key}>
          <SearchSortPicker
            field={key}
            order={sort[key]}
            allFields={allFields}
            prioritizedFields={prioritizedFields}
            isFieldEnabled={(field) => {
              return !usedFields.includes(field) || field === key;
            }}
            onChange={(field, order) => {
              const newSorts = [...sorts];
              newSorts[index] = { [field]: order };
              onChange(newSorts);
            }}
          />
          <Button
            iconOnly={true}
            ssrIcon={RemoveIcon}
            size="small"
            css={css`
              align-self: flex-end;
              margin-bottom: 4px;
            `}
            onClick={() => {
              const newSorts = [...sorts];
              newSorts.splice(index, 1);
              onChange(newSorts);
            }}
          />
        </Stack>
      );
    });
  });

  return (
    <>
      {renderedSorts}
      <Button
        text={'Add sort'}
        size="small"
        onClick={() => {
          // find the index of the first field that is not used
          const index = allFields.findIndex((f) => !usedFields.includes(f));

          // if there are no more fields to use, just pick the first one
          const indexToUse = index === -1 ? 0 : index;

          onChange([...sorts, { [allFields[indexToUse]]: 'asc' }]);
        }}
      />
    </>
  );
};
