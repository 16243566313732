/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    ExtRequestParams,
    ExtRequestParamsFromJSON,
    ExtRequestParamsFromJSONTyped,
    ExtRequestParamsToJSON,
} from './';

/**
 * 
 * @export
 * @interface ExtApiSearchRequestBody
 */
export interface ExtApiSearchRequestBody {
    /**
     * 
     * @type {ExtRequestParams}
     * @memberof ExtApiSearchRequestBody
     */
    options: ExtRequestParams;
}

export function ExtApiSearchRequestBodyFromJSON(json: any): ExtApiSearchRequestBody {
    return ExtApiSearchRequestBodyFromJSONTyped(json, false);
}

export function ExtApiSearchRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtApiSearchRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'options': ExtRequestParamsFromJSON(json['options']),
    };
}

export function ExtApiSearchRequestBodyToJSON(value?: ExtApiSearchRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'options': ExtRequestParamsToJSON(value.options),
    };
}


