/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface JobAddedToBoard
 */
export interface JobAddedToBoard {
    /**
     * 
     * @type {string}
     * @memberof JobAddedToBoard
     */
    type: JobAddedToBoardTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JobAddedToBoard
     */
    board: string;
    /**
     * 
     * @type {number}
     * @memberof JobAddedToBoard
     */
    attempt: number;
    /**
     * 
     * @type {number}
     * @memberof JobAddedToBoard
     */
    timestamp: number;
}


/**
* @export
* @enum {string}
*/
export type JobAddedToBoardTypeEnum = 'job-added-to-board';





export function JobAddedToBoardFromJSON(json: any): JobAddedToBoard {
    return JobAddedToBoardFromJSONTyped(json, false);
}

export function JobAddedToBoardFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobAddedToBoard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'board': json['board'],
        'attempt': json['attempt'],
        'timestamp': json['timestamp'],
    };
}

export function JobAddedToBoardToJSON(value?: JobAddedToBoard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'board': value.board,
        'attempt': value.attempt,
        'timestamp': value.timestamp,
    };
}


