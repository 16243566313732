/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface NotFoundInFS
 */
export interface NotFoundInFS {
    /**
     * 
     * @type {boolean}
     * @memberof NotFoundInFS
     */
    isError: NotFoundInFSIsErrorEnum;
    /**
     * 
     * @type {string}
     * @memberof NotFoundInFS
     */
    type: NotFoundInFSTypeEnum;
}


/**
* @export
* @enum {boolean}
*/
export type NotFoundInFSIsErrorEnum = true;




/**
* @export
* @enum {string}
*/
export type NotFoundInFSTypeEnum = 'not-found-in-fs';





export function NotFoundInFSFromJSON(json: any): NotFoundInFS {
    return NotFoundInFSFromJSONTyped(json, false);
}

export function NotFoundInFSFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotFoundInFS {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isError': json['isError'],
        'type': json['type'],
    };
}

export function NotFoundInFSToJSON(value?: NotFoundInFS | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isError': value.isError,
        'type': value.type,
    };
}


