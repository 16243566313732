import React, { JSX, useEffect, useState } from 'react';
import {
  BynderMetaResult,
  CreateBynderAssetJobResult,
  MetaProperty,
  SearchFSResult,
  UploadJobItem,
} from '../generated-backend-api';

import { Loadable } from '../util/type';
import Button from '@ingka/button';
import Tooltip from '@ingka/tooltip';
import { Section } from '../components/section/section';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Checkbox from '@ingka/checkbox';
import Accordion, { AccordionItem } from '@ingka/accordion';

import { getIDBTemplates, setIDBTemplates, TemplateListType, TemplateType } from '../templates';
import { CreateTemplate } from './create-template';
import { TemplateSelect } from './template-select';
import { DATE_FORMAT, MetaInput } from '../components/meta-input';
import { UploadAssetExistsWarning } from '../components/upload-asset-exists-warning';
import { useBynder } from '../util/bynderReactUtils';
import { BynderLoginButton } from '../components/bynder-login-button';
import { useToasts } from '../util/toastProvider';

import { SelectBynderBoard } from './select-bynder-board';
import { IBynderCollection } from '../util/bynder-api';
import { BynderBoardInfoShort } from './bynder-board-info-short';
import { extDayjs as dayjs } from '../util/dayjs';
import {
  bynderDescriptionProp,
  bynderLimitedDateProp,
  bynderLimitedProp,
  bynderNameProp,
  bynderTagsProp,
  defaultEditableBynderProps,
} from './bynder-default-meta-fields';
import { IDAMAssetResultWrapper, UploadsListLoadable } from '../app-state';
import { FindSimilarPayload, FindSimilarResponse } from '../util/find-similar';
import { UploadSimilarityResults } from './upload-form-similarity-result';
import { UploadJobItemWithThumbnail } from '../util/common-types';
import UploadRow from './upload-row';
import { translateIDAMFieldsToBynder } from '../util/idam-bynder-meta-translation';
import { getUniqueKey } from '../util/generate-unique-name-job-item';

/// STYLING

const cellStyle = css`
  width: 300px;
  display: inline-block;
  margin-right: 10px;
  flex-shrink: 0;
`;

const rowStyle = css`
  margin-bottom: 10px;
  display: flex;
  position: relative;
`;

const headerRowStyle = css([
  rowStyle,
  css`
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 15px;
    margin-bottom: 20px;
    display: inline-flex;
  `,
]);

// END STYLING

type InvalidList = { name: string; property: MetaProperty }[];

function getInvalidRequiredFields(
  data: { [key: string]: MetaBag | undefined },
  requiredProps: MetaProperty[],
  disabledUploadItems: Set<string>,
): InvalidList {
  const invalid: InvalidList = [];

  for (const p of requiredProps) {
    for (const [name, v] of Object.entries(data)) {
      if (disabledUploadItems.has(name)) {
        continue;
      }

      if (!v) {
        invalid.push({ name, property: p });
        continue;
      }

      const value = v[p.id];

      if (!value || value.length === 0) {
        invalid.push({ name, property: p });
      }
    }
  }

  return invalid;
}

const removeColumnFromData = (id: string, data: { [key: string]: MetaBag | undefined }) => {
  const out: { [key: string]: MetaBag } = {};

  for (const name of Object.keys(data)) {
    const d = data[name];

    if (!d) {
      continue;
    }

    const q = { ...d };

    if (q[id]) {
      delete q[id];
    }

    out[name] = q;
  }

  return out;
};

const changeAll = (
  value: string[],
  property: MetaProperty,
  data: { [key: string]: MetaBag | undefined },
): typeof data => {
  const out = { ...data };

  for (const [k, v] of Object.entries(data)) {
    if (value.length === 0) {
      const update = { ...v };
      delete update[property.id];

      out[k] = update;
    } else {
      out[k] = {
        ...v,
        [property.id]: value,
      };
    }
  }

  return out;
};

function initData(assetsToUpload: UploadJobItem[]): { [key: string]: MetaBag | undefined } {
  const d: { [key: string]: MetaBag | undefined } = {};

  for (const r of assetsToUpload) {
    d[getUniqueKey({ type: 'upload-job-item', data: r })] = {};
  }

  return d;
}

interface IUploadProps {
  bynderMeta: Loadable<BynderMetaResult>;
  loadBynderMeta: () => void;
  assetsToUpload: UploadJobItemWithThumbnail[];
  uploadToBynder: (items: UploadJobItem[]) => Promise<CreateBynderAssetJobResult | false>;
  bynderBaseUrl: string;
  loadUploadsList: () => Promise<unknown> | undefined;
  uploadsList: UploadsListLoadable;
  searchFS: (phs: string[]) => void;
  searchFSResult: Loadable<SearchFSResult, { phs: string[] }>;
  searchIDAMImage: (phs: string[]) => void;
  searchIDAMImageResult: Loadable<IDAMAssetResultWrapper, { phs: string[] }>;
  setUploadJobItems: (jobs: UploadJobItem[]) => void;
  findSimilarImages: (
    payloads: FindSimilarPayload[],
    onProgress?: (percentage: number) => void,
  ) => Promise<FindSimilarResponse[]>;
}

export type MetaBag = { [key: string]: string[] | undefined };

// arrays viewed as set are the same
function isSubset<T>(superset: T[], sub: T[]) {
  const as = new Set(superset);
  const bs = new Set(sub);

  for (const b of bs) {
    if (!as.has(b)) {
      return false;
    }
  }
  return true;
}

// arrays have the same contents, disregarding order
function haveSameContents<T>(a: T[], b: T[]): boolean {
  if (a.length !== b.length) {
    return false;
  }

  return isSubset(a, b);
}

const validPHRegex = new RegExp(/^[0-9a-zA-Z]+$/);

export function UploadForm(props: IUploadProps): JSX.Element {
  const [data, setData] = useState<{ [key: string]: MetaBag | undefined }>(() => {
    return initData(props.assetsToUpload);
  });
  const [isUploading, setIsUploading] = useState(false);
  const [columns, setColumns] = useState<Set<string>>(new Set());
  const [templates, setTemplates] = useState<Loadable<TemplateListType>>({
    status: 'uninitialized',
  });
  const [createTemplate, setCreateTemplate] = useState<false | Omit<TemplateType, 'name'>>(false);
  const [board, setBoard] = useState<IBynderCollection | false>(false);
  const [findSimilarResults, setFindSimilarResults] = useState<
    Loadable<
      FindSimilarResponse[],
      { progress: number; checkedUploads: UploadJobItemWithThumbnail[] }
    >
  >({
    status: 'uninitialized',
  });

  const [showImages, setShowImages] = useState(() => {
    return localStorage.getItem('upload_showImages') === 'true';
  });

  const [disabledUploadItems, setDisabledUploadItems] = useState<Set<string>>(new Set());

  // is the initial load of values complete
  const [initialLoadDefaultValuesSet, setInitialLoadDefaultValuesSet] = useState<boolean>(false);

  const bynderWrap = useBynder();
  const toastApi = useToasts();

  const {
    bynderMeta,
    loadBynderMeta,
    bynderBaseUrl,
    loadUploadsList,
    uploadsList,
    searchFS,
    searchFSResult,
    assetsToUpload,
    searchIDAMImage,
    searchIDAMImageResult,
  } = props;

  useEffect(() => {
    if (bynderMeta.status !== 'uninitialized') {
      return;
    }

    loadBynderMeta();
  }, [bynderMeta.status, loadBynderMeta]);

  useEffect(() => {
    if (templates.status === 'uninitialized') {
      setTemplates({ status: 'loading' });
      void getIDBTemplates().then((list) => {
        setTemplates({ status: 'loaded', data: list });
      });
    }
  }, [templates.status]);

  const assetNames = assetsToUpload.map((a) => {
    const d = data[a.name];

    if (!d) {
      return a.name;
    }

    const nameProp = d[bynderNameProp.id];

    if (!nameProp || nameProp.length !== 1 || nameProp[0].length === 0) {
      return a.name;
    }

    return nameProp[0];
  });

  useEffect(() => {
    if (
      searchFSResult.status === 'uninitialized' ||
      !haveSameContents(searchFSResult.phs, assetNames)
    ) {
      searchFS(assetNames);
    }
  }, [searchFSResult, searchFS, assetNames]);

  useEffect(() => {
    const searchableAssets = assetsToUpload.map((a) => a.name).filter((n) => validPHRegex.exec(n));

    if (
      searchIDAMImageResult.status === 'uninitialized' ||
      (searchIDAMImageResult.status === 'loaded' &&
        !isSubset(searchIDAMImageResult.phs, searchableAssets))
    ) {
      searchIDAMImage(searchableAssets);
    }
  });

  const loadIDAMValues = () => {
    if (searchIDAMImageResult.status !== 'loaded') {
      console.warn('Cannot load IDAM values, searchIDAMImageResult not loaded');
      return;
    }

    if (searchIDAMImageResult.data.type !== 'idam-asset-result') {
      console.warn('Cannot load IDAM values, searchIDAMImageResult not loaded');
      return;
    }

    if (bynderMeta.status !== 'loaded') {
      console.warn('Cannot load IDAM values, bynderMeta not loaded');
      return;
    }

    if (bynderMeta.data.type !== 'bynder-meta-response') {
      console.warn('Cannot load IDAM values, bynderMeta not loaded');
      return;
    }

    const out = translateIDAMFieldsToBynder(searchIDAMImageResult.data.result, bynderMeta.data);

    const newData = { ...data };

    for (const [k, v] of Object.entries(out.data)) {
      if (!newData[k]) {
        newData[k] = { ...v };
      } else {
        newData[k] = { ...newData[k], ...v };
      }
    }

    setData(newData);
    setColumns(new Set([...out.columns, ...columns]));
  };

  useEffect(() => {
    const searchableAssets = assetsToUpload.map((a) => a.name).filter((n) => validPHRegex.exec(n));

    if (
      searchIDAMImageResult.status === 'loaded' &&
      isSubset(searchIDAMImageResult.phs, searchableAssets) &&
      !initialLoadDefaultValuesSet &&
      bynderMeta.status === 'loaded'
    ) {
      // set default values

      setInitialLoadDefaultValuesSet(true);
    }
  }, [initialLoadDefaultValuesSet, assetsToUpload, searchIDAMImageResult, bynderMeta.status]);

  if (props.bynderMeta.status === 'loading') {
    return <div>Bynder meta loading...</div>;
  }

  if (props.bynderMeta.status === 'uninitialized') {
    return <div>Meta not loaded.</div>;
  }

  if (props.bynderMeta.data.type !== 'bynder-meta-response') {
    return <div>Bad bynder meta response: {props.bynderMeta.data.type}</div>;
  }

  if (!bynderWrap.isLoggedIn) {
    return <BynderLoginButton />;
  }

  const m = props.bynderMeta.data.result;
  const propMap: Map<string, MetaProperty> = new Map<string, MetaProperty>();

  const requiredProps: MetaProperty[] = [];

  const otherProps: MetaProperty[] = [];

  for (const v of Object.values(m)) {
    propMap.set(v.id, v);

    if (v.isRequired === 1) {
      requiredProps.push(v);
    } else {
      otherProps.push(v);
    }
  }

  otherProps.push(...defaultEditableBynderProps);

  requiredProps.sort((a, b) => a.zindex - b.zindex);
  otherProps.sort((a, b) => a.zindex - b.zindex);

  const onChangeValue = (value: string[], property: MetaProperty, job: UploadJobItem) => {
    if (value.length === 0) {
      const update = {
        ...data[getUniqueKey({ type: 'upload-job-item', data: job })],
      };

      delete update[property.id];

      setData({
        ...data,
        [getUniqueKey({ type: 'upload-job-item', data: job })]: update,
      });

      return;
    }

    setData({
      ...data,
      [getUniqueKey({ type: 'upload-job-item', data: job })]: {
        ...data[getUniqueKey({ type: 'upload-job-item', data: job })],
        [property.id]: value,
      },
    });
  };

  const onChangeAllValues = (value: string[], property: MetaProperty) => {
    const out = changeAll(value, property, data);
    setData(out);
  };

  const handleUpload = async () => {
    const list = props.assetsToUpload
      .filter(
        (asset) => !disabledUploadItems.has(getUniqueKey({ type: 'upload-job-item', data: asset })),
      )
      .map((asset) => {
        const meta = data[getUniqueKey({ type: 'upload-job-item', data: asset })];

        if (!meta) {
          return asset;
        }

        const bynderMeta: { [k: string]: string } = {};

        const defaultVals: {
          name?: string;
          description?: string;
          tags?: string[];
          limited?: boolean;
          limitedDate?: string;
          originalName: string;
        } = { originalName: asset.name };

        Object.entries(meta).forEach(([k, v]) => {
          if (!v) {
            return;
          }

          const strValue = v.join(',');

          if (k === bynderNameProp.id) {
            defaultVals.name = strValue;
          } else if (k === bynderDescriptionProp.id) {
            defaultVals.description = strValue;
          } else if (k === bynderTagsProp.id) {
            defaultVals.tags = v;
          } else if (k === bynderLimitedProp.id) {
            defaultVals.limited = v.length === 1 && v[0] === '1';
          } else if (k === bynderLimitedDateProp.id && v.length === 1) {
            const d = dayjs(v[0], DATE_FORMAT);
            if (d.isValid()) {
              // 2014-12-25T10:30:00Z
              defaultVals.limitedDate = d.toISOString();
            }
          } else {
            bynderMeta[`metaproperty.${k}`] = strValue;
          }
        });

        return {
          ...asset,
          bynderUploadMeta: bynderMeta,
          boards: board ? [board.id] : [],
          ...defaultVals,
        };
      });

    setIsUploading(true);
    const r = await props.uploadToBynder(list);
    setIsUploading(false);

    let message;
    let isError;
    if (r === false) {
      message = 'Could not start uploads, check bynder login.';
      isError = true;
      toastApi.push({ isError, message });
    } else if (r.type !== 'create-bynder-job-response') {
      message = `Could not queue uploads: ${r.type}`;
      isError = true;
      toastApi.push({ isError, message });
    } else {
      message = `Uploads queued.`;
      isError = false;
      toastApi.push({ isError, message });
      void loadUploadsList();
      props.setUploadJobItems([]);
    }
  };

  const editablePropsToRender = [...requiredProps, ...otherProps.filter((p) => columns.has(p.id))];

  const saveAsTemplate = () => {
    const values = editablePropsToRender.reduce(
      (agg, p) => {
        if (p.id === bynderNameProp.id) {
          // skip name in template
          return agg;
        }

        const vals = Object.values(data).map((d) => {
          if (!d) {
            return [];
          }
          const v = d[p.id];

          if (!v) {
            return [];
          }

          return v;
        });

        const allSame = vals.every((z) => z.join(',') === vals[0].join(','));

        const value = allSame ? vals[0] : [];
        agg[p.id] = value;

        return agg;
      },
      {} as { [key: string]: string[] },
    );

    const templateData: Omit<TemplateType, 'name'> = {
      version: '1',
      values,
      columns: Array.from(columns),
      timestamp: new Date().getTime(),
    };

    setCreateTemplate(templateData);
  };

  const loadTemplate = (template: TemplateType) => {
    let d = { ...data };

    for (const [propertyId, value] of Object.entries(template.values)) {
      const p = propMap.get(propertyId);

      if (!p) {
        console.warn(`invalid property ${propertyId} in stored template`, template);
        continue;
      }

      d = changeAll(value, p, d);
    }

    setColumns(new Set([...template.columns, ...columns]));
    setData(d);
  };

  const onCreateTemplate = (_template: TemplateType) => {
    setTemplates({ status: 'uninitialized' });
    setCreateTemplate(false);
  };

  const clearAllData = () => {
    setData(initData(props.assetsToUpload));
    setColumns(new Set());
  };

  const headerPropsToRender = [
    { id: 'name_first', label: 'Name', isEditable: 0, isRequired: 0 } as const,
    ...editablePropsToRender,
  ];

  const headerRow = headerPropsToRender.map((r, index) => {
    return (
      <span key={r.id} css={cellStyle}>
        <span style={index === 0 && showImages ? { marginLeft: '170px' } : {}}>
          {r.label}
          {r.isRequired === 1 ? ' *' : ''}
        </span>
      </span>
    );
  });

  const headerEditRow = headerPropsToRender.map((r) => {
    let el;

    if (r.isEditable) {
      const vals = Object.values(data).map((q) => {
        if (!q) {
          return [];
        }

        const t = q[r.id];

        if (!t) {
          return [];
        }

        return t;
      });

      const allSame = vals.every((z) => z.join(',') === vals[0].join(','));

      let value;
      if (allSame && vals.length > 0 && vals[0].length > 0) {
        value = vals[0];
      }

      el = (
        <MetaInput
          meta={r}
          value={value}
          onChange={(e) => {
            onChangeAllValues(e, r);
          }}
        />
      );
    } else if (r.id === 'name_first') {
      el = (
        <div>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              void saveAsTemplate();
            }}
          >
            Save as template
          </a>
        </div>
      );
    }

    return (
      <span key={r.id} css={cellStyle}>
        {el}
      </span>
    );
  });

  const rows = props.assetsToUpload.map((a) => {
    const editable = data[getUniqueKey({ type: 'upload-job-item', data: a })];

    if (!editable) {
      return <div key="no_editable">No editable for {a.name}!</div>;
    }

    const inputs = editablePropsToRender.map((p) => {
      const v = editable[p.id];

      return (
        <span key={p.id} css={cellStyle}>
          <MetaInput
            meta={p}
            value={v}
            onChange={(e) => {
              onChangeValue(e, p, a);
            }}
          />
        </span>
      );
    });

    const uniqueKey = getUniqueKey({ type: 'upload-job-item', data: a });
    return (
      <UploadRow
        key={uniqueKey}
        {...a}
        uniqueKey={uniqueKey}
        inputs={inputs}
        disabledUploadItems={disabledUploadItems}
        showImages={showImages}
        rowStyle={rowStyle}
        setDisabledUploadItems={setDisabledUploadItems}
      />
    );
  });

  const fieldCheckBoxes = otherProps.map((p) => {
    return (
      <Checkbox
        key={p.id}
        id={p.id}
        css={css`
          margin-bottom: 5px;
          display: inline-flex;
          width: 100%;
        `}
        label={p.label}
        checked={columns.has(p.id)}
        value={''}
        onChange={() => {
          if (columns.has(p.id)) {
            setColumns(new Set([...columns].filter((c) => c !== p.id)));
            setData(removeColumnFromData(p.id, data));
          } else {
            setColumns(new Set([...columns, p.id]));
          }
        }}
      />
    );
  });

  const invalidList = getInvalidRequiredFields(data, requiredProps, disabledUploadItems);

  const uploadButton = (
    <Button
      text="Upload"
      type="primary"
      onClick={handleUpload}
      disabled={invalidList.length > 0}
      loading={isUploading}
    />
  );

  const deleteTemplate = async (template: TemplateType) => {
    if (templates.status !== 'loaded') return;

    const newTemplates = templates.data.filter(
      (t) => JSON.stringify(t) !== JSON.stringify(template),
    );
    await setIDBTemplates(newTemplates);
    setTemplates((prev) => ({ ...prev, data: newTemplates }));
  };

  let uploadButtonContent;
  if (invalidList.length > 0) {
    uploadButtonContent = (
      <Tooltip
        tooltipText={
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          (
            <pre>
              {invalidList
                .slice(0, 5)
                .map(({ name, property }) => `${name} is missing ${property.label}`)
                .join('\n')}
              {invalidList.length > 5 ? '\n...' : ''}
            </pre> // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ) as any
        }
      >
        {uploadButton}
      </Tooltip>
    );
  } else {
    uploadButtonContent = uploadButton;
  }

  let templateSelector;

  if (templates.status === 'loaded') {
    templateSelector = (
      <TemplateSelect
        templates={templates.data}
        propMap={propMap}
        onSelectTemplate={(template) => {
          loadTemplate(template);
        }}
        onDeleteTemplate={deleteTemplate}
      />
    );
  } else {
    templateSelector = <div>Loading...</div>;
  }

  let bynderBoard;

  if (board) {
    bynderBoard = (
      <div>
        Selected board: <BynderBoardInfoShort board={board} />{' '}
        <a
          href={'#'}
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            setBoard(false);
          }}
        >
          Remove
        </a>
      </div>
    );
  } else {
    bynderBoard = <div>No board selected.</div>;
  }

  const loadIDAMValuesLoading =
    searchIDAMImageResult.status === 'loading' ||
    bynderMeta.status === 'loading' ||
    !initialLoadDefaultValuesSet;
  return (
    <>
      {createTemplate !== false ? (
        <CreateTemplate
          templateWithoutName={createTemplate}
          propMap={propMap}
          onClose={() => setCreateTemplate(false)}
          onCreate={onCreateTemplate}
          templates={templates}
        />
      ) : null}

      <Section size={'large'}>
        <Section>
          <SelectBynderBoard loggedInBynder={bynderWrap} onSelect={(item) => setBoard(item)} />
          {bynderBoard}
        </Section>

        <Accordion>
          <AccordionItem id={'active_columns'} title={'Active columns'}>
            <Section
              style={{
                columnCount: 4,
              }}
            >
              {fieldCheckBoxes}
            </Section>
            <div>
              <Button
                title={'Clear all'}
                text={'Clear all'}
                size="small"
                onClick={() => {
                  let d = data;
                  for (const c of columns) {
                    d = removeColumnFromData(c, d);
                  }

                  setData(d);
                  setColumns(new Set());
                }}
              />
            </div>
          </AccordionItem>
        </Accordion>
      </Section>

      <Section size="large">
        <Button
          text="Clear all data"
          onClick={clearAllData}
          css={css`
            margin-right: 10px;
          `}
        />
        <Tooltip
          tooltipText={loadIDAMValuesLoading ? 'Loading IDAM data...' : 'Prefills data from IDAM'}
        >
          <Button
            text="Load IDAM values"
            onClick={loadIDAMValues}
            loading={loadIDAMValuesLoading}
            disabled={loadIDAMValuesLoading}
          />
        </Tooltip>
      </Section>

      <Section size="large">
        <Checkbox
          id={'show_images'}
          label={'Show images in table'}
          checked={showImages}
          value={''}
          onChange={() => {
            setShowImages(!showImages);
            localStorage.setItem('upload_showImages', JSON.stringify(!showImages));
          }}
        />
      </Section>

      <div
        css={css`
          display: grid;
          grid-template-columns: 1fr auto;
          justify-content: end;
        `}
      >
        <Section>Template: {templateSelector}</Section>
        <Section size="large">
          <Button
            text="Clear all data"
            onClick={clearAllData}
            css={css`
              margin-right: 10px;
            `}
          />
        </Section>
      </div>

      <Section size={'large'}>
        <div css={rowStyle}>{headerRow}</div>
        <div css={headerRowStyle}>{headerEditRow}</div>
        <Section size="large">{rows}</Section>

        <UploadAssetExistsWarning
          names={assetNames}
          searchFSResult={searchFSResult}
          bynderBaseUrl={bynderBaseUrl}
          uploadsList={uploadsList}
          propMap={propMap}
        />

        <Section>
          <Button
            text={'Find similar images in DAM'}
            loading={findSimilarResults.status === 'loading'}
            disabled={findSimilarResults.status === 'loading'}
            size="small"
            onClick={async () => {
              const uploads = props.assetsToUpload.filter(
                (a) =>
                  ['jpg', 'jpeg', 'png', 'webp', 'gif'].includes(a.extension.toLowerCase()) &&
                  !disabledUploadItems.has(getUniqueKey({ type: 'upload-job-item', data: a })),
              );

              setFindSimilarResults({ status: 'loading', progress: 0, checkedUploads: uploads });

              const r = await props.findSimilarImages(
                uploads.map((a) => {
                  return { type: 'url', imageUrl: a.url, count: 5 };
                }),
                (progress) => {
                  setFindSimilarResults({ status: 'loading', progress, checkedUploads: uploads });
                },
              );

              setFindSimilarResults({
                status: 'loaded',
                data: r,
                progress: 1,
                checkedUploads: uploads,
              });
            }}
          />
        </Section>

        <Section>
          <UploadSimilarityResults findSimilarResults={findSimilarResults} />
        </Section>

        {uploadButtonContent}
      </Section>
    </>
  );
}
