/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface UnexpectedResponseError
 */
export interface UnexpectedResponseError {
    /**
     * 
     * @type {boolean}
     * @memberof UnexpectedResponseError
     */
    isError: UnexpectedResponseErrorIsErrorEnum;
    /**
     * 
     * @type {string}
     * @memberof UnexpectedResponseError
     */
    type: UnexpectedResponseErrorTypeEnum;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof UnexpectedResponseError
     */
    error?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof UnexpectedResponseError
     */
    status?: number;
}


/**
* @export
* @enum {boolean}
*/
export type UnexpectedResponseErrorIsErrorEnum = true;




/**
* @export
* @enum {string}
*/
export type UnexpectedResponseErrorTypeEnum = 'unexpected-response';





export function UnexpectedResponseErrorFromJSON(json: any): UnexpectedResponseError {
    return UnexpectedResponseErrorFromJSONTyped(json, false);
}

export function UnexpectedResponseErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnexpectedResponseError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isError': json['isError'],
        'type': json['type'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function UnexpectedResponseErrorToJSON(value?: UnexpectedResponseError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isError': value.isError,
        'type': value.type,
        'error': value.error,
        'status': value.status,
    };
}


