/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface PIAItemItemMediaMediaContentLinksDownload
 */
export interface PIAItemItemMediaMediaContentLinksDownload {
    /**
     * 
     * @type {string}
     * @memberof PIAItemItemMediaMediaContentLinksDownload
     */
    href: string;
}

export function PIAItemItemMediaMediaContentLinksDownloadFromJSON(json: any): PIAItemItemMediaMediaContentLinksDownload {
    return PIAItemItemMediaMediaContentLinksDownloadFromJSONTyped(json, false);
}

export function PIAItemItemMediaMediaContentLinksDownloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): PIAItemItemMediaMediaContentLinksDownload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'href': json['href'],
    };
}

export function PIAItemItemMediaMediaContentLinksDownloadToJSON(value?: PIAItemItemMediaMediaContentLinksDownload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'href': value.href,
    };
}


