/** @jsxImportSource @emotion/react */

import React, { JSX } from 'react';
import { EXTENSION_FAKE_ID } from '../AssetEdit';
import { bynderIdProp, bynderNameProp } from '../components/bynder-default-meta-fields';
import { FSAssetDev, FSAssetProd, SearchFSResult } from '../generated-backend-api';
import { Loadable } from '../util/type';
import { Section } from '../components/section/section';
import InlineMessage from '@ingka/inline-message';
import { EmotionJSX } from '@emotion/react/dist/declarations/src/jsx-namespace';

type IdentifyingCol =
  | { type: 'id'; idColumn: number }
  | { type: 'name-ext'; nameCol: number; extensionCol: number }
  | { type: 'no-identifier-found' };

export type Row = Array<{ value: string; className?: string } | undefined>;

export type Cell = { value: string; className?: string } | undefined;

export interface IUseAssetEditHelpers {
  identifyingCol: IdentifyingCol;
  isFaultyRow: (row: Row) => boolean;
  checkIdOrNameExt: () => boolean;
  isCreationCell: (cell: Cell, colIndex: number) => boolean;
  syncDuplicateWarnings: JSX.Element | undefined;
  syncFaultyNameExtWarnings: JSX.Element | undefined;
  syncFaultyCountryCodesWarnings: JSX.Element | undefined;
  faultyIDSet: Set<string>;
  duplicateNames: string[];
  faultyExtNameSet: Set<string>;
}

const useAssetEditHelpers = (
  assets: Loadable<SearchFSResult, { ids: string[] }>,
  headerSelection: string[],
  creationCell: string[],
  assetsNameExt: Loadable<SearchFSResult, { names: string[]; extensions: string[] }>,
  faultyCountryCodesArray: string[],
): IUseAssetEditHelpers => {
  const faultyIDSet = new Set<string>();
  const duplicateNames: string[] = [];
  let duplicateNameList: string[] = [];
  const faultyExtNameList: string[] = [];
  const faultyExtNameSet: Set<string> = new Set();

  const checkIfNamesExtIDHeader = (): IdentifyingCol => {
    const nameIndex = headerSelection.findIndex((q) => q === bynderNameProp.id);
    const extensionIndex = headerSelection.findIndex((q) => q === EXTENSION_FAKE_ID);
    const idIndex = headerSelection.findIndex((q) => q === bynderIdProp.id);

    if (idIndex !== -1) {
      return { type: 'id', idColumn: idIndex };
    }
    if (extensionIndex !== -1 && nameIndex !== -1) {
      return { type: 'name-ext', nameCol: nameIndex, extensionCol: extensionIndex };
    }
    return { type: 'no-identifier-found' };
  };

  const identifyingCol = checkIfNamesExtIDHeader();

  const isFaultyRow = (row: Row): boolean => {
    let names;
    let id;

    if (identifyingCol.type === 'id') {
      id = row[identifyingCol.idColumn];
    } else if (identifyingCol.type === 'name-ext') {
      names = row[identifyingCol.nameCol];
    } else {
      console.log('No identifier');
    }
    if (id) {
      return faultyIDSet.has(id.value);
    } else if (names) {
      return faultyExtNameSet.has(names.value.toLowerCase());
    }
    return false;
  };

  const isCreationCell = (cell: Cell, colIndex: number): boolean => {
    return creationCell.includes([colIndex, cell?.value].join(','));
  };

  const checkIdOrNameExt = (): boolean => {
    if (assets.status === 'uninitialized' && assetsNameExt.status === 'loaded') {
      return false;
    } else if (assets.status === 'loaded' && assetsNameExt.status === 'uninitialized') {
      return false;
    } else if (assetsNameExt.status === 'uninitialized' && assets.status === 'uninitialized') {
      return true;
    }
    return false;
  };

  if (assets.status === 'loaded' && assets.data.isError === false && identifyingCol.type === 'id') {
    const fsSet = new Set();
    const faultyIdElement: EmotionJSX.Element[] = [];
    for (const fsAsset of assets.data.results) {
      fsSet.add(fsAsset.id);
    }

    for (const id of assets.ids) {
      const hasID = fsSet.has(id);
      if (hasID === false) {
        faultyIDSet.add(id);
        faultyIdElement.push(
          <div key={id}>
            <pre>{id}</pre>
          </div>,
        );
      }
    }
  }

  if (
    assetsNameExt.status === 'loaded' &&
    assetsNameExt.data.isError === false &&
    identifyingCol.type === 'name-ext'
  ) {
    const duplicateNamesAsset: string[] = [];
    const fsNameExtArray: string[] = [];

    // create map of name and extension key to list of assets
    const nameExtMap: Map<string, FSAssetDev[] | FSAssetProd[]> = new Map<
      string,
      FSAssetDev[] | FSAssetProd[]
    >();

    for (const fsAsset of assetsNameExt.data.results) {
      fsNameExtArray.push(String(fsAsset.nameLowerCase));
      if (fsAsset.extension) {
        const extension = Object.keys(fsAsset.extension)[0];

        const key = `${fsAsset.name}_${extension}`;
        const list = nameExtMap.get(key);
        if (list) {
          list.push(fsAsset);
        } else {
          nameExtMap.set(key, [fsAsset]);
        }
      }
    }

    for (const [_key, list] of nameExtMap.entries()) {
      if (list.length > 1) {
        const names = list.map((a: FSAssetDev | FSAssetProd) => String(a.nameLowerCase));

        duplicateNames.push(...names);
        duplicateNameList = duplicateNames.filter(
          (item, index) => duplicateNames.indexOf(item) != index, // Filtering on duplicates in the fsNameExtArray by using index.
        );
        duplicateNamesAsset.concat(names);
        names.forEach((n) => {
          faultyExtNameSet.add(n);
        });
      }
    }

    for (const names of assetsNameExt.names) {
      const hasName = fsNameExtArray.includes(names);
      if (hasName === false) {
        faultyExtNameSet.add(names);
        faultyExtNameList.push(names);
      }
    }
  }

  let syncDuplicateWarnings: JSX.Element | undefined;
  let syncFaultyNameExtWarnings: JSX.Element | undefined;
  let syncFaultyCountryCodesWarnings: JSX.Element | undefined;

  if (duplicateNameList.length > 0) {
    syncDuplicateWarnings = (
      <Section>
        <InlineMessage
          variant={'cautionary'}
          title={'The following assets are not unique:'}
          body={<pre>{JSON.stringify(duplicateNameList, null, 4)}</pre>}
        />
      </Section>
    );
  }
  if (faultyExtNameList.length > 0) {
    syncFaultyNameExtWarnings = (
      <Section>
        <InlineMessage
          variant={'cautionary'}
          title={'The following assets does not exist:'}
          body={<pre>{JSON.stringify(faultyExtNameList, null, 4)}</pre>}
        />
      </Section>
    );
  }
  if (faultyCountryCodesArray.length > 0) {
    syncFaultyCountryCodesWarnings = (
      <Section>
        <InlineMessage
          variant={'cautionary'}
          title={'The following country codes are not within INGKA and has been removed:'}
          body={<pre>{JSON.stringify(faultyCountryCodesArray, null, 4)}</pre>}
        />
      </Section>
    );
  }

  return {
    identifyingCol,
    isFaultyRow,
    checkIdOrNameExt,
    isCreationCell,
    syncDuplicateWarnings,
    syncFaultyNameExtWarnings,
    syncFaultyCountryCodesWarnings,
    faultyIDSet,
    duplicateNames,
    faultyExtNameSet,
  };
};

export default useAssetEditHelpers;
