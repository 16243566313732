/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface IMCCImageSearchResultResponseResultsJsonapi
 */
export interface IMCCImageSearchResultResponseResultsJsonapi {
    /**
     * 
     * @type {string}
     * @memberof IMCCImageSearchResultResponseResultsJsonapi
     */
    version: string;
}

export function IMCCImageSearchResultResponseResultsJsonapiFromJSON(json: any): IMCCImageSearchResultResponseResultsJsonapi {
    return IMCCImageSearchResultResponseResultsJsonapiFromJSONTyped(json, false);
}

export function IMCCImageSearchResultResponseResultsJsonapiFromJSONTyped(json: any, ignoreDiscriminator: boolean): IMCCImageSearchResultResponseResultsJsonapi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': json['version'],
    };
}

export function IMCCImageSearchResultResponseResultsJsonapiToJSON(value?: IMCCImageSearchResultResponseResultsJsonapi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': value.version,
    };
}


