/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import AspectRatioBox from '@ingka/aspect-ratio-box';
import Button from '@ingka/button';
import Skeleton from '@ingka/skeleton';
import equal from 'deep-equal';
import React, { JSX, useEffect, useState } from 'react';
import { SectionWrap } from '../../SectionWrap';
import { SearchExtApi2Result, SearchRequestBody } from '../../generated-backend-api';
import { useClient } from '../../hooks/api-client';
import { useSearchableFields } from '../../hooks/searchableFields';
import { useToasts } from '../../util/toastProvider';
import { Loadable } from '../../util/type';
import { BynderBoardInput } from '../bynder-board-input/BynderBoardInput';
import { Grid } from '../grid';
import { Pagination } from '../pagination';
import { SearchQueryBuilder } from '../search-query-builder';
import { SearchQueryFieldAdder } from '../search-query-field-adder';
import { SearchFieldQuery } from '../search-query-types';
import { SearchResultAssetCard } from '../search-result-asset-card';
import { SearchSortPickerList } from '../search-sort-picker-list';
import { Section } from '../section/section';
import { Stack } from '../stack';
import CropAssetButton from './CropAssetButton';
import {
  ASSETS_PER_PAGE,
  DirectionalSort,
  frontendSearchStateToSearchRequestBody,
  getAllIds,
  prioritizedSearchableFields,
  searchRequestBodyToFrontendSearchState,
} from './helpers';

interface SearchProps {
  title: string;
  search: (opts: SearchRequestBody) => Promise<void> | undefined;
  results: Loadable<
    SearchExtApi2Result,
    {
      options: SearchRequestBody;
    }
  >;
  cropPage?: boolean;
  usageRights: string[];
  requestingMarket: string | undefined;
  urlQuery: SearchRequestBody | null;
  searchState: FrontendSearchState;
  setUrlQuery: (q: SearchRequestBody) => void;
  setSearchState: React.Dispatch<React.SetStateAction<FrontendSearchState>>;
}

export interface FrontendSearchState {
  query: SearchFieldQuery;
  limit: number;
  offset: number;
  sort?: DirectionalSort[];
}

export function Search({
  title,
  cropPage,
  usageRights,
  requestingMarket,
  results,
  urlQuery,
  searchState,
  search,
  setUrlQuery,
  setSearchState,
}: SearchProps): JSX.Element {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [_, setAssetsInBoard] = useState<string[]>([]);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isSelectingAll, setSelectingAll] = useState(false);

  const doSearch = (offset: number, force = false) => {
    const q = frontendSearchStateToSearchRequestBody(
      searchState,
      usageRights,
      requestingMarket ?? '',
      offset,
    );

    if (force) {
      setForceRefresh(true);
    }

    setUrlQuery(q);
  };

  useEffect(() => {
    if (urlQuery === null) {
      if (results.status !== 'uninitialized') {
        setUrlQuery(results.options);
        setSearchState(searchRequestBodyToFrontendSearchState(results.options));
      }

      return;
    }

    if (results.status === 'uninitialized') {
      // exec query

      void search(urlQuery);
      return;
    }

    if (!equal(results.options, urlQuery) || forceRefresh) {
      if (!equal(results.options.query, urlQuery.query)) {
        setSelectedIds([]);
      }
      // exec query
      void search(urlQuery);
      setForceRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlQuery, results, search, setUrlQuery, forceRefresh, setForceRefresh]);

  let allSelected = false;
  if (results.status === 'loaded' && !results.data.isError) {
    allSelected = selectedIds.length == results.data.results.total.value;
  }

  const apiClient = useClient();
  const toastApi = useToasts();
  const handleSelectAll = async () => {
    if (results.status === 'loaded' && !results.data.isError) {
      if (allSelected) {
        setSelectedIds([]);
      } else {
        try {
          setSelectingAll(true);
          setSelectedIds(await getAllIds(apiClient, results.options));
        } catch (e) {
          console.error(e);
          toastApi.push({
            isError: true,
            message: 'Error occured while attempting to select all',
          });
        } finally {
          setSelectingAll(false);
        }
      }
    }
  };

  let resultList;
  let totalAssets = 0;

  if (results.status === 'loading') {
    const indexNumbers = Array.from(Array(ASSETS_PER_PAGE).keys());

    resultList = (
      <div>
        <Grid colGap={20} rowGap={20} columns={5}>
          {indexNumbers.map((q) => (
            <div key={q}>
              <AspectRatioBox>
                <Skeleton className="image" />
              </AspectRatioBox>
            </div>
          ))}
        </Grid>
      </div>
    );
  } else if (results.status === 'loaded') {
    const r = results.data;

    if (r.isError) {
      resultList = (
        <div>
          Error: <pre>{JSON.stringify(r, null, 4)}</pre>
        </div>
      );
    } else {
      totalAssets = r.results.total.value;
      resultList = (
        <div>
          <Grid colGap={20} rowGap={30} columns={5}>
            {r.results.assets.map((asset) => {
              return (
                <div
                  key={asset.id}
                  css={css`
                    position: relative;
                    button {
                      opacity: 0;
                    }
                    input[id='${asset.id}'] {
                      opacity: ${selectedIds.length > 0 ? 1 : 0};
                    }
                    &:hover {
                      button,
                      input[id='${asset.id}'] {
                        opacity: 1;
                      }
                    }
                  `}
                >
                  <SearchResultAssetCard
                    asset={asset}
                    uri={
                      cropPage
                        ? `/crop/${encodeURIComponent(asset.id)}`
                        : `/assetstatus/${encodeURIComponent(asset.name)}`
                    }
                  />
                  <CropAssetButton asset={asset} />
                  <input
                    id={asset.id}
                    type="checkbox"
                    checked={selectedIds.includes(asset.id)}
                    onChange={() => {
                      if (selectedIds.includes(asset.id)) {
                        setSelectedIds((prev) => prev.filter((id) => id !== asset.id));
                      } else {
                        setSelectedIds((prev) => [...prev, asset.id]);
                      }
                    }}
                    css={css`
                      position: absolute;
                      top: 4px;
                      left: 4px;
                      background: rgba(255, 255, 255, 0.5);
                      border-radius: 1rem;
                      height: 20px;
                      width: 20px;
                      cursor: pointer;
                    `}
                  />
                </div>
              );
            })}
          </Grid>
        </div>
      );
    }
  }

  const { data: searchableFields } = useSearchableFields();
  return (
    <SectionWrap>
      <Section>
        <h2>{title} </h2>
      </Section>
      <Section>
        <Stack vertical gap={20}>
          <SearchQueryBuilder
            onChange={(q) => setSearchState({ ...searchState, query: q })}
            onEnter={() => doSearch(0)}
            query={searchState.query}
          />
          <SearchQueryFieldAdder
            query={searchState.query}
            searchableFields={searchableFields}
            prioritizedFields={prioritizedSearchableFields}
            onChange={(q) => setSearchState({ ...searchState, query: q })}
          />
          <SearchSortPickerList
            allFields={searchableFields}
            prioritizedFields={prioritizedSearchableFields}
            sorts={searchState.sort ?? []}
            onChange={(s) => setSearchState({ ...searchState, sort: s })}
          />
          <Button onClick={() => doSearch(0, true)} text="Search" />

          {results.status !== 'uninitialized' && (
            <>
              <BynderBoardInput
                onAssetsAddedToBoard={() => {
                  setSelectedIds([]);
                }}
                selectedAssetIds={selectedIds}
                onBoardStatusChange={(status) => {
                  if (status.status === 'loaded' && status.data.type === 'collection-result') {
                    setAssetsInBoard(status.data.assets);
                  }
                }}
                toggleSelectAll={handleSelectAll}
                allSelected={allSelected}
                isSelectingAll={isSelectingAll}
              />
              <Pagination
                selectedAssets={selectedIds.length}
                totalAssets={totalAssets}
                offset={urlQuery?.offset}
                pageSize={ASSETS_PER_PAGE}
                onChangePage={(offset) => doSearch(offset)}
                disabled={results.status !== 'loaded'}
              />
            </>
          )}
        </Stack>
      </Section>
      <Section>{resultList}</Section>
    </SectionWrap>
  );
}
