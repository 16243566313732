/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    IDAMRenditionsColorspace,
    IDAMRenditionsColorspaceFromJSON,
    IDAMRenditionsColorspaceFromJSONTyped,
    IDAMRenditionsColorspaceToJSON,
    IDAMRenditionsHasClippingPath,
    IDAMRenditionsHasClippingPathFromJSON,
    IDAMRenditionsHasClippingPathFromJSONTyped,
    IDAMRenditionsHasClippingPathToJSON,
    IDAMRenditionsOrientation,
    IDAMRenditionsOrientationFromJSON,
    IDAMRenditionsOrientationFromJSONTyped,
    IDAMRenditionsOrientationToJSON,
    IDAMRenditionsResolution,
    IDAMRenditionsResolutionFromJSON,
    IDAMRenditionsResolutionFromJSONTyped,
    IDAMRenditionsResolutionToJSON,
} from './';

/**
 * 
 * @export
 * @interface IDAMRenditions
 */
export interface IDAMRenditions {
    /**
     * 
     * @type {string}
     * @memberof IDAMRenditions
     */
    _class?: string;
    /**
     * 
     * @type {IDAMRenditionsColorspace}
     * @memberof IDAMRenditions
     */
    colorspace?: IDAMRenditionsColorspace | null;
    /**
     * 
     * @type {string}
     * @memberof IDAMRenditions
     */
    extension?: string;
    /**
     * 
     * @type {IDAMRenditionsHasClippingPath}
     * @memberof IDAMRenditions
     */
    hasClippingPath?: IDAMRenditionsHasClippingPath | null;
    /**
     * 
     * @type {number}
     * @memberof IDAMRenditions
     */
    height?: number;
    /**
     * 
     * @type {IDAMRenditionsOrientation}
     * @memberof IDAMRenditions
     */
    orientation?: IDAMRenditionsOrientation | null;
    /**
     * 
     * @type {string}
     * @memberof IDAMRenditions
     */
    renditionPublicUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof IDAMRenditions
     */
    renditionPrivateUrl?: string;
    /**
     * 
     * @type {IDAMRenditionsResolution}
     * @memberof IDAMRenditions
     */
    resolution?: IDAMRenditionsResolution | null;
    /**
     * 
     * @type {string}
     * @memberof IDAMRenditions
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof IDAMRenditions
     */
    width?: number;
    /**
     * 
     * @type {string}
     * @memberof IDAMRenditions
     */
    fileSize?: string;
    /**
     * 
     * @type {string}
     * @memberof IDAMRenditions
     */
    modifiedDate?: string;
}

export function IDAMRenditionsFromJSON(json: any): IDAMRenditions {
    return IDAMRenditionsFromJSONTyped(json, false);
}

export function IDAMRenditionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDAMRenditions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_class': !exists(json, 'class') ? undefined : json['class'],
        'colorspace': !exists(json, 'colorspace') ? undefined : IDAMRenditionsColorspaceFromJSON(json['colorspace']),
        'extension': !exists(json, 'extension') ? undefined : json['extension'],
        'hasClippingPath': !exists(json, 'hasClippingPath') ? undefined : IDAMRenditionsHasClippingPathFromJSON(json['hasClippingPath']),
        'height': !exists(json, 'height') ? undefined : json['height'],
        'orientation': !exists(json, 'orientation') ? undefined : IDAMRenditionsOrientationFromJSON(json['orientation']),
        'renditionPublicUrl': !exists(json, 'renditionPublicUrl') ? undefined : json['renditionPublicUrl'],
        'renditionPrivateUrl': !exists(json, 'renditionPrivateUrl') ? undefined : json['renditionPrivateUrl'],
        'resolution': !exists(json, 'resolution') ? undefined : IDAMRenditionsResolutionFromJSON(json['resolution']),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'fileSize': !exists(json, 'fileSize') ? undefined : json['fileSize'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : json['modifiedDate'],
    };
}

export function IDAMRenditionsToJSON(value?: IDAMRenditions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'class': value._class,
        'colorspace': IDAMRenditionsColorspaceToJSON(value.colorspace),
        'extension': value.extension,
        'hasClippingPath': IDAMRenditionsHasClippingPathToJSON(value.hasClippingPath),
        'height': value.height,
        'orientation': IDAMRenditionsOrientationToJSON(value.orientation),
        'renditionPublicUrl': value.renditionPublicUrl,
        'renditionPrivateUrl': value.renditionPrivateUrl,
        'resolution': IDAMRenditionsResolutionToJSON(value.resolution),
        'type': value.type,
        'width': value.width,
        'fileSize': value.fileSize,
        'modifiedDate': value.modifiedDate,
    };
}


