/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
// aaaa
// export enum IMCCImageAttributesLaunch {
// 
// 
//     L1 = 'L1',
// 
//     L2 = 'L2',
// 
//     L3 = 'L3',
// 
//     L4 = 'L4'
// 
// 
// }

export type IMCCImageAttributesLaunch =   'L1'|  'L2'|  'L3'|  'L4'  

export function IMCCImageAttributesLaunchFromJSON(json: any): IMCCImageAttributesLaunch {
    return IMCCImageAttributesLaunchFromJSONTyped(json, false);
}

export function IMCCImageAttributesLaunchFromJSONTyped(json: any, ignoreDiscriminator: boolean): IMCCImageAttributesLaunch {
    return json as IMCCImageAttributesLaunch;
}

export function IMCCImageAttributesLaunchToJSON(value?: IMCCImageAttributesLaunch | null): any {
    return value as any;
}

