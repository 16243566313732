/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface AssetPropertyLanguage
 */
export interface AssetPropertyLanguage {
    /**
     * 
     * @type {string}
     * @memberof AssetPropertyLanguage
     */
    language?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetPropertyLanguage
     */
    value?: string;
}

export function AssetPropertyLanguageFromJSON(json: any): AssetPropertyLanguage {
    return AssetPropertyLanguageFromJSONTyped(json, false);
}

export function AssetPropertyLanguageFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetPropertyLanguage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'language': !exists(json, 'language') ? undefined : json['language'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function AssetPropertyLanguageToJSON(value?: AssetPropertyLanguage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'language': value.language,
        'value': value.value,
    };
}


