/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    IDAMPropping,
    IDAMProppingFromJSON,
    IDAMProppingFromJSONTyped,
    IDAMProppingToJSON,
    IDAMSupporting,
    IDAMSupportingFromJSON,
    IDAMSupportingFromJSONTyped,
    IDAMSupportingToJSON,
} from './';

/**
 * 
 * @export
 * @interface IDAMProducts
 */
export interface IDAMProducts {
    /**
     * 
     * @type {Array<IDAMSupporting>}
     * @memberof IDAMProducts
     */
    supporting?: Array<IDAMSupporting>;
    /**
     * 
     * @type {Array<IDAMPropping>}
     * @memberof IDAMProducts
     */
    propping?: Array<IDAMPropping>;
}

export function IDAMProductsFromJSON(json: any): IDAMProducts {
    return IDAMProductsFromJSONTyped(json, false);
}

export function IDAMProductsFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDAMProducts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'supporting': !exists(json, 'supporting') ? undefined : ((json['supporting'] as Array<any>).map(IDAMSupportingFromJSON)),
        'propping': !exists(json, 'propping') ? undefined : ((json['propping'] as Array<any>).map(IDAMProppingFromJSON)),
    };
}

export function IDAMProductsToJSON(value?: IDAMProducts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'supporting': value.supporting === undefined ? undefined : ((value.supporting as Array<any>).map(IDAMSupportingToJSON)),
        'propping': value.propping === undefined ? undefined : ((value.propping as Array<any>).map(IDAMProppingToJSON)),
    };
}


