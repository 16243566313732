/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// oneof
import {
    CouldNotPerformRequest,
    RequestAbortedError,
    StreamResizeError,
    UnexpectedResponseError,
    VIImageFeaturesResponse,
    ValidationError,
    CouldNotPerformRequestFromJSONTyped,
    CouldNotPerformRequestToJSON,
    RequestAbortedErrorFromJSONTyped,
    RequestAbortedErrorToJSON,
    StreamResizeErrorFromJSONTyped,
    StreamResizeErrorToJSON,
    UnexpectedResponseErrorFromJSONTyped,
    UnexpectedResponseErrorToJSON,
    VIImageFeaturesResponseFromJSONTyped,
    VIImageFeaturesResponseToJSON,
    ValidationErrorFromJSONTyped,
    ValidationErrorToJSON,
} from './';

/**
 * oneof interface
 * @type VIImageFeatures
 * 
 * @export
 */
export type VIImageFeatures = CouldNotPerformRequest | ValidationError | RequestAbortedError | StreamResizeError | UnexpectedResponseError | VIImageFeaturesResponse;


export function VIImageFeaturesFromJSON(json: any): VIImageFeatures {
    return VIImageFeaturesFromJSONTyped(json, false);
}

export function VIImageFeaturesFromJSONTyped(json: any, ignoreDiscriminator: boolean): VIImageFeatures {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'could-not-perform-request':
            return {...CouldNotPerformRequestFromJSONTyped(json, true), type: 'could-not-perform-request'};
        case 'failed-to-validate':
            return {...ValidationErrorFromJSONTyped(json, true), type: 'failed-to-validate'};
        case 'request-aborted':
            return {...RequestAbortedErrorFromJSONTyped(json, true), type: 'request-aborted'};
        case 'stream-resize-error':
            return {...StreamResizeErrorFromJSONTyped(json, true), type: 'stream-resize-error'};
        case 'unexpected-response':
            return {...UnexpectedResponseErrorFromJSONTyped(json, true), type: 'unexpected-response'};
        case 'vi-image-features':
            return {...VIImageFeaturesResponseFromJSONTyped(json, true), type: 'vi-image-features'};
        default:
            throw new Error(`No variant of VIImageFeatures exists with 'type=${json['type']}'`);
    }
}

export function VIImageFeaturesToJSON(value?: VIImageFeatures | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'could-not-perform-request':
            return CouldNotPerformRequestToJSON(value);
        case 'failed-to-validate':
            return ValidationErrorToJSON(value);
        case 'request-aborted':
            return RequestAbortedErrorToJSON(value);
        case 'stream-resize-error':
            return StreamResizeErrorToJSON(value);
        case 'unexpected-response':
            return UnexpectedResponseErrorToJSON(value);
        case 'vi-image-features':
            return VIImageFeaturesResponseToJSON(value);
        default:
            throw new Error(`No variant of VIImageFeatures exists with 'type=${value['type']}'`);
    }
}

