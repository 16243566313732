/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    PIABulkItemEntry,
    PIABulkItemEntryFromJSON,
    PIABulkItemEntryFromJSONTyped,
    PIABulkItemEntryToJSON,
} from './';

/**
 * 
 * @export
 * @interface PIAItemList
 */
export interface PIAItemList {
    /**
     * 
     * @type {Array<PIABulkItemEntry>}
     * @memberof PIAItemList
     */
    entities: Array<PIABulkItemEntry>;
}

export function PIAItemListFromJSON(json: any): PIAItemList {
    return PIAItemListFromJSONTyped(json, false);
}

export function PIAItemListFromJSONTyped(json: any, ignoreDiscriminator: boolean): PIAItemList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entities': ((json['entities'] as Array<any>).map(PIABulkItemEntryFromJSON)),
    };
}

export function PIAItemListToJSON(value?: PIAItemList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entities': ((value.entities as Array<any>).map(PIABulkItemEntryToJSON)),
    };
}


