/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    PIAItemCountrySpecificParent,
    PIAItemCountrySpecificParentFromJSON,
    PIAItemCountrySpecificParentFromJSONTyped,
    PIAItemCountrySpecificParentToJSON,
    PIAItemItemMedia,
    PIAItemItemMediaFromJSON,
    PIAItemItemMediaFromJSONTyped,
    PIAItemItemMediaToJSON,
    PIAItemProductNameLocal,
    PIAItemProductNameLocalFromJSON,
    PIAItemProductNameLocalFromJSONTyped,
    PIAItemProductNameLocalToJSON,
    PIAItemProductTypeLocal,
    PIAItemProductTypeLocalFromJSON,
    PIAItemProductTypeLocalFromJSONTyped,
    PIAItemProductTypeLocalToJSON,
} from './';

/**
 * 
 * @export
 * @interface PIAItem
 */
export interface PIAItem {
    /**
     * 
     * @type {string}
     * @memberof PIAItem
     */
    itemNo: string;
    /**
     * 
     * @type {string}
     * @memberof PIAItem
     */
    itemType: string;
    /**
     * 
     * @type {string}
     * @memberof PIAItem
     */
    itemState: string;
    /**
     * 
     * @type {Array<PIAItemItemMedia>}
     * @memberof PIAItem
     */
    itemMedia?: Array<PIAItemItemMedia>;
    /**
     * 
     * @type {PIAItemCountrySpecificParent}
     * @memberof PIAItem
     */
    countrySpecificParent?: PIAItemCountrySpecificParent;
    /**
     * 
     * @type {Array<PIAItemProductNameLocal>}
     * @memberof PIAItem
     */
    productNameLocal?: Array<PIAItemProductNameLocal>;
    /**
     * 
     * @type {Array<PIAItemProductTypeLocal>}
     * @memberof PIAItem
     */
    productTypeLocal?: Array<PIAItemProductTypeLocal>;
}

export function PIAItemFromJSON(json: any): PIAItem {
    return PIAItemFromJSONTyped(json, false);
}

export function PIAItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): PIAItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemNo': json['itemNo'],
        'itemType': json['itemType'],
        'itemState': json['itemState'],
        'itemMedia': !exists(json, 'itemMedia') ? undefined : ((json['itemMedia'] as Array<any>).map(PIAItemItemMediaFromJSON)),
        'countrySpecificParent': !exists(json, 'countrySpecificParent') ? undefined : PIAItemCountrySpecificParentFromJSON(json['countrySpecificParent']),
        'productNameLocal': !exists(json, 'productNameLocal') ? undefined : ((json['productNameLocal'] as Array<any>).map(PIAItemProductNameLocalFromJSON)),
        'productTypeLocal': !exists(json, 'productTypeLocal') ? undefined : ((json['productTypeLocal'] as Array<any>).map(PIAItemProductTypeLocalFromJSON)),
    };
}

export function PIAItemToJSON(value?: PIAItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'itemNo': value.itemNo,
        'itemType': value.itemType,
        'itemState': value.itemState,
        'itemMedia': value.itemMedia === undefined ? undefined : ((value.itemMedia as Array<any>).map(PIAItemItemMediaToJSON)),
        'countrySpecificParent': PIAItemCountrySpecificParentToJSON(value.countrySpecificParent),
        'productNameLocal': value.productNameLocal === undefined ? undefined : ((value.productNameLocal as Array<any>).map(PIAItemProductNameLocalToJSON)),
        'productTypeLocal': value.productTypeLocal === undefined ? undefined : ((value.productTypeLocal as Array<any>).map(PIAItemProductTypeLocalToJSON)),
    };
}


