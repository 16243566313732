/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    IMCCImageResponseDataLinksSelf,
    IMCCImageResponseDataLinksSelfFromJSON,
    IMCCImageResponseDataLinksSelfFromJSONTyped,
    IMCCImageResponseDataLinksSelfToJSON,
} from './';

/**
 * 
 * @export
 * @interface IMCCImageResponseDataLinks
 */
export interface IMCCImageResponseDataLinks {
    /**
     * 
     * @type {IMCCImageResponseDataLinksSelf}
     * @memberof IMCCImageResponseDataLinks
     */
    self: IMCCImageResponseDataLinksSelf;
}

export function IMCCImageResponseDataLinksFromJSON(json: any): IMCCImageResponseDataLinks {
    return IMCCImageResponseDataLinksFromJSONTyped(json, false);
}

export function IMCCImageResponseDataLinksFromJSONTyped(json: any, ignoreDiscriminator: boolean): IMCCImageResponseDataLinks {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'self': IMCCImageResponseDataLinksSelfFromJSON(json['self']),
    };
}

export function IMCCImageResponseDataLinksToJSON(value?: IMCCImageResponseDataLinks | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'self': IMCCImageResponseDataLinksSelfToJSON(value.self),
    };
}


