/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface BynderAssetJobRetryRequestBody
 */
export interface BynderAssetJobRetryRequestBody {
    /**
     * 
     * @type {string}
     * @memberof BynderAssetJobRetryRequestBody
     */
    accessToken: string;
    /**
     * 
     * @type {string}
     * @memberof BynderAssetJobRetryRequestBody
     */
    refreshToken: string;
    /**
     * 
     * @type {string}
     * @memberof BynderAssetJobRetryRequestBody
     */
    id: string;
}

export function BynderAssetJobRetryRequestBodyFromJSON(json: any): BynderAssetJobRetryRequestBody {
    return BynderAssetJobRetryRequestBodyFromJSONTyped(json, false);
}

export function BynderAssetJobRetryRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): BynderAssetJobRetryRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': json['accessToken'],
        'refreshToken': json['refreshToken'],
        'id': json['id'],
    };
}

export function BynderAssetJobRetryRequestBodyToJSON(value?: BynderAssetJobRetryRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessToken': value.accessToken,
        'refreshToken': value.refreshToken,
        'id': value.id,
    };
}


