/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface VIroomclassification
 */
export interface VIroomclassification {
    /**
     * 
     * @type {string}
     * @memberof VIroomclassification
     */
    room_type: string;
    /**
     * 
     * @type {number}
     * @memberof VIroomclassification
     */
    score: number;
}

export function VIroomclassificationFromJSON(json: any): VIroomclassification {
    return VIroomclassificationFromJSONTyped(json, false);
}

export function VIroomclassificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): VIroomclassification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'room_type': json['room_type'],
        'score': json['score'],
    };
}

export function VIroomclassificationToJSON(value?: VIroomclassification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'room_type': value.room_type,
        'score': value.score,
    };
}


