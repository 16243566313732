/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    FSVIAssetMetaDataV2,
    FSVIAssetMetaDataV2FromJSON,
    FSVIAssetMetaDataV2FromJSONTyped,
    FSVIAssetMetaDataV2ToJSON,
} from './';

/**
 * 
 * @export
 * @interface FSVIAssetMetaV2
 */
export interface FSVIAssetMetaV2 {
    /**
     * 
     * @type {FSVIAssetMetaDataV2}
     * @memberof FSVIAssetMetaV2
     */
    visualSearch: FSVIAssetMetaDataV2;
}

export function FSVIAssetMetaV2FromJSON(json: any): FSVIAssetMetaV2 {
    return FSVIAssetMetaV2FromJSONTyped(json, false);
}

export function FSVIAssetMetaV2FromJSONTyped(json: any, ignoreDiscriminator: boolean): FSVIAssetMetaV2 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'visualSearch': FSVIAssetMetaDataV2FromJSON(json['visualSearch']),
    };
}

export function FSVIAssetMetaV2ToJSON(value?: FSVIAssetMetaV2 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'visualSearch': FSVIAssetMetaDataV2ToJSON(value.visualSearch),
    };
}


