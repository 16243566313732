/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface AssetActiveOriginalFocusPoint
 */
export interface AssetActiveOriginalFocusPoint {
    /**
     * 
     * @type {number}
     * @memberof AssetActiveOriginalFocusPoint
     */
    y: number;
    /**
     * 
     * @type {number}
     * @memberof AssetActiveOriginalFocusPoint
     */
    x: number;
}

export function AssetActiveOriginalFocusPointFromJSON(json: any): AssetActiveOriginalFocusPoint {
    return AssetActiveOriginalFocusPointFromJSONTyped(json, false);
}

export function AssetActiveOriginalFocusPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetActiveOriginalFocusPoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'y': json['y'],
        'x': json['x'],
    };
}

export function AssetActiveOriginalFocusPointToJSON(value?: AssetActiveOriginalFocusPoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'y': value.y,
        'x': value.x,
    };
}


