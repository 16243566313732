import React, { JSX } from 'react';
import { Interpolation, Theme } from '@emotion/react';

interface ISectionProps {
  css?: Interpolation<Theme>;
  size?: 'large' | 'small';
  className?: string;
  style?: React.CSSProperties;
}

export function Section(props: React.PropsWithChildren<ISectionProps>): JSX.Element {
  return (
    <div
      style={{ marginBottom: props.size === 'large' ? '40px' : '20px', ...props.style }}
      className={props.className}
    >
      {props.children}
    </div>
  );
}
