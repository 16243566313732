/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    PIAItemItemMediaMediaContentLinks,
    PIAItemItemMediaMediaContentLinksFromJSON,
    PIAItemItemMediaMediaContentLinksFromJSONTyped,
    PIAItemItemMediaMediaContentLinksToJSON,
} from './';

/**
 * 
 * @export
 * @interface PIAItemItemMediaMediaContent
 */
export interface PIAItemItemMediaMediaContent {
    /**
     * 
     * @type {string}
     * @memberof PIAItemItemMediaMediaContent
     */
    mediaFileTypeNo?: string;
    /**
     * 
     * @type {string}
     * @memberof PIAItemItemMediaMediaContent
     */
    mediaFileTypeName?: string;
    /**
     * 
     * @type {string}
     * @memberof PIAItemItemMediaMediaContent
     */
    mediaQuality?: string;
    /**
     * 
     * @type {string}
     * @memberof PIAItemItemMediaMediaContent
     */
    mediaFilename?: string;
    /**
     * 
     * @type {PIAItemItemMediaMediaContentLinks}
     * @memberof PIAItemItemMediaMediaContent
     */
    _links?: PIAItemItemMediaMediaContentLinks;
}

export function PIAItemItemMediaMediaContentFromJSON(json: any): PIAItemItemMediaMediaContent {
    return PIAItemItemMediaMediaContentFromJSONTyped(json, false);
}

export function PIAItemItemMediaMediaContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PIAItemItemMediaMediaContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mediaFileTypeNo': !exists(json, 'mediaFileTypeNo') ? undefined : json['mediaFileTypeNo'],
        'mediaFileTypeName': !exists(json, 'mediaFileTypeName') ? undefined : json['mediaFileTypeName'],
        'mediaQuality': !exists(json, 'mediaQuality') ? undefined : json['mediaQuality'],
        'mediaFilename': !exists(json, 'mediaFilename') ? undefined : json['mediaFilename'],
        '_links': !exists(json, '_links') ? undefined : PIAItemItemMediaMediaContentLinksFromJSON(json['_links']),
    };
}

export function PIAItemItemMediaMediaContentToJSON(value?: PIAItemItemMediaMediaContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mediaFileTypeNo': value.mediaFileTypeNo,
        'mediaFileTypeName': value.mediaFileTypeName,
        'mediaQuality': value.mediaQuality,
        'mediaFilename': value.mediaFilename,
        '_links': PIAItemItemMediaMediaContentLinksToJSON(value._links),
    };
}


