import React, { JSX, useEffect, useRef, useState } from 'react';
import { ProductCoordinateListType } from '../util/product-coordinates';
import { ShoppableDot } from './shoppable-dot';

function useOnScreen(ref: React.RefObject<Element | null>) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

    observer.observe(ref.current);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}

interface IShoppableProps {
  url: string;
  products: ProductCoordinateListType;
  highlightProductBoxes?: string[];
  onDotClick?: (art: string) => void;
}

function toBoundedNumber(s: string) {
  return Math.min(Math.max(Number(s), 0.0), 1.0);
}

export function Shoppable(props: IShoppableProps): JSX.Element {
  const targetRef = useRef<HTMLImageElement>(null);
  const isVisible = useOnScreen(targetRef);

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (!targetRef.current) {
      return;
    }

    const r = targetRef.current.getBoundingClientRect();
    if (dimensions.width !== r.width || dimensions.height !== r.height) {
      setDimensions({
        width: r.width,
        height: r.height,
      });
    }
  }, [dimensions.width, dimensions.height, isVisible]);

  const dots = props.products.map((p) => {
    return (
      <ShoppableDot
        key={p.prd}
        product={p}
        onClick={props.onDotClick}
        isActive={props.highlightProductBoxes && props.highlightProductBoxes.includes(p.prd)}
      />
    );
  });

  let boxes;
  if (props.highlightProductBoxes) {
    const bb = props.highlightProductBoxes;

    const p = props.products.filter((p) => bb.includes(p.prd));

    if (p.length > 0) {
      boxes = p.map((z, index) => {
        return (
          <div
            key={`box_${z.prd}_${index}`}
            style={{
              position: 'absolute',
              width: `${toBoundedNumber(z.box.w) * 100}%`,
              height: `${toBoundedNumber(z.box.h) * 100}%`,
              left: `${toBoundedNumber(z.box.l) * 100}%`,
              top: `${toBoundedNumber(z.box.t) * 100}%`,
              border: '1px solid white',
              zIndex: 50,
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '-25px',
                backgroundColor: '#fafafa',
                padding: '2px',
              }}
            >
              {z.prd}
            </div>
          </div>
        );
      });
    }
  }

  return (
    <div ref={targetRef} style={{ position: 'relative' }} className="shoppable-image--visible-dots">
      {dimensions.width > 100 ? dots : null}
      {dimensions.width > 100 ? boxes : null}
      <img src={props.url} style={{ width: '100%' }} />
    </div>
  );
}
