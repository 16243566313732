/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// oneof
import {
    CouldNotAuthIMCC,
    CouldNotPerformRequest,
    IMCCDeliveriesListResponse,
    RequestAbortedError,
    UnexpectedResponseError,
    ValidationError,
    CouldNotAuthIMCCFromJSONTyped,
    CouldNotAuthIMCCToJSON,
    CouldNotPerformRequestFromJSONTyped,
    CouldNotPerformRequestToJSON,
    IMCCDeliveriesListResponseFromJSONTyped,
    IMCCDeliveriesListResponseToJSON,
    RequestAbortedErrorFromJSONTyped,
    RequestAbortedErrorToJSON,
    UnexpectedResponseErrorFromJSONTyped,
    UnexpectedResponseErrorToJSON,
    ValidationErrorFromJSONTyped,
    ValidationErrorToJSON,
} from './';

/**
 * oneof interface
 * @type IMCCDeliveries
 * 
 * @export
 */
export type IMCCDeliveries = CouldNotAuthIMCC | CouldNotPerformRequest | IMCCDeliveriesListResponse | ValidationError | RequestAbortedError | UnexpectedResponseError;


export function IMCCDeliveriesFromJSON(json: any): IMCCDeliveries {
    return IMCCDeliveriesFromJSONTyped(json, false);
}

export function IMCCDeliveriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): IMCCDeliveries {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'could-not-auth-imcc':
            return {...CouldNotAuthIMCCFromJSONTyped(json, true), type: 'could-not-auth-imcc'};
        case 'could-not-perform-request':
            return {...CouldNotPerformRequestFromJSONTyped(json, true), type: 'could-not-perform-request'};
        case 'deliveries-list-response':
            return {...IMCCDeliveriesListResponseFromJSONTyped(json, true), type: 'deliveries-list-response'};
        case 'failed-to-validate':
            return {...ValidationErrorFromJSONTyped(json, true), type: 'failed-to-validate'};
        case 'request-aborted':
            return {...RequestAbortedErrorFromJSONTyped(json, true), type: 'request-aborted'};
        case 'unexpected-response':
            return {...UnexpectedResponseErrorFromJSONTyped(json, true), type: 'unexpected-response'};
        default:
            throw new Error(`No variant of IMCCDeliveries exists with 'type=${json['type']}'`);
    }
}

export function IMCCDeliveriesToJSON(value?: IMCCDeliveries | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'could-not-auth-imcc':
            return CouldNotAuthIMCCToJSON(value);
        case 'could-not-perform-request':
            return CouldNotPerformRequestToJSON(value);
        case 'deliveries-list-response':
            return IMCCDeliveriesListResponseToJSON(value);
        case 'failed-to-validate':
            return ValidationErrorToJSON(value);
        case 'request-aborted':
            return RequestAbortedErrorToJSON(value);
        case 'unexpected-response':
            return UnexpectedResponseErrorToJSON(value);
        default:
            throw new Error(`No variant of IMCCDeliveries exists with 'type=${value['type']}'`);
    }
}

