import Checkbox from '@ingka/checkbox';
import React, { JSX } from 'react';
import Select, { createFilter, MenuListProps } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { MetaProperty } from '../generated-backend-api';
import { getOptionMap } from '../meta-options';
import { hasStringKey } from '../util/type';
import { extDayjs as dayjs } from '../util/dayjs';

export const DATE_FORMAT = 'MM/DD/YYYY';

import 'react-day-picker/dist/style.css';
import { DayPicker } from 'react-day-picker';

const height = 40;

function MenuList<T, Q extends boolean = false>(
  props: React.PropsWithChildren<MenuListProps<T, Q>>,
) {
  const { options, children, maxHeight, getValue } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  if (!Array.isArray(children)) {
    return null;
  }

  return (
    <FixedSizeList
      height={maxHeight}
      itemCount={children.length}
      itemSize={height}
      width={300}
      initialScrollOffset={initialOffset}
    >
      {(p: ListChildComponentProps) => <div style={p.style}>{children[p.index]}</div>}
    </FixedSizeList>
  );
}

interface IMetaInputProps {
  meta: MetaProperty;
  value: string[] | undefined;
  onChange: (value: string[]) => void;
}

export function MetaInput(props: IMetaInputProps): JSX.Element {
  const { meta, value, onChange } = props;

  const oMap = getOptionMap(meta);

  if (meta.type === 'autocomplete') {
    const options = meta.options.map((o) => {
      return {
        label: o.displayLabel,
        value: o.id,
      };
    });

    const v = value ? value : [];

    const selected = v.map((z) => {
      if (oMap.has(z)) {
        return oMap.get(z);
      }

      return {
        label: z,
        value: z,
      };
    });

    return (
      <CreatableSelect
        filterOption={createFilter({ ignoreAccents: false })}
        components={{ MenuList }}
        value={selected}
        isMulti={meta.isMultiselect === 1}
        menuShouldScrollIntoView={false}
        options={options}
        onChange={(e: unknown) => {
          if (e === null) {
            return;
          } else if (hasStringKey('value', e)) {
            onChange([e.value]);
          } else if (Array.isArray(e)) {
            onChange(
              e.map((q) => {
                if (!hasStringKey('value', q)) {
                  console.error('no value on option', q, meta, value);
                  return '';
                }
                return q.value;
              }),
            );
          }
        }}
      />
    );
  } else if (meta.type === 'select') {
    const options = meta.options.map((o) => {
      return {
        label: o.displayLabel,
        value: o.id,
      };
    });

    const selected = options.filter((q) => value?.includes(q.value));

    return (
      <Select
        filterOption={createFilter({ ignoreAccents: false })}
        components={{ MenuList }}
        value={selected}
        options={options}
        isMulti={meta.isMultiselect === 1}
        menuShouldScrollIntoView={false}
        onChange={(e: unknown) => {
          if (e === null) {
            return;
          } else if (hasStringKey('value', e)) {
            onChange([e.value]);
          } else if (Array.isArray(e)) {
            onChange(
              e.map((q) => {
                if (!hasStringKey('value', q)) {
                  console.error('no value on option', q, meta, value);
                  return '';
                }
                return q.value;
              }),
            );
          }
        }}
      />
    );
  } else if (meta.type === 'textarea') {
    return <textarea value={value} onChange={(e) => onChange([e.currentTarget.value])} />;
  } else if (meta.type === 'checkbox') {
    // return <textarea value={value} onChange={(e) => onChange([e.currentTarget.value])} />;
    return (
      <Checkbox
        id={`${meta.id}`}
        checked={value?.length === 1 && value[0] === '1'}
        value={''}
        onChange={() => {
          onChange([!value || value.length === 0 || value[0] !== '1' ? '1' : '0']);
        }}
      />
    );
  } else if (meta.type === 'date') {
    return (
      <DayPicker
        mode="single"
        selected={value && value.length === 1 ? new Date(value[0]) : undefined}
        onSelect={(v) => {
          if (v) {
            const d = dayjs(v);
            onChange([d.isValid() ? d.format(DATE_FORMAT) : '']);
          } else {
            onChange(['']);
          }
        }}
      />
    );
  } else {
    return (
      <input
        type="text"
        value={typeof value === 'undefined' ? '' : value}
        onChange={(e) => onChange([e.currentTarget.value])}
      />
    );
  }
}
