import { useQuery } from '@tanstack/react-query';
import { useClient } from './api-client';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ApiContext } from '../App';
import { baseUrl } from '../config';
import { ImageObject } from '../SelectableList';

export const useIdamAsset = (id: string) => {
  const client = useClient();

  const query = useQuery({
    queryKey: ['idamAsset', id],
    queryFn: () => client.IDAMAsset({ iDAMAssetRequestBody: { id } }),
  });

  return query;
};

export const useImageSrc = ({ damUrl, idamUrl }: ImageObject) => {
  const [src, setSrc] = useState<string>();
  const { getAccessToken } = useContext(ApiContext);

  const fetchImage = useCallback(async () => {
    const token = await getAccessToken();

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ url: idamUrl }),
    };
    const response = await fetch(`${baseUrl as string}/api/idam/image`, options);
    const blob = await response.blob();

    setSrc(URL.createObjectURL(blob));
  }, [getAccessToken, idamUrl]);

  useEffect(() => {
    if (damUrl || !idamUrl) return;

    fetchImage().catch((err) => {
      console.error(err);
    });
  }, [damUrl, fetchImage, idamUrl]);

  return damUrl || src;
};
