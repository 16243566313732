/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
/**
 * 
 * @export
 * @interface JobCancelledDueToExistingAsset
 */
export interface JobCancelledDueToExistingAsset {
    /**
     * 
     * @type {string}
     * @memberof JobCancelledDueToExistingAsset
     */
    type: JobCancelledDueToExistingAssetTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof JobCancelledDueToExistingAsset
     */
    timestamp: number;
    /**
     * 
     * @type {string}
     * @memberof JobCancelledDueToExistingAsset
     */
    existingId: string;
}


/**
* @export
* @enum {string}
*/
export type JobCancelledDueToExistingAssetTypeEnum = 'asset-already-exists-in-bynder';





export function JobCancelledDueToExistingAssetFromJSON(json: any): JobCancelledDueToExistingAsset {
    return JobCancelledDueToExistingAssetFromJSONTyped(json, false);
}

export function JobCancelledDueToExistingAssetFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobCancelledDueToExistingAsset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'timestamp': json['timestamp'],
        'existingId': json['existingId'],
    };
}

export function JobCancelledDueToExistingAssetToJSON(value?: JobCancelledDueToExistingAsset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'timestamp': value.timestamp,
        'existingId': value.existingId,
    };
}


