/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    IDAMIllustration,
    IDAMIllustrationFromJSON,
    IDAMIllustrationFromJSONTyped,
    IDAMIllustrationToJSON,
    IDAMImage,
    IDAMImageFromJSON,
    IDAMImageFromJSONTyped,
    IDAMImageToJSON,
    IDAMPublication,
    IDAMPublicationFromJSON,
    IDAMPublicationFromJSONTyped,
    IDAMPublicationToJSON,
    IDAMStoreCommunication,
    IDAMStoreCommunicationFromJSON,
    IDAMStoreCommunicationFromJSONTyped,
    IDAMStoreCommunicationToJSON,
} from './';

/**
 * 
 * @export
 * @interface IDAMAssetInfo
 */
export interface IDAMAssetInfo {
    /**
     * 
     * @type {IDAMImage}
     * @memberof IDAMAssetInfo
     */
    image?: IDAMImage;
    /**
     * 
     * @type {IDAMIllustration}
     * @memberof IDAMAssetInfo
     */
    illustration?: IDAMIllustration;
    /**
     * 
     * @type {IDAMPublication}
     * @memberof IDAMAssetInfo
     */
    publication?: IDAMPublication;
    /**
     * 
     * @type {IDAMStoreCommunication}
     * @memberof IDAMAssetInfo
     */
    storecommunication?: IDAMStoreCommunication;
}

export function IDAMAssetInfoFromJSON(json: any): IDAMAssetInfo {
    return IDAMAssetInfoFromJSONTyped(json, false);
}

export function IDAMAssetInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IDAMAssetInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'image': !exists(json, 'image') ? undefined : IDAMImageFromJSON(json['image']),
        'illustration': !exists(json, 'illustration') ? undefined : IDAMIllustrationFromJSON(json['illustration']),
        'publication': !exists(json, 'publication') ? undefined : IDAMPublicationFromJSON(json['publication']),
        'storecommunication': !exists(json, 'storecommunication') ? undefined : IDAMStoreCommunicationFromJSON(json['storecommunication']),
    };
}

export function IDAMAssetInfoToJSON(value?: IDAMAssetInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'image': IDAMImageToJSON(value.image),
        'illustration': IDAMIllustrationToJSON(value.illustration),
        'publication': IDAMPublicationToJSON(value.publication),
        'storecommunication': IDAMStoreCommunicationToJSON(value.storecommunication),
    };
}


