/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// oneof
import {
    BynderMetaResponse,
    CouldNotFetchBynderMeta,
    ValidationError,
    BynderMetaResponseFromJSONTyped,
    BynderMetaResponseToJSON,
    CouldNotFetchBynderMetaFromJSONTyped,
    CouldNotFetchBynderMetaToJSON,
    ValidationErrorFromJSONTyped,
    ValidationErrorToJSON,
} from './';

/**
 * oneof interface
 * @type BynderMeta
 * 
 * @export
 */
export type BynderMeta = BynderMetaResponse | CouldNotFetchBynderMeta | ValidationError;


export function BynderMetaFromJSON(json: any): BynderMeta {
    return BynderMetaFromJSONTyped(json, false);
}

export function BynderMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): BynderMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'bynder-meta-response':
            return {...BynderMetaResponseFromJSONTyped(json, true), type: 'bynder-meta-response'};
        case 'could-not-fetch-bynder-meta':
            return {...CouldNotFetchBynderMetaFromJSONTyped(json, true), type: 'could-not-fetch-bynder-meta'};
        case 'failed-to-validate':
            return {...ValidationErrorFromJSONTyped(json, true), type: 'failed-to-validate'};
        default:
            throw new Error(`No variant of BynderMeta exists with 'type=${json['type']}'`);
    }
}

export function BynderMetaToJSON(value?: BynderMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'bynder-meta-response':
            return BynderMetaResponseToJSON(value);
        case 'could-not-fetch-bynder-meta':
            return CouldNotFetchBynderMetaToJSON(value);
        case 'failed-to-validate':
            return ValidationErrorToJSON(value);
        default:
            throw new Error(`No variant of BynderMeta exists with 'type=${value['type']}'`);
    }
}

