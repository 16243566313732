/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// oneof
import {
    CouldNotAuthIMCC,
    CouldNotPerformRequest,
    ExtSearchResultResponse,
    RequestAbortedError,
    UnexpectedResponseError,
    ValidationError,
    CouldNotAuthIMCCFromJSONTyped,
    CouldNotAuthIMCCToJSON,
    CouldNotPerformRequestFromJSONTyped,
    CouldNotPerformRequestToJSON,
    ExtSearchResultResponseFromJSONTyped,
    ExtSearchResultResponseToJSON,
    RequestAbortedErrorFromJSONTyped,
    RequestAbortedErrorToJSON,
    UnexpectedResponseErrorFromJSONTyped,
    UnexpectedResponseErrorToJSON,
    ValidationErrorFromJSONTyped,
    ValidationErrorToJSON,
} from './';

/**
 * oneof interface
 * @type SearchExtApi
 * 
 * @export
 */
export type SearchExtApi = CouldNotAuthIMCC | CouldNotPerformRequest | ExtSearchResultResponse | ValidationError | RequestAbortedError | UnexpectedResponseError;


export function SearchExtApiFromJSON(json: any): SearchExtApi {
    return SearchExtApiFromJSONTyped(json, false);
}

export function SearchExtApiFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchExtApi {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'could-not-auth-imcc':
            return {...CouldNotAuthIMCCFromJSONTyped(json, true), type: 'could-not-auth-imcc'};
        case 'could-not-perform-request':
            return {...CouldNotPerformRequestFromJSONTyped(json, true), type: 'could-not-perform-request'};
        case 'ext-search-result-response':
            return {...ExtSearchResultResponseFromJSONTyped(json, true), type: 'ext-search-result-response'};
        case 'failed-to-validate':
            return {...ValidationErrorFromJSONTyped(json, true), type: 'failed-to-validate'};
        case 'request-aborted':
            return {...RequestAbortedErrorFromJSONTyped(json, true), type: 'request-aborted'};
        case 'unexpected-response':
            return {...UnexpectedResponseErrorFromJSONTyped(json, true), type: 'unexpected-response'};
        default:
            throw new Error(`No variant of SearchExtApi exists with 'type=${json['type']}'`);
    }
}

export function SearchExtApiToJSON(value?: SearchExtApi | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'could-not-auth-imcc':
            return CouldNotAuthIMCCToJSON(value);
        case 'could-not-perform-request':
            return CouldNotPerformRequestToJSON(value);
        case 'ext-search-result-response':
            return ExtSearchResultResponseToJSON(value);
        case 'failed-to-validate':
            return ValidationErrorToJSON(value);
        case 'request-aborted':
            return RequestAbortedErrorToJSON(value);
        case 'unexpected-response':
            return UnexpectedResponseErrorToJSON(value);
        default:
            throw new Error(`No variant of SearchExtApi exists with 'type=${value['type']}'`);
    }
}

