/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * app
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
// generic
import {
    BynderUploadMetaValue,
    BynderUploadMetaValueFromJSON,
    BynderUploadMetaValueFromJSONTyped,
    BynderUploadMetaValueToJSON,
    UploadJobItemCrop,
    UploadJobItemCropFromJSON,
    UploadJobItemCropFromJSONTyped,
    UploadJobItemCropToJSON,
} from './';

/**
 * 
 * @export
 * @interface UploadJobItem
 */
export interface UploadJobItem {
    /**
     * 
     * @type {string}
     * @memberof UploadJobItem
     */
    type: UploadJobItemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UploadJobItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UploadJobItem
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof UploadJobItem
     */
    extension: string;
    /**
     * 
     * @type {number}
     * @memberof UploadJobItem
     */
    fileSize?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UploadJobItem
     */
    automaticUpload?: boolean;
    /**
     * 
     * @type {{ [key: string]: BynderUploadMetaValue; }}
     * @memberof UploadJobItem
     */
    bynderUploadMeta?: { [key: string]: BynderUploadMetaValue; };
    /**
     * 
     * @type {Array<string>}
     * @memberof UploadJobItem
     */
    boards?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UploadJobItem
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UploadJobItem
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UploadJobItem
     */
    originalName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UploadJobItem
     */
    limited?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UploadJobItem
     */
    limitedDate?: string;
    /**
     * 
     * @type {UploadJobItemCrop}
     * @memberof UploadJobItem
     */
    crop?: UploadJobItemCrop;
}


/**
* @export
* @enum {string}
*/
export type UploadJobItemTypeEnum = 'upload-job';





export function UploadJobItemFromJSON(json: any): UploadJobItem {
    return UploadJobItemFromJSONTyped(json, false);
}

export function UploadJobItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadJobItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'name': json['name'],
        'url': json['url'],
        'extension': json['extension'],
        'fileSize': !exists(json, 'fileSize') ? undefined : json['fileSize'],
        'automaticUpload': !exists(json, 'automaticUpload') ? undefined : json['automaticUpload'],
        'bynderUploadMeta': !exists(json, 'bynderUploadMeta') ? undefined : (mapValues(json['bynderUploadMeta'], (q) => q)),
        'boards': !exists(json, 'boards') ? undefined : json['boards'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'originalName': !exists(json, 'originalName') ? undefined : json['originalName'],
        'limited': !exists(json, 'limited') ? undefined : json['limited'],
        'limitedDate': !exists(json, 'limitedDate') ? undefined : json['limitedDate'],
        'crop': !exists(json, 'crop') ? undefined : UploadJobItemCropFromJSON(json['crop']),
    };
}

export function UploadJobItemToJSON(value?: UploadJobItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'name': value.name,
        'url': value.url,
        'extension': value.extension,
        'fileSize': value.fileSize,
        'automaticUpload': value.automaticUpload,
        'bynderUploadMeta': value.bynderUploadMeta === undefined ? undefined : (mapValues(value.bynderUploadMeta, (q) => q)),
        'boards': value.boards,
        'description': value.description,
        'tags': value.tags,
        'originalName': value.originalName,
        'limited': value.limited,
        'limitedDate': value.limitedDate,
        'crop': UploadJobItemCropToJSON(value.crop),
    };
}


